<i18n src="@/i18n/locales/app.en.json"></i18n>
<i18n src="@/i18n/locales/app.fr.json"></i18n>

<template>
  <footer class="footer">
    <div class="footer-content content-page">
      <div class="columns border-separate">
        <div class="column is-half">
          <SogetLogo />
          <p>
            <b>SOGET SA</b>
            <br />
            <span>
              4, rue des Lamaneurs
            </span>
            <br />
            <span>
              76600 Le Havre, France
            </span>
            <br />
            <span>
              +33 2 35 19 25 54
            </span>
            <br />
            <span>
              <a href="mailto:hello@soget.fr">
                <span> hello@soget.fr </span>
              </a>
            </span>
            <br />
            <span>
              <a
                href="https://www.soget.fr"
                target="_blank"
                rel="noopener nofollow"
                >https://www.soget.fr
              </a>
            </span>
            <br />
            <span>
              <a
                href="https://twitter.com/SOGET_fr"
                target="_blank"
                rel="noreferrer nofollow"
                title="twitter"
              >
                <b-icon
                  pack="fab"
                  icon="twitter-square"
                  size="is-medium"
                  class="brand-icon twitter-icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/soget-sa"
                target="_blank"
                rel="noreferrer nofollow"
                title="linkedin"
              >
                <b-icon
                  pack="fab"
                  icon="linkedin"
                  size="is-medium"
                  class="brand-icon linkedin-icon"
                />
              </a>
              <a
                href="https://www.facebook.com/SOGET.SA/"
                target="_blank"
                rel="noreferrer nofollow"
                title="facebook"
              >
                <b-icon
                  pack="fab"
                  icon="facebook-square"
                  size="is-medium"
                  class="brand-icon facebook-icon"
                />
              </a>
            </span>
          </p>
        </div>
        <div class="column ellipse-element">
          <img src="@/assets/images/ellipse_element.svg" />
        </div>
      </div>
      <div class="columns content">
        <p class="column">
          <router-link :to="{ name: 'Home' }">
            <strong>
              {{ appName }}
            </strong>
          </router-link>
          {{ $t("by") }}
          <span>
            <a
              href="https://www.soget.fr"
              target="_blank"
              rel="noopener nofollow"
              >Soget
            </a>
          </span>
          <br />
          <span class="copyright-website">
            {{ `${new Date().getFullYear()} ${$t("copyrightSite")}` }}
          </span>
          <br />
        </p>
        <p class="column">
          <router-link :to="{ name: 'LegalNotice' }">
            {{ $t("notion.legalNotice") }}
          </router-link>
          <span class="copy-right">{{ $t("credit") }} © iStock</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import SogetLogo from "@/components/common/footer/SogetLogo.vue";

export default {
  name: "TheFooter",
  components: { SogetLogo },
  data() {
    return {
      appName: process.env.APP_NAME
    };
  }
};
</script>

<style scoped>
.footer {
  padding: 1.5rem 1.5rem 1.2em 2.2rem;
}

.twitter-icon,
.linkedin-icon,
.facebook-icon {
  font-size: 1.16em;
  margin: 8px 1px;
}

.twitter-icon {
  color: #1da1f2 !important;
}
.linkedin-icon {
  color: #0077b5 !important;
}
.facebook-icon {
  color: #3b5998 !important;
}

@media (min-width: 1140px) {
  .footer-content.content-page {
    width: 1140px;
  }
}

.content-page {
  flex-grow: 1;
}

@media (min-width: 1000px) {
  .content-page {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
  }
}

@media screen and (max-width: 899px) {
  .ellipse-element svg {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ellipse-element {
    display: none;
  }
}

.copyright-website {
  font-size: 13.34px;
}

.border-separate {
  border-style: dotted;
  border-width: 0 0 1px 0;
  border-color: #9b9b9b;
  padding-bottom: 12px;
}

.copy-right {
  font-size: 10pt;
  margin-top: 6px;
  display: block;
}

:root.dark .brand-icon {
  color: #fff !important;
}

:root.dark .footer a {
  color: #1e92e7 !important;
}
</style>
