<template>
  <span class="nbsp">
    +33 2 35 19 25 54
  </span>
</template>
<script>
export default {
  name: "SogetNumTel"
};
</script>
