<i18n src="@/i18n/locales/header.en.json"></i18n>
<i18n src="@/i18n/locales/header.fr.json"></i18n>
<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="container">
    <section class="section is-flex is-flex-direction-column">
      <b-loading :is-full-page="true" v-model="isLoading" />
      <h1 class="title-container">{{ $t("myOrganization") }}</h1>
      <OrganizationInfo
        class="mt-5"
        :organization="organization"
        @reload-data="getDataFromApi"
      />
    </section>
  </div>
</template>
<script>
import OrganizationInfo from "@/components/my-organization/OrganizationInfo";
import organizationsApi from "@/repository/customer-order/organizations.api";
export default {
  name: "MyOrganization",
  components: { OrganizationInfo },
  data() {
    return {
      organization: null,
      isLoading: false
    };
  },

  async mounted() {
    await this.getDataFromApi();
  },

  methods: {
    // Function to get data from api.
    async getDataFromApi() {
      try {
        let paginationParams = {
          limit: 1,
          offset: 0
        };

        this.isLoading = true;
        const response = await organizationsApi.getAllOrganizations(
          paginationParams
        );
        // If user is superviewer or admin we show the list.
        if (response.data.total > 0) {
          this.organization = response.data.items[0];
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.section {
  width: 80%;
}

.title-container {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1em;
}
</style>
