/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context(".", true, /\.store\.js$/);
const modules = {};

requireModule.keys().forEach(filename => {
  // create the module name from fileName
  // remove the leading (folder name), the tailing (store.js extension) and capitalize
  const moduleName = filename
    .replace(/^.*\//g, "")
    .replace(/(\.\/|\.store\.js)/g, "")
    .replace(/^\w/, c => c.toUpperCase());

  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default modules;
