export default {
  data() {
    return {
      isPrefill: Boolean(localStorage.getItem("prefill") === "true"),
      prefillContainer: null
    };
  },

  watch: {
    isPrefill(newVal) {
      localStorage.setItem("prefill", newVal);
    }
  }
};
