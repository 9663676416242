<i18n src="@/i18n/locales/general-info.en.json"></i18n>
<i18n src="@/i18n/locales/general-info.fr.json"></i18n>
<i18n src="@/i18n/locales/event-info.en.json"></i18n>
<i18n src="@/i18n/locales/event-info.fr.json"></i18n>

<template>
  <div class="columns">
    <div class="column">
      <div
        class="columns"
        :class="{ 'is-flex-direction-column': showCarrierDetails }"
      >
        <!-- Carrier code -->
        <div class="column">
          <Autocomplete
            :disabled="isEditingOrder"
            v-model="code"
            :label="$t('carrierCode')"
            :search-property="'code'"
            :validation-rules="'required|max:255'"
            :max-length="255"
            :is-required="true"
            :is-description-displayed="false"
            :label-position="'on-border'"
            :description-property="'name'"
            :fetch-all-async="getAllActorsAsync"
            :fetch-async="getActorAsync"
            :is-autocomplete-field-expanded="true"
            @input:description="handleNameChangedByCodeAutocomplete"
            @input="emitCarrierDataChanged"
            @select="handleSelectEvent"
          />
        </div>

        <!-- Carrier name -->
        <div class="column">
          <InputWithValidation
            v-if="!isMyCarrierFeatureEnabled"
            rules="max:128"
            :label="$t('carrierName')"
            v-model="name"
            :disabled="isEditingOrder"
            @input="emitCarrierDataChanged"
          />
          <Autocomplete
            v-else
            :disabled="isEditingOrder"
            v-model="name"
            :label="$t('carrierName')"
            :search-property="'name'"
            :validation-rules="'max:128'"
            :max-length="128"
            :is-description-displayed="false"
            :label-position="'on-border'"
            :description-property="'code'"
            :fetch-all-async="getAllMyCarriersAsync"
            :searching-suffix="''"
            :is-autocomplete-field-expanded="true"
            search-query-parameter="name"
            :uppercase-input="false"
            hide-fetch-all-async-error
            @input:description="handleCodeChangedByNameAutocomplete"
            @input="emitCarrierDataChanged"
            @select="handleSelectEvent"
          />
        </div>

        <!-- Carrier email -->
        <div v-if="!showCarrierDetails" class="column">
          <InputWithValidation
            rules="max:255|email"
            :label="$t('email')"
            v-model="contactEmail"
            :disabled="isEditingOrder"
            @input="emitCarrierDataChanged"
          />
        </div>

        <!-- Carrier telephone -->
        <div v-if="!showCarrierDetails" class="column">
          <InputWithValidation
            rules="max:255"
            :label="$t('phone')"
            v-model="contactPhone"
            :disabled="isEditingOrder"
            @input="emitCarrierDataChanged"
          />
        </div>
      </div>
    </div>

    <div class="column is-5" v-if="showCarrierDetails">
      <b-field
        :label="$t('carrierCompany')"
        label-position="on-border"
        custom-class="transparent-label"
      >
        <div class="box">
          <div class="columns">
            <div class="column is-8">
              <!-- Carrier company name -->
              <InputWithValidation
                rules="max:255"
                :label="$t('name')"
                v-model="companyName"
                :disabled="isEditingOrder"
                @input="emitCarrierDataChanged"
              />

              <!-- Carrier address -->
              <InputWithValidation
                rules="max:255"
                :label="$t('address')"
                v-model="companyAddress"
                :disabled="isEditingOrder"
                @input="emitCarrierDataChanged"
              />

              <!-- Carrier city -->
              <InputWithValidation
                rules="max:255"
                :label="$t('city')"
                v-model="companyCity"
                :disabled="isEditingOrder"
                @input="emitCarrierDataChanged"
              />
            </div>

            <div class="is-flex is-align-items-flex-end">
              <div class="column">
                <!-- Carrier zip code -->
                <InputWithValidation
                  rules="max:255"
                  :label="$t('zipCode')"
                  v-model="companyZipCode"
                  :disabled="isEditingOrder"
                  @input="emitCarrierDataChanged"
                />

                <!-- Carrier country -->
                <Autocomplete
                  v-model="companyCountry"
                  :disabled="isEditingOrder"
                  :label="$t('country')"
                  :search-property="'alphaCode2'"
                  :validation-rules="'length:2'"
                  :max-length="2"
                  :is-fixed-length="true"
                  :is-description-displayed="false"
                  :label-position="'on-border'"
                  :description-property="'name'"
                  :fetch-all-async="getAllCountriesAsync"
                  :fetch-async="getCountryAsync"
                  :is-autocomplete-field-expanded="true"
                  @input="emitCarrierDataChanged"
                />
              </div>
            </div>
          </div>
        </div>
      </b-field>
    </div>

    <div class="column is-one-quarter" v-if="showCarrierDetails">
      <b-field
        :label="$t('carrierContact')"
        label-position="on-border"
        custom-class="transparent-label"
      >
        <div class="box">
          <div class="columns">
            <div class="column">
              <!-- Carrier contact name -->
              <InputWithValidation
                rules="max:255"
                :label="$t('name')"
                v-model="contactName"
                :disabled="isEditingOrder"
                @input="emitCarrierDataChanged"
              />

              <!-- Carrier email -->
              <InputWithValidation
                rules="max:255|email"
                :label="$t('email')"
                v-model="contactEmail"
                :disabled="isEditingOrder"
                @input="emitCarrierDataChanged"
              />

              <!-- Carrier telephone -->
              <InputWithValidation
                rules="max:255"
                :label="$t('phone')"
                v-model="contactPhone"
                :disabled="isEditingOrder"
                @input="emitCarrierDataChanged"
              />
            </div>
          </div>
        </div>
      </b-field>
    </div>

    <div
      class="is-flex is-justify-content-flex-end is-flex-direction-row column pr-5 is-clickable"
      :class="
        `${
          !showCarrierDetails && !isMyCarrierFeatureEnabled
            ? 'is-one-fifth'
            : 'is-1'
        }`
      "
      @click="showCarrierDetails = !showCarrierDetails"
    >
      <b-icon
        pack="fas"
        :icon="`chevron-${showCarrierDetails ? 'up' : 'down'}`"
        class="is-size-3"
      />
    </div>
  </div>
</template>

<script>
import Autocomplete from "@/components/common/Autocomplete";
import InputWithValidation from "@/components/common/InputWithValidation.vue";

import actorsApi from "@/repository/referential/actors.api";
import countriesApi from "@/repository/referential/countries.api";
import myCarriersApi from "@/repository/customer-order/myCarriers.api";

import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import localStorage from "@/mixins/localStorage";

export default {
  name: "CarrierInfo",

  components: {
    Autocomplete,
    InputWithValidation
  },

  mixins: [localStorage],

  props: {
    // value is possibly not null when the component used for editing order purpose
    value: {
      type: Object
    },

    isEditingOrder: {
      type: Boolean
    }
  },

  data() {
    return {
      getAllActorsAsync: actorsApi.getAll,
      getActorAsync: actorsApi.get,
      getAllMyCarriersAsync: myCarriersApi.getAllMyCarriers,
      getAllCountriesAsync: countriesApi.getAll,
      getCountryAsync: countriesApi.get,

      code: "",
      name: "",
      companyName: "",
      companyAddress: "",
      companyCity: "",
      companyZipCode: "",
      companyCountry: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",

      isCodeOrNameAutocompleteSelected: false,
      showCarrierDetails: false
    };
  },

  computed: {
    isCarrierLocationEmpty() {
      return (
        !this.companyAddress &&
        !this.companyCity &&
        !this.companyZipCode &&
        !this.companyCountry
      );
    },

    isCarrierCompanyEmpty() {
      return this.isCarrierLocationEmpty && !this.companyName;
    },

    isCarrierContactEmpty() {
      return !this.contactName && !this.contactEmail && !this.contactPhone;
    },

    isMyCarrierFeatureEnabled() {
      return window._config.app.CONFIG_ENABLE_MY_CARRIERS_MANAGEMENT;
    }
  },

  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.loadNewCarrier(newVal);
      }
    }
  },

  methods: {
    handleNameChangedByCodeAutocomplete(newName) {
      if (!newName) this.loadNewCarrier({});
      else {
        this.name = newName;
        // If newName is not null or empty, this means the code is valid in Referential API,
        // call handleAutofill method to auto complete other fields if possible.
        if (!this.isCodeOrNameAutocompleteSelected)
          this.handleAutofill(this.code);
      }
    },

    handleCodeChangedByNameAutocomplete(newCode) {
      if (!newCode) this.loadNewCarrier({});
      else {
        this.code = newCode;
        if (!this.isCodeOrNameAutocompleteSelected) {
          // If newCode is not null or empty, this means the code is valid in Referential API,
          // call handleAutofill method to auto complete other fields if possible.
          this.handleAutofill(newCode);
        }
      }
    },

    async handleSelectEvent(selectedItem) {
      // Selecting an option in Autocomplete triggers 2 events 'input:description' and 'select'
      // For CarrierInfo, both of them calls handleAutofill which sends a GET request to API server.
      // As a result, 2 GET requests will be generated.
      // The variable is used to limit the number of requests to only 1
      this.isCodeOrNameAutocompleteSelected = true;
      await this.handleAutofill(selectedItem.code);
      // Set isCodeOrNameAutocompleteSelected back to default value
      this.isCodeOrNameAutocompleteSelected = false;
    },

    async handleAutofill(code) {
      if (!this.isMyCarrierFeatureEnabled) return;

      this.isLoading = true;
      try {
        const carrier = await myCarriersApi.getCarrierByCode({ code: code });
        if (carrier) {
          this.loadNewCarrier(carrier);
          this.emitCarrierDataChanged();
          this.showCarrierDetails =
            !this.isCarrierCompanyEmpty || !this.isCarrierContactEmpty;
        }
      } catch {
        // set other fields except code and name to empty string
        this.loadNewCarrier({
          code: this.code,
          name: this.name
        });
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Emit an event to notify that the carrier data has changed.
     *
     * This should be called each time an input event is transmitted by an input component.
     * There're possibly too many input events that'll be transmitted, then use debounce func
     * to reduce the event emission.
     *
     * We shouldn't wait for the blur event because there's a possibility that users don't
     * blur the input component but click directly the Submit button, so the blur event can be
     * handled after the submit event. As a result, the new carrier data changed
     * isn't acknowledged.
     */
    emitCarrierDataChanged: debounce(function() {
      this.$emit("set-order-information", this.createNewCarrier());
    }, 300),

    loadNewCarrier(carrier) {
      this.code = carrier.code ?? "";
      this.name = carrier.name ?? "";

      if (carrier.agency) {
        this.companyName = carrier.agency.name ?? "";
        this.companyAddress = carrier.agency.address ?? "";
        this.companyCity = carrier.agency.city ?? "";
        this.companyZipCode = carrier.agency.postalCode ?? "";
        this.companyCountry = carrier.agency.country ?? "";
      } else {
        this.companyName = carrier.company?.name ?? "";
        this.companyAddress = carrier.company?.location?.address ?? "";
        this.companyCity = carrier.company?.location?.city ?? "";
        this.companyZipCode = carrier.company?.location?.postalCode ?? "";
        this.companyCountry = carrier.company?.location?.country ?? "";
      }

      this.contactName = carrier.contact?.name ?? "";
      this.contactEmail = carrier.contact?.email ?? "";
      this.contactPhone = carrier.contact?.phone ?? "";
    },

    createNewCarrier() {
      const carrier = {};
      if (this.code) carrier.code = this.code;
      if (this.name) carrier.name = this.name;
      if (!this.isCarrierCompanyEmpty) {
        carrier.company = {};
        if (this.companyName) carrier.company.name = this.companyName;
        if (!this.isCarrierLocationEmpty) {
          carrier.company.location = {};
          if (this.companyAddress)
            carrier.company.location.address = this.companyAddress;
          if (this.companyCity)
            carrier.company.location.city = this.companyCity;
          if (this.companyZipCode)
            carrier.company.location.zipCode = this.companyZipCode;
          if (this.companyCountry)
            carrier.company.location.country = this.companyCountry;
        }
      }
      if (!this.isCarrierContactEmpty) {
        carrier.contact = {};
        if (this.contactName) carrier.contact.name = this.contactName;
        if (this.contactEmail) carrier.contact.email = this.contactEmail;
        if (this.contactPhone) carrier.contact.phone = this.contactPhone;
      }
      return carrier;
    }
  },

  created() {
    if (this.value) {
      this.loadNewCarrier(this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  flex-direction: column;

  &.field-gap-5 > *:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}
</style>
