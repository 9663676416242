const DateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      hour12: false
    },
    shortWithoutYear: { month: "short", day: "numeric" },
    shortWithoutTimeZone: {
      weekday: "short",
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false
    },
    shortNumericWithoutTimeZone: {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false
    }
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      hour12: false
    },
    shortWithoutYear: { month: "short", day: "numeric" },
    shortWithoutTimeZone: {
      weekday: "short",
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false
    }
  }
};
export default DateTimeFormats;
