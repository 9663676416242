<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <div class="mt-4 box">
    <div
      class="is-flex is-align-items-center is-justify-content-flex-end is-flex-wrap-wrap"
    >
      <b-button
        class="has-text-weight-semibold"
        type="is-primary"
        id="createButton"
        @click="handleCreate()"
      >
        {{ $t("createNew") }}
      </b-button>
    </div>

    <!-- Create new transcodification entity modal -->
    <SubjectModalAdd
      v-model="isOpenActorModalAdd"
      :endpoint="endpoint"
      @add-subject="getData()"
    />

    <div class="mt-4 box">
      <b-table
        :data="data"
        :loading="isLoading"
        hoverable
        detailed
        detail-key="id"
        detail-transition="fade"
        @details-open="row => getSubjectTranscodifications(row.id)"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <b-table-column
          field="id"
          width="16%"
          :label="$t('index')"
          v-slot="props"
          centered
        >
          <strong>{{ props.row.index + 1 }}</strong>
        </b-table-column>

        <b-table-column
          field="locationCode"
          width="18%"
          :label="$t('soneCode')"
          v-slot="props"
        >
          <strong class="has-text-primary"> {{ props.row.code }}</strong>
        </b-table-column>

        <b-table-column
          field="locationName"
          width="50%"
          label="Description"
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column width="16%" label="Action" v-slot="props">
          <div class="is-flex is-align-items-center">
            <b-button
              type="is-warning is-light"
              style="color: orange"
              class="is-size-7 mr-3"
              id="editButton"
              icon-pack="mdi"
              icon-left="circle-edit-outline"
              @click="handleEdit(props.row.id, props.row.code)"
            >
              {{ $t("edit") }}
            </b-button>
            <b-button
              type="is-danger is-light"
              class="is-size-7"
              id="deleteButton"
              icon-pack="mdi"
              icon-left="delete"
              @click="handleDelete(props.row.id)"
            >
              {{ $t("delete") }}
            </b-button>
          </div>
        </b-table-column>

        <template #detail="props">
          <b-loading
            v-model="isLoadingTranscode"
            :is-full-page="false"
          ></b-loading>

          <div
            class="columns is-flex is-align-items-flex-start is-justify-content-stretch is-full is-flex-wrap-wrap "
          >
            <TranscodificationItem
              v-for="item in listOpenedTranscodifications[props.row.id]"
              :key="item.transcodeId"
              :subjectId="props.row.id"
              :transcodeId="item.transcodeId"
              :system="item.system"
              :value="item.value"
              :endpoint="endpoint"
              @update-transcode-item="getSubjectTranscodifications"
              @refresh-data="getSubjectTranscodifications"
            />

            <div
              class="column is-one-third p-3 is-flex is-align-items-center is-align-self-stretch"
            >
              <div
                class="box addIcon is-clickable is-full is-flex is-align-items-center is-justify-content-center"
                v-if="code != props.row.code"
                @click="code = props.row.code"
              >
                <b-icon
                  pack="fas"
                  icon="plus"
                  class="has-text-primary is-size-4"
                ></b-icon>
              </div>

              <TranscodificationAddUpdate
                v-else
                :subjectId="props.row.id"
                :endpoint="endpoint"
                @add-transcode="getSubjectTranscodifications"
                v-bind:code.sync="code"
              />
            </div>
          </div>
        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t("noRecord") }}</div>
        </template>
      </b-table>
    </div>

    <PaginationBar
      :total="totalItems"
      :limit="limit"
      v-bind:page.sync="page"
      @update:page="getData"
    />

    <SubjectModalUpdate
      v-bind:isOpen.sync="isOpenModalUpdate"
      :subjectId="subjectId"
      :oldCode="oldCode"
      :endpoint="endpoint"
      @update-subject="getData()"
    />
  </div>
</template>

<script>
import SubjectModalUpdate from "./SubjectModalUpdate.vue";
import PaginationBar from "@/components/common/PaginationBar.vue";
import SubjectModalAdd from "./SubjectModalAdd.vue";
import TranscodificationItem from "./TranscodificationItem.vue";
import TranscodificationAddUpdate from "./TranscodificationAddUpdate.vue";

import transcodificationsApi from "@/repository/customer-order/transcodifications.api";
import containerTypesReferential from "@/mixins/containerTypes";
import locationReferential from "@/mixins/location";
import actorReferential from "@/mixins/actor";

import notifier from "@/mixins/notifier";

export default {
  name: "StackTranscodification",

  components: {
    SubjectModalUpdate,
    PaginationBar,
    SubjectModalAdd,
    TranscodificationItem,
    TranscodificationAddUpdate
  },

  mixins: [
    actorReferential,
    locationReferential,
    containerTypesReferential,
    notifier
  ],

  props: {
    endpoint: {
      type: String,
      required: true,
      default: ""
    }
  },

  data() {
    return {
      isLoading: false,
      isLoadingTranscode: false,
      isOpenActorModalAdd: false,
      isOpenModalUpdate: false,

      data: [],
      listOpenedTranscodifications: {},

      totalItems: 0,
      page: 1,
      limit: 6,

      subjectId: 0,
      oldCode: "",
      code: null,
      system: null,
      value: null
    };
  },

  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    handleCreate() {
      this.isOpenActorModalAdd = true;
    },

    handleEdit(id, code) {
      this.isOpenModalUpdate = true;
      this.subjectId = id;
      this.oldCode = code;
    },

    handleDelete(subjectId) {
      let message;

      if (this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT)
        message = this.$t("deleteActor");

      if (this.endpoint === process.env.VUE_APP_API_LOCATIONS_ENDPOINT)
        message = this.$t("deleteLocation");

      if (this.endpoint === process.env.VUE_APP_API_CONTAINER_TYPES_ENDPOINT)
        message = this.$t("deleteContainerType");

      this.$buefy.dialog.confirm({
        title: this.$t("confirmTitle"),
        message,
        confirmText: this.$t("delete"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          transcodificationsApi
            .deleteSubject(this.endpoint, subjectId)
            .then(() => {
              this.isLoading = false;
              this.getData();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },

    async getData() {
      let paginationParams = {
        limit: this.limit,
        offset: this.offset
      };

      this.isLoading = true;

      await transcodificationsApi
        .getAllSubjects(this.endpoint, paginationParams)
        .then(async response => {
          this.totalItems = response.data.total;

          var items = response.data.items;

          for (let i = 0; i < items.length; i++) {
            items[i].index = this.offset + i;

            if (this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT)
              items[i].description = await this.getActorName(items[i].code);

            if (this.endpoint === process.env.VUE_APP_API_LOCATIONS_ENDPOINT)
              items[i].description = await this.getLocationName(items[i].code);

            if (
              this.endpoint === process.env.VUE_APP_API_CONTAINER_TYPES_ENDPOINT
            )
              items[i].description = await this.getContainerTypesDescription(
                items[i].code
              );
          }

          return response;
        })
        .then(response => {
          this.data = response.data.items;
        })
        .finally(() => (this.isLoading = false));
    },

    async getSubjectTranscodifications(subjectId) {
      this.isLoadingTranscode = true;

      await transcodificationsApi
        .getAllTranscodifications(this.endpoint, subjectId)
        .then(response => {
          this.listOpenedTranscodifications[subjectId] = response.data;
        });

      this.isLoadingTranscode = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.addIcon {
  transition: all 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  min-height: 171px;
  border: 1px #fff solid;
}

.addIcon:hover {
  filter: brightness(120%);
  border: 3px $primary solid;
}

.addItem {
  width: 100%;
  height: 100%;
  min-height: 171px;
  position: relative;
  padding-bottom: 0px !important;
}

.closeIcon {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.closeIcon:hover {
  color: $danger;
}

.selectItem,
.select,
select {
  width: 100%;
}

.box {
  border: 1px solid white;
}

//custom css for cancel button in delete dialog of a subject or transcodification
.modal-card .button {
  color: $primary-text-color;
}
</style>
