<i18n src="@/i18n/locales/connection.en.json"></i18n>
<i18n src="@/i18n/locales/connection.fr.json"></i18n>

<template>
  <div>
    <TheBandeau />

    <div class="connection">
      <div class="section">
        <h3>
          <span v-html="$t('title2')"></span>
        </h3>

        <span v-html="$t('title3')"></span>

        <p
          class="connecText"
          :class="{
            'dark-mode': $root.isDarkMode
          }"
        >
          <span v-html="$t('promo1')"></span>
          <br />
          <a
            :href="signUpUrl"
            :class="{
              'has-text-primary-light': $root.isDarkMode
            }"
          >
            &gt;&nbsp;<span v-html="$t('promo2')"></span>
          </a>
          <br />
          <span v-html="$t('promo3')"></span>
        </p>

        <h3 v-html="$t('titlePros')"></h3>

        • <span v-html="$t('pros1')"></span>
        <br />

        • <span v-html="$t('pros2')"></span>
        <br />

        • <span v-html="$t('pros3')"></span>
        <br />

        • <span v-html="$t('pros4')"></span>
        <br />

        • <span v-html="$t('pros5')"></span>
        <br />
      </div>

      <div class="columns">
        <div class="column">
          <b-button type="is-link">
            <a :href="signUpUrl" data-testid="sign-up">
              {{ $t("signUp") }}
            </a>
          </b-button>
        </div>
        <div class="column">
          <b-button type="is-link" @click="logIn" data-testid="sign-in">
            {{ $t("signIn") }}
          </b-button>
        </div>
      </div>
    </div>

    <br />
    <br />
  </div>
</template>

<script>
import TheBandeau from "@/components/common/TheBandeau.vue";
import urlConfigurator from "@/mixins/urlConfigurator";

export default {
  name: "ConnectionView",
  mixins: [urlConfigurator],
  components: {
    TheBandeau
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables";

.connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.columns,
.column {
  display: flex;
  justify-content: center;
}

.columns {
  width: 84%;
}
.section {
  width: 84%;
  font-size: 1.15em;
  padding-top: 0.5em;
  justify-content: center;
}

h3 {
  font-size: 1.8em;
  padding-bottom: 0.7em;
  line-height: 1.2em;
}

p.connecText {
  text-align: center;
  border: 3px solid $primary;
  padding: 20px;
  margin: 40px 0px;
  border-radius: 6px;
}

p.connecText.dark-mode {
  border-color: $primary-light;
}

div.connecText {
  padding-bottom: 20px;
}

.isNew {
  width: auto;
  height: 25px;
  padding: 4px;
  font-size: 0.7em;
  background-color: red;
  border-radius: 4px;
  color: #fff;
}

button.button.is-link {
  background-color: $primary;
  &:hover {
    background-color: $primary;
  }
  a {
    color: #fff;
  }
}

.image-container {
  width: 100%;
  padding: 48px 0 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 319px) {
  .section {
    width: 98%;
    h3 {
      font-size: 20px !important;
    }
  }
  .columns {
    width: 96%;
    flex-direction: column;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .section {
    width: 96%;
  }
  .columns {
    width: 96%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .section {
    width: 96%;
  }
  .columns {
    width: 96%;
  }
}
</style>
