import {
  IMPORT_EVENT_EMPTY_RETURN,
  EXPORT_EVENT_DELIVERY
} from "@/helpers/constants.js";

function isEditable(order) {
  let containers = [];
  if (order.container) {
    containers.push(order.container);
  }

  if (order.containers && order.containers.length > 0) {
    containers = order.containers;
  }

  let allLastImportEventsCompleted = true;
  let allLastExportEventsCompleted = true;

  containers.forEach(container => {
    let lastImportEventCompleted =
      container.events.find(
        e =>
          e.type === IMPORT_EVENT_EMPTY_RETURN &&
          (e.state === "OK" || e.state === "KO")
      ) != undefined;

    allLastImportEventsCompleted &= lastImportEventCompleted;

    let lastExportEventCompleted =
      container.events.find(
        e =>
          e.type === EXPORT_EVENT_DELIVERY &&
          (e.state === "OK" || e.state === "KO")
      ) != undefined;

    allLastExportEventsCompleted &= lastExportEventCompleted;
  });

  let incompleteOrder =
    order.customsFlag.toLowerCase() === "import"
      ? !allLastImportEventsCompleted // at least one import container has not been returned onto the terminal yet
      : !allLastExportEventsCompleted; // at leas one export container has not been delivered onto the terminal yet

  return (
    order.type === "Master" &&
    (order.status === "Draft" ||
      order.status === "Valid" ||
      order.status === "Accepted" ||
      order.status === "Rejected") &&
    incompleteOrder // order can be updated when it is not fully completed
  );
}

export { isEditable };
