<template>
  <b-collapse
    :open="isOpen"
    @open="$emit('on-card-open')"
    class="card"
    animation="slide"
    aria-id="contentIdForA11y3"
  >
    <template #trigger="props">
      <div
        class="card-header"
        role="button"
        aria-controls="contentIdForA11y3"
        :aria-expanded="props.open"
      >
        <h1 class="card-header-title">
          {{ title }}
        </h1>
        <a class="card-header-icon">
          <b-icon pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'">
          </b-icon>
        </a>
      </div>
    </template>

    <div class="card-content p-4">
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: "CollapseCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>
