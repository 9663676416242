<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="column is-one-third p-2">
    <div class="box">
      <article class="media is-align-items-center">
        <div class="media-left">
          <i class="fas fa-user-circle fa-2x has-text-primary"></i>
        </div>
        <div class="media-content">
          <div class="content">
            <p
              class="is-flex is-justify-content-space-between"
              style="margin: 0;"
            >
              <strong>{{ memberName }}</strong>
              <span class="tag is-primary">{{
                $t("member.role." + role)
              }}</span>
            </p>
            <p>
              {{ email }}
            </p>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
export default {
  name: "MemberItem",

  props: {
    memberName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  }
};
</script>
