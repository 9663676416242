<i18n src="@/i18n/locales/super-viewer-pop-up-actor.en.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-pop-up-actor.fr.json"></i18n>

<template>
  <b-tooltip
    type="is-white"
    :position="position"
    :size="size"
    multilined
    square
    :triggers="['click']"
    :auto-close="['escape', 'outside']"
  >
    <b-icon
      pack="fas"
      icon="info-circle"
      size="is-medium"
      class="is-clickable"
    />
    <template #content>
      <table class="table is-fullwidth has-text-left">
        <tbody>
          <tr>
            <th>{{ $t("name") }}</th>
            <td>{{ actor.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("code") }}</th>
            <td>{{ actor.code }}</td>
          </tr>
          <tr>
            <th>{{ $t("company") }}</th>
            <td>{{ actor.company.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("address") }}</th>
            <td>
              {{ address }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("contact") }}</th>
            <td>{{ actor.contact.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("phone") }}</th>
            <td>{{ actor.contact.phone }}</td>
          </tr>
          <tr>
            <th>{{ $t("email") }}</th>
            <td>{{ actor.contact.email }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </b-tooltip>
</template>

<script>
export default {
  name: "PopUpActor",
  props: {
    actor: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: "is-bottom"
    },
    size: {
      type: String,
      required: false,
      default: "is-large"
    }
  },
  computed: {
    address: function() {
      let address = this.actor.company.location.address;

      if (this.actor.company.location.zipCode)
        address += ", " + this.actor.company.location.zipCode;

      if (this.actor.company.location.city)
        address += " " + this.actor.company.location.city;

      if (this.actor.company.location.country)
        address += ", " + this.actor.company.location.country;

      return address;
    }
  },
  created() {
    if (!this.actor.company) {
      this.actor.company = {
        name: null
      };
    }

    if (!this.actor.company.location) {
      this.actor.company.location = {
        address: null,
        city: null,
        zipCode: null,
        country: null
      };
    }

    if (!this.actor.contact) {
      this.actor.contact = {
        name: null,
        phone: null,
        email: null
      };
    }
  }
};
</script>
