<i18n src="@/i18n/locales/location.en.json"></i18n>
<i18n src="@/i18n/locales/location.fr.json"></i18n>
<i18n src="@/i18n/locales/contact.en.json"></i18n>
<i18n src="@/i18n/locales/contact.fr.json"></i18n>
<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>

<template>
  <div
    class="is-inline-block"
    :class="hasNoPositionTop() ? 'is-inline-block-no-top' : ''"
  >
    <div class="location-name" v-if="data.location">
      <b-tooltip
        type="is-white"
        position="is-bottom"
        size="is-large"
        multilined
        square
        :triggers="['click']"
        :auto-close="['outside']"
      >
        <div
          class="label is-clickable horizontal-timeline-item-location-label"
          @click="$emit('openLocationPopUp')"
          v-if="data.location"
        >
          {{ data.location.code ? data.location.code : data.location.name }}
        </div>
        <template #content>
          <table class="table is-fullwidth has-text-left">
            <tbody>
              <tr>
                <th>{{ $t("locationCode") }}</th>
                <td>{{ data.location.code }}</td>
              </tr>
              <tr>
                <th>{{ $t("locationName") }}</th>
                <td>{{ data.location.name }}</td>
              </tr>
              <tr>
                <th>{{ $t("reserves") }}</th>
                <td>{{ data.location.reserves }}</td>
              </tr>
              <tr>
                <th>{{ $t("reference") }}</th>
                <td>{{ data.location.reference }}</td>
              </tr>
              <tr>
                <th>{{ $t("address") }}</th>
                <td>{{ address }}</td>
              </tr>
              <tr>
                <th>{{ $t("zipCode") }}</th>
                <td>{{ zipCode }}</td>
              </tr>
              <tr>
                <th>{{ $t("city") }}</th>
                <td>{{ city }}</td>
              </tr>
              <tr>
                <th>{{ $t("country") }}</th>
                <td>{{ country }}</td>
              </tr>
              <tr>
                <th>{{ $t("contact") }}</th>
                <td>{{ contactName }}</td>
              </tr>
              <tr>
                <th>{{ $t("email") }}</th>
                <td>{{ contactEmail }}</td>
              </tr>
              <tr>
                <th>{{ $t("phone") }}</th>
                <td>{{ contactPhone }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </b-tooltip>
    </div>

    <div class="horizontal-timeline-item-container">
      <div
        class="overlay has-background-primary"
        style="height: 10px; width:100%;"
      />
      <div class="child1">
        <b-button
          v-if="data.state == 'InProgress'"
          style="border-radius: 50px"
          type="is-primary"
          icon-pack="fas"
          icon-left="clock"
        />
        <b-button
          v-else-if="data.state == 'OK'"
          style="border-radius: 50px"
          class="is-primary"
          icon-pack="fas"
          icon-left="check"
        />
        <b-button
          v-else-if="data.state == 'KO'"
          style="border-radius: 50px"
          type="is-primary"
          icon-pack="fas"
          icon-left="times"
        />
        <b-button
          v-else
          style="border-radius: 50px"
          type="is-primary"
          icon-pack="fas"
          icon-left="circle"
        />
      </div>
      <div class="text-container">
        <div class=" is-size-8" style="top: 50px" v-html="labelHtml" />
        <div class=" label title-1">{{ data.title1 }}</div>
        <div class=" title-2">{{ data.title2 }}</div>
        <div class="title-2 label has-text-success" v-if="data.state == 'OK'">
          {{
            $t("formatString.completionDate", [
              formatDateStr(data.creationDate)
            ])
          }}
        </div>
        <div class="px-1" style="top: 125px" v-html="errorHtml" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalTimeLineItem",
  props: {
    data: {
      type: Object,
      required: true
    },
    isEventTimeline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    zipCode: function() {
      return this.data.location?.location?.zipCode;
    },
    address: function() {
      return this.data.location?.location?.address;
    },
    city: function() {
      return this.data.location?.location?.city;
    },
    country: function() {
      return this.data.location?.location?.country;
    },
    contactName: function() {
      return this.data.location?.contact?.name;
    },
    contactEmail: function() {
      return this.data.location?.contact?.email;
    },
    contactPhone: function() {
      return this.data.location?.contact?.phone;
    },
    errorHtml: function() {
      let errorHtml = "";

      if (this.data.title3) {
        var errors = this.data.title3.split(";");
        errors.forEach(e => {
          errorHtml += `<p class='has-text-danger'>${e}</p>`;
        });
      }

      return errorHtml;
    },
    labelHtml: function() {
      if (!this.data.history) return this.data.label;

      let labelHtml = "";

      if (this.isEventTimeline) {
        const startDate = this.formatDateStr(this.data.startDate);
        const endDate = this.formatDateStr(this.data.endDate);

        if (startDate && endDate)
          labelHtml = this.$t("formatString.startDateEndDate", [
            startDate,
            endDate
          ]);
        else if (!startDate && endDate)
          labelHtml = this.$t("formatString.endDate", [endDate]);
        else if (startDate && !endDate)
          labelHtml = this.$t("formatString.startDate", [startDate]);

        labelHtml = `<p>${labelHtml}</p>`;

        return labelHtml;
      }

      for (let i = 0; i < this.data.history.length; i++) {
        let date = this.$d(
          new Date(this.data.history[i]),
          "shortWithoutTimeZone"
        );

        if (i == this.data.history.length - 1) labelHtml += `<p>${date}</p>`;
        else labelHtml += `<p>${date}</p>`;
      }

      return labelHtml;
    }
  },
  methods: {
    formatDateStr: function(dateStr) {
      return dateStr
        ? this.$d(new Date(dateStr), "shortWithoutTimeZone")
        : dateStr;
    },

    // if there's no location code, location name will be shown,
    // this function helps adding the css class to
    // re-position location name that's too long
    hasNoPositionTop: function() {
      if (this.data.location && !this.data.location.code) {
        if (this.data.location.name.trim().length > 38) return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.is-inline-block {
  white-space: normal;
  position: relative;
  top: 24px;
}

.is-inline-block-no-top {
  top: 0;
}

.horizontal-timeline-item-container {
  width: 400px;
  height: 40px;
  position: relative;
  white-space: normal;
}

.child1 {
  width: 100%;
  height: 40px;
  position: absolute;
  text-align: center;
}

.text-container {
  top: 105%;
  left: 5%;
  right: 5%;
  position: absolute;
  text-align: center;
}

.overlay {
  z-index: 0;
  position: absolute;
  top: 15px;
}

.location-name {
  text-align: center;
}

.title-1 {
  top: 75px;
}

.title-2 {
  top: 100px;
}
</style>
