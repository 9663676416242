/*
 * IMPORT LIBS
 */
import "./registerServiceWorker";
import "@/validation/validation.js";
import App from "@/App.vue";
import Axios from "axios";
import SoneConnect from "./plugins/sone-connect";
import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import useI18n from "@/i18n/i18n.js";
import useRouter from "@/router";
import useStore from "@/store";
import { useSOneConnect } from "./plugins/sone-connect/install";

import LoadingStateManager from "./plugins/loading-state-manager";
Vue.use(LoadingStateManager);

if (window.document.documentMode) {
  // IE Stuff
  require("proxy-polyfill");
}

import Buefy from "buefy";
Vue.use(Buefy, {
  defaultIconPack: "fas"
});

/*
 * IMPORT STYLES
 */
import "./assets/sass/app-style.scss";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

/*
 * IMPORT MIXINS
 */
import darkModeSetProperty from "@/mixins/darkModeSetProperty";
import prefillSetProperty from "@/mixins/prefillSetProperty";
import flowTypeSetProperty from "@/mixins/flowTypeSetProperty";

/**
 * IMPORT CUSTOM GLOBAL FILTERS
 */
import "@/filters/index.js";

//init empty config
window._config = {};

// Need config is loaded before Vue instance creation and in order to use application
Axios.create()
  .get("/config/config.json")
  .then(function(response) {
    window._config = response.data;
  })
  .catch(err => {
    console.log(err);
  })
  .then(async () => {
    const i18n = useI18n();
    const router = useRouter();
    const store = useStore();
    Vue.use(SoneConnect);
    const soneConnect = useSOneConnect();

    const vm = new Vue({
      data: {
        newOrderKey: 0,
        isCookieBannerDisplay: false
      },
      i18n,
      router,
      store,
      mixins: [darkModeSetProperty, prefillSetProperty, flowTypeSetProperty],
      mounted: async function() {
        // Show loading before config is ready
        let loadingIndex = this.$enableLoading();
        this.setPropertyInDarkMode();
        this.$disableLoading(loadingIndex);
      },
      render: h => h(App)
    });

    // SOneConnect - SSO module initialization
    await soneConnect.initialize(vm);

    // Vue instance is mounted on #app element
    vm.$mount("#app");
  });
