import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

const END_POINT = "/carriers";
let etag = null;

export default {
  /**
   * Get all my carriers
   * @param {PaginationParams} queryParams
   * @returns {Promise<HttpResponse<PaginationResponse<MyCarrierSimple>>>}
   *
   * @see {@link PaginationParams}
   * @see {@link PaginationResponse}
   * @see {@link MyCarrierSimple}
   */
  getAllMyCarriers(_, queryParams) {
    return PrivateCustomerOrderHttpClient.httpClient.get(`${END_POINT}`, {
      params: queryParams
    });
  },

  /**
   * Create new my carriers
   * @param {MyCarrierPost} data
   * @returns
   */
  createNewCarrier(data) {
    return PrivateCustomerOrderHttpClient.httpClient.post(`${END_POINT}`, data);
  },

  /**
   * Get carrier info by its code.
   * @param {string} code
   * @returns {Promise<MyCarrierGetResponse>}
   */
  getCarrierByCode(pathParams) {
    return PrivateCustomerOrderHttpClient.httpClientIgnoredDefaultInterceptors
      .get(`${END_POINT}/${pathParams?.code}`)
      .then(response => {
        etag = response.headers.etag ?? null;
        return response.data;
      });
  },

  /**
   * Update a carrier.
   * @param {MyCarrierPost} data
   * @returns
   */
  updateCarrier(data) {
    return PrivateCustomerOrderHttpClient.httpClient.put(`${END_POINT}`, data, {
      headers: {
        "If-Match": etag
      }
    });
  },

  /**
   * Delete carrier by code.
   * @param {string} code
   * @returns
   */
  deleteCarrier(code) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${END_POINT}/${code}`
    );
  }
};
