<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="pr-5">
    <ValidationObserver ref="validationObserver" tag="div" class="field">
      <ValidationProvider
        rules="required|max:256"
        v-slot="{ errors }"
        :name="$t('organization')"
      >
        <b-field label-position="on-border" expanded>
          <template #label>
            <label class="required-field">{{ $t("organization") }}</label>
          </template>
          <b-input
            v-model.trim="organizationName"
            @input="upperCaseInput"
            expanded
          />
          <template #message>
            <p class="has-text-danger">
              {{ errors[0] }}
            </p>
          </template>
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        rules="required|digits:14"
        v-slot="{ errors }"
        :name="$t('siret')"
      >
        <b-field class="mt-4 mb-4" label-position="on-border">
          <template #label>
            <label class="required-field">{{ $t("siret") }}</label>
          </template>
          <b-input v-model="siret" expanded />
          <template #message>
            <p class="has-text-danger">
              {{ errors[0] }}
            </p>
          </template>
        </b-field>
      </ValidationProvider>
    </ValidationObserver>
    <Autocomplete
      v-model="actorCode"
      :label="$t('actorCode')"
      :search-property="'code'"
      :max-length="32"
      :is-description-displayed="true"
      :label-position="'on-border'"
      :description-property="'name'"
      :fetch-all-async="getAllActorsAsync"
      :fetch-async="getActorAsync"
      :is-autocomplete-field-expanded="true"
      :hide-all-errors="true"
    />
    <div class="is-flex is-justify-content-end">
      <b-button
        type="is-primary"
        class="mt-4"
        :label="$t('create')"
        :loading="isWaitingSubmit"
        @click="submit"
      />
    </div>
  </div>
</template>
<script>
import organizationsApi from "@/repository/customer-order/organizations.api";
import actorsApi from "@/repository/referential/actors.api";
import Autocomplete from "../common/Autocomplete.vue";

export default {
  name: "AddOrganizationForm",
  data() {
    return {
      organizationName: "",
      siret: "",
      actorCode: null,
      isWaitingSubmit: false,
      getAllActorsAsync: actorsApi.getAll,
      getActorAsync: actorsApi.get
    };
  },

  components: { Autocomplete },

  methods: {
    async submit() {
      const validateResult = await this.$refs.validationObserver.validate();
      if (!validateResult) return;
      const newData = {
        name: this.organizationName,
        siret: this.siret,
        actorCode: this.actorCode
      };
      this.isWaitingSubmit = true;
      try {
        await organizationsApi.createNewOrganization(newData);

        // Reload page to get new token with owner role.
        window.location.reload();
      } finally {
        this.isWaitingSubmit = false;
      }
    },

    // Convert input data to uppercase
    upperCaseInput() {
      this.organizationName = this.organizationName.toUpperCase();
    }
  }
};
</script>
