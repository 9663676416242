<template>
  <div
    class="horizontal-timeline"
    :class="{
      'horizontal-timeline-large': isPopUpDisplayed
    }"
    @click.self="isPopUpDisplayed = false"
  >
    <HorizontalTimeLineItem
      v-for="(item, index) in items"
      :key="index"
      :data="item"
      :is-event-timeline="isEventTimeline"
      @openLocationPopUp="isPopUpDisplayed = true"
    />
    <HorizontalTimeLineArrow :margin-top="isEventTimeline ? '24px' : null" />
  </div>
</template>

<script>
import HorizontalTimeLineArrow from "./HorizontalTimeLineArrow.vue";
import HorizontalTimeLineItem from "./HorizontalTimeLineItem.vue";
export default {
  name: "HorizontalTimeLine",
  data() {
    return {
      isPopUpDisplayed: false
    };
  },
  components: {
    HorizontalTimeLineArrow,
    HorizontalTimeLineItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    isEventTimeline: Boolean
  }
};
</script>

<style scoped>
.horizontal-timeline {
  width: 100%;
  height: 200px;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
}

.horizontal-timeline-large {
  height: 450px;
}
</style>
