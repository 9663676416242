import { useSOneConnect } from "@/plugins/sone-connect/install";
import HttpClient from "@/repository/httpClient";

const soneConnect = useSOneConnect();

class PublicCustomerOrderHttpClient extends HttpClient {
  static get httpClient() {
    const token = soneConnect.userToken;
    const headers = {
      Authorization: `Bearer ${token.access_token}`
    };

    let customerOrderHttpClient = new HttpClient(
      window._config.api.CONFIG_CUSTOMER_ORDER_PUBLIC_URL,
      headers
    );

    return customerOrderHttpClient.httpClient;
  }
}

class PrivateCustomerOrderHttpClient extends HttpClient {
  static get newClient() {
    const token = soneConnect.userToken;
    const headers = {
      Authorization: `Bearer ${token.access_token}`
    };

    let customerOrderHttpClient = new PrivateCustomerOrderHttpClient(
      window._config.api.CONFIG_CUSTOMER_ORDER_PRIVATE_URL,
      headers
    );

    return customerOrderHttpClient;
  }

  static get httpClient() {
    let customerOrderHttpClient = PrivateCustomerOrderHttpClient.newClient;
    return customerOrderHttpClient.httpClient;
  }

  static get httpClientIgnoredDefaultInterceptors() {
    let client = PrivateCustomerOrderHttpClient.newClient;
    client.ejectDefaultResponseInterceptors();
    return client.httpClient;
  }
}

export { PublicCustomerOrderHttpClient, PrivateCustomerOrderHttpClient };

export default PublicCustomerOrderHttpClient;
