import ReferentialApi from "@/repository/referential/referentialHTTPClient";

/**
 * Dangerous codes end point.
 */
const END_POINT = "/dangerous-codes";

export default {
  /**
   * Search dangerous codes.
   * @param {*} pathParams - path parameters.
   * @param {*} queryParams - query parameters.
   */
  getAll(pathParams, queryParams) {
    return ReferentialApi.httpClient.get(
      `${END_POINT}/${pathParams?.type}/search`,
      {
        params: queryParams
      }
    );
  },

  /**
   * Get dangerous code information by code.
   * @param {*} pathParams - path parameters.
   */
  get(pathParams) {
    return ReferentialApi.httpClient.get(
      `${END_POINT}/${pathParams?.type}/${pathParams?.code}`
    );
  }
};
