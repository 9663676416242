import ReferentialApi from "@/repository/referential/referentialHTTPClient";

/**
 * Container types end point.
 */
const END_POINT = "/container-types";

export default {
  /**
   * Search container types.
   * @param {*} queryParams - query parameters.
   */
  getAll(_, queryParams) {
    return ReferentialApi.httpClient.get(`${END_POINT}/search`, {
      params: queryParams
    });
  },

  /**
   * Get container type information by code.
   * @param {*} pathParams - path parameters.
   */
  get(pathParams) {
    return ReferentialApi.httpClient.get(`${END_POINT}/${pathParams?.code}`);
  }
};
