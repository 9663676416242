<template>
  <div
    class="box box-global has-text-white has-text-centered is-clickable px-2"
    :class="isActive ? 'active' : ''"
    @click="$emit('click-label', containerIndex)"
  >
    <div class="center-horizontal is-flex-grow-5">
      <p class="has-text-weight-bold">{{ title }}</p>
      <p>{{ subtitle }}</p>

      <b-icon
        pack="fas"
        icon="info-circle"
        size="is-medium"
        :type="isActivePopup ? 'is-warning' : 'is-white'"
        @click.native.stop="$emit('click-info', containerIndex)"
      />
    </div>

    <div class="has-text-weight-bold">
      <b-icon v-if="indicator.isReefer" pack="fas" icon="asterisk"></b-icon>
      <b-icon v-if="indicator.isOog" pack="fab" icon="codepen"></b-icon>
      <b-icon
        v-if="indicator.isHazardous"
        pack="fas"
        icon="skull-crossbones"
      ></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContainersBoardListItemLabel",
  props: {
    title: {
      type: String,
      required: true,
      default: null
    },
    indicator: {
      type: Object,
      required: true,
      default: null
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    containerIndex: {
      type: [String, Number],
      required: false,
      default: null
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    isActivePopup: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped>
.box.active {
  background: #3caea3 !important;
}
</style>
