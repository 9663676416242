import CustomerOrderHttpClient, {
  PrivateCustomerOrderHttpClient
} from "./customerOrderHTTPClient";
import { isArray, isEmpty } from "lodash";
import "./../models.doc";

/**
 * Customer orders end point.
 */
const END_POINT = "/customer-orders";
let etag = null;

export default {
  /**
   * Get a customer order by its ID.
   * @param {string} id Order ID.
   * @returns {Promise<HttpResponse<CustomerOrderGet>>}
   */
  getById(id) {
    return CustomerOrderHttpClient.httpClient
      .get(`${END_POINT}/${id}`)
      .catch(error => {
        return error.response;
      })
      .then(response => {
        etag = response.headers.etag ?? null;
        return response;
      });
  },

  /**
   * Get customer orders by its reference.
   * @param {string} transportOrderNumber Transport order number.
   * @param {string} containerNumber Container number.
   * @param {string} carrierCode Carrier code.
   * @param {string} processingIndicator Customs flag.
   * @param {Date} startDate Start of search range for creation date.
   * @param {Date} endDate End of search range for creation date.
   * @param {number} offset Index of the first returned result.
   * @param {number} limit Number of results to be returned. Max value is 50.
   * @returns {Promise<HttpResponse<PaginationResponse<CustomerOrderGet>>>}
   */
  filter(
    transportOrderNumber,
    containerNumber,
    carrierCode,
    processingIndicator,
    startDate,
    endDate,
    offset,
    limit
  ) {
    return CustomerOrderHttpClient.httpClient.get(END_POINT, {
      params: {
        transportOrderNumber,
        containerNumber,
        carrierCode,
        processingIndicator,
        startDate,
        endDate,
        offset,
        limit,
        isProjection: true,
        desc: true
      }
    });
  },

  /**
   * Create a new customer order.
   * @param {CustomerOrderPost} customerOrder customer order data.
   * @param {Array} files list of attached documents.
   * @returns {Promise<HttpResponse<CustomerOrderPostResponse>>}
   */
  post(customerOrder, files) {
    // create form data
    const formData = new FormData();

    // first part is attached documents
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    // second part is order information
    function replacer(_key, value) {
      // Filtering out null/undefined/empty properties
      if (value === null || value === undefined || value === "")
        return undefined;

      // Filtering out empty object
      if (isEmpty(value) && isNaN(value)) {
        return undefined;
      }

      // Filtering out null elements (if any) in array
      if (isArray(value)) {
        value = value.filter(element => {
          return element !== null;
        });
      }

      return value;
    }

    let json = JSON.stringify(customerOrder, replacer);
    for (let i = 0; i < 3; i++) {
      let obj = JSON.parse(json);
      json = JSON.stringify(obj, replacer);
    }

    formData.append("order", json);

    // config header to accept form data
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return CustomerOrderHttpClient.httpClient.post(END_POINT, formData, config);
  },

  /**
   * Update an existing customer order.
   * @param {number} orderId Order ID
   * @param {CustomerOrderPost} customerOrder Customer order infotmation
   */
  put(orderId, customerOrder) {
    return CustomerOrderHttpClient.httpClient.put(
      `${END_POINT}/${orderId}`,
      customerOrder,
      {
        headers: {
          "If-Match": etag
        }
      }
    );
  },

  /**
   * Cancel a customer order.
   * @param {string} orderId Master order identifier.
   * @returns Promise
   */
  cancel(orderId) {
    return CustomerOrderHttpClient.httpClient.delete(`${END_POINT}/${orderId}`);
  },

  /**
   * Get segments of a customer order by its ID.
   * @param {string} id Master order ID.
   * @returns {Promise<HttpResponse<CustomerOrderGet[]>>}
   */
  getChildren(id) {
    return PrivateCustomerOrderHttpClient.httpClient.get(
      `${END_POINT}/${id}/children?isProjection=false`
    );
  },

  /**
   * Get a document information.
   * @param {string} orderId Order identifier.
   * @param {number} documentId Document identifier.
   */
  getAttachedDocument(orderId, documentId) {
    return PrivateCustomerOrderHttpClient.httpClient.get(
      `${END_POINT}/${orderId}/attached-documents/${documentId}`
    );
  },

  /**
   * Delete a document.
   * @param {string} orderId Order identifier.
   * @param {number} documentId Document identifier.
   */
  deleteAttachedDocument(orderId, documentId) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${END_POINT}/${orderId}/attached-documents/${documentId}`
    );
  }
};
