import containerTypesApi from "@/repository/referential/containerTypes.api";

export default {
  methods: {
    getContainerTypesDescription: function(code) {
      let pathParams = {
        code
      };

      return containerTypesApi
        .get(pathParams)
        .then(response => {
          return Promise.resolve(response.data.description);
        })
        .catch(() => {
          return Promise.resolve(code);
        });
    }
  }
};
