import useI18n from "@/i18n/i18n";

const REGEX_DECIMAL_EN = /^-?\d{1,15}([.]\d{0,3})?$/;
const REGEX_DECIMAL_FR = /^-?\d{1,15}([,]\d{0,3})?$/;

export default {
  methods: {
    /**
     * Round a float number.
     * @param {*} value - Number to be rounded.
     * @param {*} digits - number of digits after floating point.
     * @returns rounded number.
     */
    roundFloat: function(value, digits) {
      try {
        return parseFloat(value.toFixed(digits));
      } catch {
        return Number.NaN;
      }
    },

    /**
     * Determines whether the string represents an integer number.
     * @param {String} str - string to test.
     */
    isIntegerString: function(str) {
      let regex = new RegExp("^-?\\d+$");
      return regex.test(str?.trim());
    },

    /**
     * Determines whether the string represents a float number.
     * @param {String} str - string to test.
     */
    isFloatString: function(str) {
      const { locale } = useI18n();
      let regex = REGEX_DECIMAL_EN;
      if (["fr", "fr-FR"].includes(locale)) {
        regex = REGEX_DECIMAL_FR;
      }

      return regex.test(`${str ?? ""}`.trim());
    },

    /**
     * Trim leading zeros out of the number string.
     * @param {String} str Number string.
     * @returns Number string without leading zeros.
     */
    trimLeadingZeros: function(str) {
      if (typeof str !== "string") return str;

      if (!str?.trim()) return str;
      const regexLeadingZero = /^(-?)(0*)(\d+([.,]?\d+)?)$/;
      return str.replace(regexLeadingZero, "$1$3");
    },

    /**
     * Try to parse a string to float number if possible.
     * @param {String} str Value will be converted to number if possible
     * @returns Float number if the string represents a float number. Otherwise, returns the string itself.
     */
    tryParseFloat(str = "") {
      if (typeof str !== "string" || !this.isFloatString(str)) return str;

      // If statement cover the cases:
      //    - User enter a number follow by a decimal point and no post number after the point (ex: 5.)
      //    - User enter 0 right after decimal point (ex: 5.02)
      let value = str?.replace(",", ".");

      if (value.endsWith(".")) {
        value += "0";
      }

      return parseFloat(value);
    },

    /**
     * Parse a string to float and round the result.
     * @param {*} value Number in string.
     */
    tryParseFloatAndRound: function(value) {
      let parsedValue = this.roundFloat(this.tryParseFloat(value), 3);
      return isNaN(parsedValue) ? value : parsedValue;
    },

    /**
     * Format the number value to string, format the decimal separator as comma if the locale is fr or fr-FR.
     * @param {*} value Number value.
     * @param {*} locale Locale
     * @returns Formatted number string.
     */
    convertStringWithLocaleDecimalSeparator: function(value) {
      if (!value) {
        return value;
      }

      const { locale } = useI18n();

      if (typeof value == "number") {
        return this.convertStringWithLocaleDecimalSeparator(
          value.toString(),
          locale
        );
      } else if (this.isFloatString(value)) {
        return value;
      } else {
        value = value.trim();
      }

      if (["fr", "fr-FR"].includes(locale)) {
        value = value.replace(".", ",");
      }

      return value;
    }
  }
};
