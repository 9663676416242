<i18n src="@/i18n/locales/side-bar.en.json"></i18n>
<i18n src="@/i18n/locales/side-bar.fr.json"></i18n>

<template>
  <div>
    <b-sidebar
      type="is-light"
      :fullheight="true"
      :overlay="true"
      :right="true"
      v-model="open"
      :class="{
        'dark-mode': $root.isDarkMode
      }"
    >
      <div class="closeButtonCnt mr-2">
        <b-icon
          class="closeButton is-size-5"
          @click.native="open = false"
          pack="mdi"
          icon="close"
        />
      </div>
      <div
        :class="{
          'dark-mode': $root.isDarkMode
        }"
      >
        <b-menu>
          <b-menu-list label="Information">
            <MenuItem
              :title="$t('about')"
              :icon="'information'"
              :is-active="selectedOption == 'about'"
              @click.native="selectedOption = 'about'"
            >
              <span
                class="brandName"
                :class="{
                  'has-text-primary-light': $root.isDarkMode
                }"
              >
                {{ appName }}
              </span>
              <br />
              <span>
                {{ $t("appVersion") }}
                v{{ appVersion }}
              </span>
            </MenuItem>

            <MenuItem
              :title="$t('compatibility')"
              :icon="'marker-check'"
              :is-active="selectedOption == 'compatibility'"
              @click.native="selectedOption = 'compatibility'"
            >
              <AppCompatibility />
            </MenuItem>
          </b-menu-list>

          <b-menu-list v-if="false" :label="$t('profile')">
            <UserProfile />
          </b-menu-list>

          <b-menu-list
            v-if="$isAuthenticated && $isSuperViewer"
            :label="$t('features')"
          >
            <SupporterFeatures />
          </b-menu-list>

          <b-menu-list :label="$t('settings')">
            <MenuItem
              :title="$t('themes')"
              :icon="'web'"
              :is-active="selectedOption == 'themes'"
              @click.native="selectedOption = 'themes'"
            >
              <SelectTheme />
            </MenuItem>

            <MenuItem
              :title="$t('languages')"
              :icon="'format-color-fill'"
              :is-active="selectedOption == 'languages'"
              @click.native="selectedOption = 'languages'"
            >
              <LocaleSwitcher />
            </MenuItem>

            <MenuItem
              v-if="showPrefill && $isAuthenticated"
              :title="$t('prefill')"
              :icon="'pen-plus'"
              :is-active="selectedOption == 'prefill'"
              @click.native="selectedOption = 'prefill'"
            >
              <PrefillHandlingUnit />
            </MenuItem>

            <MenuItem
              v-if="$isAuthenticated"
              :title="$t('typeOfFlow')"
              :icon="'truck'"
              :is-active="selectedOption == 'flowType'"
              @click.native="selectedOption = 'flowType'"
            >
              <FlowType />
            </MenuItem>
            <b-menu-item
              v-if="$isAuthenticated && showMyCarriers"
              icon-pack="mdi"
              icon="truck-fast-outline"
              :label="$t('myCarriers')"
              :active="selectedOption == 'myCarriers'"
              :expanded="false"
              tag="router-link"
              :to="'/my-carriers'"
              @click.native="handleClickCarrierOrLocation('myCarriers')"
            >
            </b-menu-item>
            <b-menu-item
              v-if="$isAuthenticated && showMyLocations"
              icon-pack="mdi"
              icon="map-marker-outline"
              :label="$t('myLocations')"
              :active="selectedOption == 'myLocations'"
              @click.native="handleClickCarrierOrLocation('myLocations')"
              tag="router-link"
              :to="'/my-locations'"
            >
            </b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>

    <span
      @click="open = true"
      class="icon-sidebar-container"
      :class="[
        $root.isDarkMode ? 'has-background-black-ter' : 'has-background-white'
      ]"
    >
      <b-icon
        pack="fas"
        icon="chevron-left"
        class="icon-sidebar has-text-weight-bold"
      />
    </span>
  </div>
</template>

<script>
import MenuItem from "@/components/common/sidebar/MenuItem.vue";
import SelectTheme from "@/components/common/sidebar/SelectTheme.vue";
import LocaleSwitcher from "@/components/common/sidebar/LocaleSwitcher";
import AppCompatibility from "@/components/common/sidebar/AppCompatibility.vue";
import UserProfile from "@/components/common/sidebar/UserProfile.vue";
import SupporterFeatures from "@/components/common/sidebar/SupporterFeatures.vue";
import PrefillHandlingUnit from "@/components/common/sidebar/PrefillHandlingUnit.vue";
import FlowType from "@/components/common/sidebar/FlowType.vue";

export default {
  name: "SideBar",

  components: {
    MenuItem,
    SelectTheme,
    LocaleSwitcher,
    AppCompatibility,
    UserProfile,
    SupporterFeatures,
    PrefillHandlingUnit,
    FlowType
  },

  data() {
    return {
      appVersion: process.env.PACKAGE_VERSION,
      appName: process.env.APP_NAME,
      open: false,
      selectedOption: "about"
    };
  },

  computed: {
    showPrefill() {
      return window._config.app.CONFIG_PREFILL_CONTAINER_INFOS;
    },

    showMyCarriers() {
      return window._config.app.CONFIG_ENABLE_MY_CARRIERS_MANAGEMENT;
    },

    showMyLocations() {
      return window._config.app.CONFIG_ENABLE_MY_LOCATIONS_MANAGEMENT;
    }
  },

  methods: {
    /* Function to handle click on carrier or location,
    to close the sidebar automatically after route page. */
    handleClickCarrierOrLocation(option) {
      this.selectedOption = option;
      this.open = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

.closeButtonCnt {
  text-align: right;
}

.closeButton {
  cursor: pointer !important;
}

.closeButton:hover {
  color: red;
}

.b-sidebar .menu-label {
  padding-left: 0.3em;
  font-size: 0.8em;
  color: #333;
}

.dark-mode.b-sidebar .menu-label {
  padding-left: 0.3em;
  font-size: 0.8em;
  color: #dbdbdb;
}

.b-sidebar .menu-label:not(:first-child) {
  margin-top: 2em;
}

.b-sidebar .menu-list li ul {
  border-width: 2px;
  padding-left: 0.5em;
  margin-left: 0;

  label.label {
    font-size: 0.8rem;
    font-weight: 400;
    color: red !important;
  }
}

.menu-label:not(:first-child) {
  margin-top: 2em;
}

.brandName {
  color: $primary;
  font-weight: bold;
}

.b-sidebar .sidebar-content.is-fixed.is-right {
  padding: 16px 8px;
}

.b-sidebar.dark-mode .sidebar-content.is-fixed.is-right {
  background-color: #303030;
  color: white;
}

.dark-mode .menu-list a {
  color: white !important;
}

.menu-list a {
  &:hover {
    color: white !important;
    background-color: #969696 !important;
  }
}

.icon-sidebar-container {
  width: 22px;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-sidebar:hover {
  color: #e32219 !important;
  background-color: transparent;
}
</style>
