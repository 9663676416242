<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>

<template>
  <div class="px-4">
    <div class="label is-size-4 has-text-weight-normal">
      {{ $t("title") }}
    </div>
    <HorizontalTimeLine :items="getTimelineItems()" is-event-timeline />
  </div>
</template>

<script>
import _camelCase from "lodash/camelCase";

import HorizontalTimeLine from "@/components/common/timeline/HorizontalTimeLine";
import { repeatableEventsArray } from "@/helpers/constants.js";

export default {
  name: "ContainerEvents",
  components: {
    HorizontalTimeLine
  },
  props: {
    events: {
      required: true,
      type: Array
    }
  },
  methods: {
    getTimelineItems: function() {
      let res = [];

      let groupedEvents = this.groupEventsByType(this.events).sort((a, b) => {
        let d1 = new Date(a.history[0]);
        let d2 = new Date(b.history[0]);
        return d1.getTime() - d2.getTime();
      });

      for (let i = 0; i < groupedEvents.length; i++) {
        res.push({
          history: groupedEvents[i].history,
          startDate: groupedEvents[i].startDate,
          endDate: groupedEvents[i].endDate,
          title1: this.$t(
            "type." +
              _camelCase(groupedEvents[i].eventStatus.state) +
              "." +
              _camelCase(groupedEvents[i].type)
          ),
          state: groupedEvents[i].eventStatus.state,
          location: groupedEvents[i].location,
          creationDate: groupedEvents[i].creationDate
        });
      }

      return res;
    },

    getActionDescription: function(history) {
      let action = history?.action?.toLowerCase();

      if (history.status == "Draft") action = "createDraft";
      else if (history.status == "Refused") action = "updateRefused";

      if (action == "update")
        action += history.actor.isContractor ? "Contractor" : "Carrier";

      return action ? this.$i18n.t(`history.actions.${action}`) : "...";
    },

    groupEventsByType: function(events) {
      const pushIfNew = (array, newValue) => {
        if (newValue && !array.includes(newValue)) array.push(newValue);
      };

      let res = events.reduce(function(result, event) {
        let existedEvent = result.find(
          e =>
            e.type == event.type && !repeatableEventsArray.includes(event.type)
        );

        if (existedEvent) {
          existedEvent.history ??= []; // initialize if null

          // push event date to history if any
          pushIfNew(existedEvent.history, event.startDate);
          pushIfNew(existedEvent.history, event.endDate);

          // update start date and end date if the event is newer than the existed
          if (existedEvent.eventStatus.isOlderThan(event.state)) {
            existedEvent.eventStatus = new EventStatus(event.state);
            existedEvent.startDate = event.startDate;
            existedEvent.endDate = event.endDate;
            existedEvent.creationDate = event.creationDate;
          }
        } else {
          result.push(event); // push event to the grouped list if not found
          event.history ??= []; // initialize if null

          event.eventStatus = new EventStatus(event.state);

          // push event date to history if any
          pushIfNew(event.history, event.startDate);
          pushIfNew(event.history, event.endDate);
        }

        return result;
      }, []);

      // sort history in ascending order
      res.forEach(e => {
        e.history.sort((a, b) => {
          let d1 = new Date(a);
          let d2 = new Date(b);
          return d1.getTime() - d2.getTime();
        });
      });

      return res;
    }
  }
};

class EventStatus {
  static states = ["inprogress", "ko", "ok"];
  state;
  stateIndex = -1;

  constructor(state) {
    this.state = state;
    if (state) this.stateIndex = EventStatus.getWeight(state);
  }

  static getWeight(state) {
    return this.states.findIndex(sts => sts === state?.toLowerCase());
  }

  compare(state1) {
    const state1Index = EventStatus.getWeight(state1);

    if (this.stateIndex < state1Index) return 1;
    if (this.stateIndex === state1Index) return 0;
    return -1;
  }

  isOlderThan(state1) {
    return this.compare(state1) > 0;
  }
}
</script>

<style scoped>
.step-details {
  margin-top: 10px;
}
</style>
