<i18n src="@/i18n/locales/page-not-found.en.json"></i18n>
<i18n src="@/i18n/locales/page-not-found.fr.json"></i18n>

<template>
  <section
    class="section page-not-found is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
  >
    <div class="image is-128x128">
      <img :src="notFoundIcon.src" :alt="notFoundIcon.alt" />
    </div>
    <h1
      class="is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-centered m-4"
    >
      {{ errorMsg }}
    </h1>
    <b-button class="has-text-weight-bold" type="is-primary">
      <router-link class="button is-primary has-text-weight-bold" to="/">
        {{ $t("goToHomepage") }}
      </router-link>
    </b-button>
  </section>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {
      notFoundIcon: {
        src: require("@/assets/images/not_found.svg"),
        alt: "Not found"
      }
    };
  },
  props: {
    error: {
      default: "",
      type: String
    }
  },
  computed: {
    errorMsg: function() {
      return this.error ? this.error : this.$t("pageNotFound");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-not-found {
  height: 100%;
}
</style>
