<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <div v-if="containers && containers.length > 0" class="mt-4 px-4 mb-4">
    <div class="columns mt-1">
      <div class="column is-2 pt-0">
        <div
          class="column-containers is-fullheight is-scrollable-y box is-radiusless p-3"
        >
          <ContainersBoardListItemLabel
            v-for="(elem, key) in containers"
            :title="elem.container.reference || elem.container.bookingNumber"
            :indicator="elem.container.indicator"
            :container-index="key"
            :key="key"
            :is-active="selectedIndex === key"
            :is-active-popup="popupSelectedIndex === key"
            @click-label="onClickItemLabelHandler"
            @click-info="onClickItemLabelInfoHandler"
          />
        </div>
      </div>
      <div v-if="isShowContainerGeneralInfo" class="column is-3 pt-0 px-2">
        <ContainersBoardItemGeneralInfo
          :container="containers[selectedIndex].container"
          :isImport="isImport"
        />
      </div>
      <div
        class="column pt-0"
        :class="isShowContainerGeneralInfo ? 'is-7' : 'is-10'"
      >
        <ContainersBoardItemDetails
          v-if="selectedIndex > -1"
          :histories="containers[selectedIndex].histories"
          :events="containers[selectedIndex].container.events"
          :history-key="historyKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContainersBoardListItemLabel from "./ContainersBoardListItemLabel.vue";
import ContainersBoardItemGeneralInfo from "./ContainersBoardItemGeneralInfo.vue";
import ContainersBoardItemDetails from "./ContainersBoardItemDetails.vue";

export default {
  name: "ContainerBoard",
  components: {
    ContainersBoardListItemLabel,
    ContainersBoardItemGeneralInfo,
    ContainersBoardItemDetails
  },
  props: {
    containers: {
      type: Array,
      required: true
    },
    isImport: {
      type: Boolean,
      required: true,
      default: true
    },
    historyKey: Number
  },
  data() {
    return {
      popupSelectedIndex: -1,
      selectedIndex: 0
    };
  },
  computed: {
    isShowContainerGeneralInfo: function() {
      return this.popupSelectedIndex !== -1;
    }
  },
  methods: {
    onClickItemLabelHandler: function(newSelectedIndex) {
      this.selectedIndex = newSelectedIndex;

      if (this.popupSelectedIndex !== -1)
        this.popupSelectedIndex = newSelectedIndex;
    },
    onClickItemLabelInfoHandler: function(newSelectedIndex) {
      this.popupSelectedIndex =
        newSelectedIndex === this.popupSelectedIndex ? -1 : newSelectedIndex;

      if (
        this.popupSelectedIndex !== -1 &&
        this.selectedIndex !== newSelectedIndex
      )
        this.selectedIndex = newSelectedIndex;
    }
  }
};
</script>

<style>
.column-containers {
  min-height: 60vh;
}

.is-scrollable-y {
  overflow-y: scroll;
}

.is-fullheight {
  height: 100% !important;
}
</style>
