<template>
  <span>
    <a href="mailto:hello@soget.fr">
      <span class="helloMail">
        hello@soget.fr
      </span>
    </a>
  </span>
</template>
<script>
export default {
  name: "SogetMail"
};
</script>
