<template>
  <div class="pagination-container mt-4">
    <a
      class="pagination-fast"
      @click="currentPage = 1"
      :class="{
        'is-disable': currentPage == 1,
        'dark-mode': $root.isDarkMode
      }"
    >
      <b-icon icon="angle-double-left" pack="fa" />
    </a>

    <b-pagination
      :total="total"
      v-model="currentPage"
      order="is-centered"
      :per-page="limit"
      icon-pack="fa"
      icon-prev="angle-left"
      icon-next="angle-right"
      class="pagination-bar"
    />

    <a
      class="pagination-fast"
      @click="currentPage = totalPages"
      :class="{
        'is-disable': currentPage >= totalPages,
        'dark-mode': $root.isDarkMode
      }"
    >
      <b-icon icon="angle-double-right" pack="fa" />
    </a>
  </div>
</template>

<script>
export default {
  name: "PaginationBar",

  props: {
    total: Number,
    limit: Number,
    page: Number
  },

  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit("update:page", value);
      }
    },

    totalPages() {
      return (
        Math.trunc(this.total / this.limit) +
        (this.total % this.limit > 0 ? 1 : 0)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .is-disable {
    cursor: not-allowed;
    color: #c4c4c4;
    background-color: #ededed;
    border: 1px solid transparent;
  }

  .is-disable.dark-mode {
    color: #4f4f4f;
    background-color: #7f7f7f;
  }
}

.pagination-bar {
  flex: 1;
}

.pagination-fast {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 1rem;
  height: 2.5em;
  line-height: 1.5;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  margin: 0 10px;
  color: black;
}

.pagination-fast.dark-mode {
  color: #f0f0f0;
}

.pagination-previous,
.pagination-next {
  border: 1px solid #dbdbdb;
}
</style>
