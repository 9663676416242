import { install } from "./install";
import vueI18n from "@/i18n/i18n";
import Keycloak from "keycloak-js";
import { Logger } from "./helpers/logger";
import { UserProfile, UserToken } from "./model";

const OAUTH_SCOPE = "openid profile email actor";
const DEFAULT_LOGGING_ENABLE = process.env.NODE_ENV !== "production";
const USER_TOKEN_LOCALSTORAGE_KEY = "SONE-CONNECT";

export default class SoneConnect {
  static install;
  /**
   * Instance of Keycloak client.
   * @type Keycloak
   */
  _keycloak;
  _logger;
  _userProfile;

  constructor() {
    // Instance of current Vue root component.
    // This value will be assigned later in the `initialize` method.
    this.app = null;
    this.userToken = null;

    let url = new URL(window.location.href);
    let debug = url.searchParams.get("debug");
    let enableLogging = DEFAULT_LOGGING_ENABLE;
    if (debug === "true") {
      enableLogging = debug === "true";
    }
    this._logger = new Logger("SConnect", enableLogging);
  }

  get keycloak() {
    if (this._keycloak) {
      return this._keycloak;
    }

    if (window._config && window._config.sone_connect) {
      // initialize the keycloak options using the application's config file
      let keycloakOptions = {
        url: window._config.sone_connect.CONFIG_AUTH_URL,
        realm: window._config.sone_connect.CONFIG_AUTH_REALM,
        clientId: window._config.sone_connect.CONFIG_AUTH_CLIENT_ID
      };
      this._keycloak = new Keycloak(keycloakOptions);

      this._keycloak.onAuthSuccess = () => {
        this.updateUserProfile();
        this.updateUserToken();
        this.saveUserTokenToLocalStorage();

        function getRedirectUri() {
          let currentUrl = new URL(window.location.href);
          return currentUrl.searchParams.get("redirect");
        }

        let redirectUri = getRedirectUri();

        if (redirectUri !== void 0 && redirectUri !== null) {
          this.app.$router.push({ path: redirectUri }).catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      };

      this._keycloak.onAuthRefreshSuccess = () => {
        this.updateUserProfile();
        this.updateUserToken();
        this.saveUserTokenToLocalStorage();
      };

      this._keycloak.onTokenExpired = () => {
        this.keycloak
          .updateToken(5)
          .then(async refreshed => {
            this._logger.info("Token refreshed " + refreshed);
          })
          .catch(async () => {
            this._logger.error("Failed to refresh token");
            this.removeUserTokenToLocalStorage();
            window.location.reload();
          });
      };

      return this._keycloak;
    }

    return null;
  }

  get userProfile() {
    return this._userProfile;
  }

  /**
   * Initialize the Keycloak client with some inner properties.
   * @param {Vue} app Vue root component instance
   */
  async initialize(app) {
    this._logger.debug("authentication", "initialization", "handled");
    this.app = app;

    let initOption = {
      redirectUri: window.location.href,
      enableLogging: DEFAULT_LOGGING_ENABLE,
      silentCheckSsoFallback: false,
      onLoad: "check-sso",
      checkLoginIframe: false,
      scope: OAUTH_SCOPE
    };

    const savedUserToken = this.retrieveUserTokenFromLocalStorage();
    if (savedUserToken) {
      initOption = {
        ...initOption,
        token: savedUserToken.access_token,
        refreshToken: savedUserToken.refresh_token
      };
    }

    return this.keycloak
      .init(initOption)
      .then(async auth => {
        if (!auth) {
          this._logger.warn("authentication failed");
          this.removeUserTokenToLocalStorage();
        }
      })
      .catch(error => {
        this._logger.error("init authentication failed");
        this._logger.error(error);
      });
  }

  async logIn(isRouteToHome) {
    this._logger.debug("authentication", "login", "handled");
    let loginOption = {
      redirectUri: isRouteToHome
        ? window.location.origin
        : window.location.href,
      scope: OAUTH_SCOPE,
      locale: vueI18n.locale
    };
    return this.keycloak
      .login(loginOption)
      .catch(error => this._logger.error(error));
  }

  async logOut() {
    this._logger.debug("authentication", "logout", "handled");
    this.removeUserTokenToLocalStorage();
    return (
      this.keycloak
        .logout({
          redirectUri: window.location.origin
        })
        // if successfully logged out on server, do nothing because a browser will reload the page automatically,
        // else manually reload the page
        .catch(error => {
          this._logger.error(error);
          window.location.reload();
        })
    );
  }

  updateUserProfile() {
    if (this.keycloak?.authenticated) {
      this._userProfile = new UserProfile(
        this.keycloak.tokenParsed.name,
        this.keycloak.tokenParsed.preferred_username,
        this.keycloak.tokenParsed.given_name,
        this.keycloak.tokenParsed.family_name
      );
    } else {
      this._userProfile = null;
    }
  }

  updateUserToken() {
    if (this.keycloak?.authenticated) {
      this.userToken = new UserToken(
        this.keycloak.token,
        this.keycloak.refreshToken,
        this.keycloak.idToken
      );
    } else {
      this.userToken = null;
    }
  }

  saveUserTokenToLocalStorage() {
    window.localStorage.setItem(
      USER_TOKEN_LOCALSTORAGE_KEY,
      JSON.stringify(this.userToken)
    );
  }

  removeUserTokenToLocalStorage() {
    if (this.retrieveUserTokenFromLocalStorage())
      window.localStorage.removeItem(USER_TOKEN_LOCALSTORAGE_KEY);
  }

  retrieveUserTokenFromLocalStorage() {
    const localStorageValue = window.localStorage.getItem(
      USER_TOKEN_LOCALSTORAGE_KEY
    );
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }
    return null;
  }
}

SoneConnect.install = install;
