import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc.js";

const END_POINT = "/my-locations";
let etag = null;

export default {
  /**
   * Get all my Locations
   * @param {PaginationParams} params
   * @returns {Promise<HttpResponse<PaginationResponse<MyLocationSimple>>>}
   *
   * @see {@link PaginationParams}
   * @see {@link PaginationResponse}
   * @see {@link MyLocationSimple}
   */
  getAllMyLocations(_, params) {
    return PrivateCustomerOrderHttpClient.httpClient.get(`${END_POINT}`, {
      params
    });
  },

  /**
   * Create new my location
   * @param {MyLocationPost} data
   * @returns
   */
  createNewMyLocation(data) {
    return PrivateCustomerOrderHttpClient.httpClient.post(`${END_POINT}`, data);
  },

  /**
   * Get my location info by its id.
   * @param {string} id
   * @returns {Promise<MyLocationGetResponse>}
   */
  getMyLocationById(pathParams) {
    return PrivateCustomerOrderHttpClient.httpClient
      .get(`${END_POINT}/${pathParams.id}`)
      .then(response => {
        etag = response.headers.etag ?? null;
        return response.data;
      });
  },

  /**
   * Update my location.
   * @param {MyLocationPost} data
   * @returns
   */
  updateMyLocation(data) {
    return PrivateCustomerOrderHttpClient.httpClient.put(`${END_POINT}`, data, {
      headers: {
        "If-Match": etag
      }
    });
  },

  /**
   * Delete my location by id.
   * @param {string} id
   * @returns
   */
  deleteMyLocation(id) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${END_POINT}/${id}`
    );
  }
};
