import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

/**
 * Customer orders end point.
 */
const END_POINT = "/gedmouv-authorizations";

export default {
  /**
   * Get all api keys
   * @param {PaginationParams} params
   * @returns {Promise<HttpResponse<PaginationResponse<GedmouvAuthorizationGetResponse>>>}
   *
   * @see {@link PaginationParams}
   * @see {@link PaginationResponse}
   * @see {@link GedmouvAuthorizationGetResponse}
   */
  getAllApiKeys(params) {
    return PrivateCustomerOrderHttpClient.httpClient.get(
      `${END_POINT}?offset=${params?.offset}&limit=${params?.limit}`
    );
  },

  /**
   * Create new api key
   * @param {GedmouvAuthorizationPost} data
   * @returns
   */
  createApiKey(data) {
    return PrivateCustomerOrderHttpClient.httpClient.post(`${END_POINT}`, data);
  },

  /**
   * Update an api key
   * @param {string} actorCode - actor code
   * @param {GedmouvAuthorizationPut} data
   */
  editApiKey(actorCode, data) {
    return PrivateCustomerOrderHttpClient.httpClient.put(
      `${END_POINT}/${actorCode}`,
      data
    );
  },

  /**
   * Delete an api key
   * @param {string} actorCode - actor code
   */
  deleteApiKey(actorCode) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${END_POINT}/${actorCode}`
    );
  }
};
