import Vue from "vue";

// Store the Vue instance where the plugin is installed
export let _Vue;

export let LoadingState = Vue.observable({
  isLoading: false,
  currentLoadingIndex: 0,
  currentLoadingItems: []
});

export function install(Vue) {
  if (install.installed && _Vue === Vue) return;
  install.installed = true;
  _Vue = Vue;

  Object.defineProperty(Vue.prototype, "$isLoading", {
    get() {
      return LoadingState.isLoading;
    }
  });

  Vue.prototype.$enableLoading = function() {
    LoadingState.currentLoadingItems.push(LoadingState.currentLoadingIndex++);
    LoadingState.isLoading = true;

    return LoadingState.currentLoadingIndex - 1;
  };

  Vue.prototype.$disableLoading = function(item) {
    var index = LoadingState.currentLoadingItems.indexOf(item);

    if (index > -1) {
      LoadingState.currentLoadingItems.splice(index, 1);
    }

    LoadingState.isLoading = LoadingState.currentLoadingItems.length > 0;
    if (LoadingState.currentLoadingItems.length == 0)
      LoadingState.currentLoadingIndex = 0;
  };
}
