<template>
  <section
    class="section is-flex is-justify-content-center is-align-items-center"
  >
    <div class="box">
      <InvitationForm />
    </div>
  </section>
</template>

<script>
import InvitationForm from "@/components/invitation/InvitationForm.vue";

export default {
  name: "InvitationConfirmation",
  components: { InvitationForm }
};
</script>
<style lang="scss" scoped>
.box {
  padding: 24px 32px;
}
</style>
