import { render, staticRenderFns } from "./ApiKeyTable.vue?vue&type=template&id=7ff646d6&scoped=true&"
import script from "./ApiKeyTable.vue?vue&type=script&lang=js&"
export * from "./ApiKeyTable.vue?vue&type=script&lang=js&"
import style0 from "./ApiKeyTable.vue?vue&type=style&index=0&id=7ff646d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff646d6",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/api-key.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fapi-key%2FApiKeyTable.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/api-key.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fapi-key%2FApiKeyTable.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports