import moment from "moment";

export default {
  methods: {
    getQueryFilter() {
      const query = this.$route?.query ?? {};

      const filterParams = {};

      if (query.transportOrderNumber?.trim()) {
        filterParams.transportOrderNumber = query.transportOrderNumber.trim();
      }
      if (query.containerNumber?.trim()) {
        filterParams.containerNumber = query.containerNumber.trim();
      }
      if (query.carrierCode?.trim()) {
        filterParams.carrierCode = query.carrierCode.trim();
      }
      if (this.validateCustomsFlag(query.customsFlag)) {
        filterParams.processingIndicator =
          query.customsFlag.trim().toLowerCase() === "export";
      }

      const isStartDateValid =
        query.startDate?.trim() && Date.parse(query.startDate) > 0;
      const isEndDateValid =
        query.endDate?.trim() && Date.parse(query.endDate) > 0;

      let startDate = null;
      let endDate = null;

      if (isStartDateValid && isEndDateValid) {
        startDate = new Date(query.startDate);
        endDate = new Date(query.endDate);
      } else if (isStartDateValid && !isEndDateValid) {
        startDate = new Date(query.startDate);
        endDate = moment(startDate)
          .endOf("day")
          .toDate();
      } else if (!isStartDateValid && isEndDateValid) {
        endDate = new Date(query.endDate);
        startDate = moment(endDate)
          .startOf("day")
          .toDate();
      }

      if (startDate && endDate && startDate <= endDate) {
        filterParams.creationDate = [startDate, endDate];
      }

      if (parseInt(query.page)) {
        filterParams.page = parseInt(query.page);
      }
      if (parseInt(query.limit)) {
        filterParams.limit = parseInt(query.limit);
      }

      return filterParams;
    },

    validateCustomsFlag(value) {
      if (typeof value !== "string") return false;
      const cleanedValue = value?.trim()?.toLowerCase();
      if (!cleanedValue) return false;
      return ["import", "export"].includes(cleanedValue);
    },

    generateNewQueryParams() {
      const queryParams = {
        transportOrderNumber: this.transportOrderNumber,
        containerNumber: this.containerNumber,
        carrierCode: this.carrierCode,
        customsFlag: this.customsFlag,
        page: `${this.page}`,
        limit: `${this.limit}`
      };
      if (this.creationDate && this.creationDate.length > 1) {
        queryParams.startDate = this.startDate.toISOString();
        queryParams.endDate = this.endDate.toISOString();
      }

      return queryParams;
    }
  }
};
