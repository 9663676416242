export default {
  computed: {
    billingPortalUrl() {
      return window._config.app.CONFIG_BILLING_PORTAL_URL;
    },
    currentURL() {
      return window.location.href;
    },
    invoiceUrl() {
      return `${this.billingPortalUrl}/Profile/Payments`;
    },
    productName() {
      return window._config.app.CONFIG_BILLING_PORTAL_PRODUCT_NAME;
    },
    publicSubscriptionUrl() {
      return `${this.billingPortalUrl}/Payment/ConsultProductDetails?productId=${this.productName}&returnUrl=${this.currentURL}`;
    },
    subscriptionUrl() {
      return `${this.billingPortalUrl}/Payment/ProductDetails?productId=${this.productName}&returnUrl=${this.currentURL}`;
    },
    signUpUrl() {
      const currentUrlEncoded = encodeURIComponent(this.currentURL);
      return `${this.billingPortalUrl}/BillingAccount/SignUp?returnUrl=${currentUrlEncoded}`;
    },
    walletUrl() {
      return `${this.billingPortalUrl}/Wallet`;
    }
  }
};
