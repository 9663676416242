<i18n src="@/i18n/locales/api-key.en.json"></i18n>
<i18n src="@/i18n/locales/api-key.fr.json"></i18n>

<template>
  <b-modal
    :active="value"
    :can-cancel="false"
    has-modal-card
    class="modal"
    aria-role="dialog"
    aria-label="Add a new API key"
    aria-modal
  >
    <div class="card">
      <header class="modal-card-head">
        <h1 class="modal-card-title mr-6">
          <span>{{ $t("titleCreateApiKey") }}</span>
        </h1>
        <button type="button" class="delete" @click="closeModal" />
      </header>

      <ValidationObserver ref="validationObserver" tag="div" class="field">
        <div class="card-content">
          <div class="box">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              class="field is-expanded"
              tag="div"
              :name="$t('actorCode')"
            >
              <b-field>
                <template #label>
                  <LabelWithTooltip
                    :label="$t('actorCode')"
                    :is-required="true"
                  />
                </template>
                <b-input v-model="actorCode" expanded />
                <template #message>
                  <p class="has-text-danger">
                    {{ errors[0] }}
                  </p>
                </template>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              class="field is-expanded"
              tag="div"
              :name="$t('authorizationKey')"
            >
              <b-field>
                <template #label>
                  <LabelWithTooltip
                    :label="$t('authorizationKey')"
                    :is-required="true"
                  />
                </template>
                <b-input v-model="authorizationKey" expanded />
                <template #message>
                  <p class="has-text-danger">
                    {{ errors[0] }}
                  </p>
                </template>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </ValidationObserver>
      <div class="modal-card-foot is-justify-content-end">
        <b-button
          type="is-danger"
          :disabled="isWaitingSubmit"
          @click="closeModal"
        >
          {{ $t("cancel") }}
        </b-button>

        <b-button
          type="is-primary"
          :loading="isWaitingSubmit"
          :disabled="isWaitingSubmit"
          @click="submit"
        >
          <span>{{ $t("create") }}</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import notifier from "@/mixins/notifier";
import apiKeysApi from "@/repository/customer-order/apiKeys.api";
import LabelWithTooltip from "../common/LabelWithTooltip.vue";

export default {
  name: "ApiKeyModalAdd",
  mixins: [notifier],
  props: {
    /**
     * Boolean to indicate the modal is open or close.
     */
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      actorCode: null,
      authorizationKey: null,
      isWaitingSubmit: false
    };
  },
  methods: {
    closeModal: function() {
      this.resetModalData();
      this.$emit("input", false);
    },
    resetModalData: function() {
      this.actorCode = null;
      this.authorizationKey = null;
    },
    submit: async function() {
      const validateResult = await this.$refs.validationObserver.validate();
      if (!validateResult) return;
      const newData = {
        actorCode: this.actorCode,
        authorizationKey: this.authorizationKey
      };
      this.isWaitingSubmit = true;
      try {
        await apiKeysApi.createApiKey(newData);
        // display popup with successful message
        let message = this.$t("notifyNewApiKey");
        this.notifySuccess(message);
        this.$emit("add-api-key", newData);
        this.closeModal();
      } finally {
        this.isWaitingSubmit = false;
      }
    }
  },
  components: { LabelWithTooltip }
};
</script>
