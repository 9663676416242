<i18n src="@/i18n/locales/app.en.json"></i18n>
<i18n src="@/i18n/locales/app.fr.json"></i18n>

<template>
  <b-button class="cookieButton is-link" @click="OpenCookieBanner">
    {{ $t("openCookieBanner") }}
  </b-button>
</template>
<script>
export default {
  name: "CookieButton",
  methods: {
    OpenCookieBanner() {
      this.$root.isCookieBannerDisplay = true;
    }
  }
};
</script>
<style>
.cookieButton {
  margin: 25px auto auto;
  display: flex !important;
}
</style>
