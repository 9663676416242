<i18n src="@/i18n/locales/home.en.json"></i18n>
<i18n src="@/i18n/locales/home.fr.json"></i18n>
<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <div>
    <div class="is-flex is-justify-content-center">
      <div class="columns" style="width: 70%">
        <div class="column">
          <!-- Transport order number -->
          <b-field
            :label="$t('transportOrderNumber')"
            label-position="on-border"
          >
            <b-input
              id="transport-order-number"
              maxlength="256"
              v-model.trim="transportOrderNumber"
              type="text"
              @keyup.native.enter="handleEnter"
            />
          </b-field>

          <!-- Container reference -->
          <b-field :label="$t('containerNumber')" label-position="on-border">
            <b-input
              id="container-number"
              v-model.trim="containerNumber"
              type="text"
              maxlength="11"
              pattern="[a-zA-Z]{4}[0-9]{7}"
              :validation-message="$t('invalidContainerNumber')"
              @keyup.native.enter="handleEnter"
            />
          </b-field>

          <!-- Carrier code -->
          <b-field :label="$t('carrierCode')" label-position="on-border">
            <b-input
              id="carrier-code"
              maxlength="32"
              v-model.trim="carrierCode"
              type="text"
              @keyup.native.enter="handleEnter"
            />
          </b-field>
        </div>

        <div class="column">
          <!-- Order identifier -->
          <b-field
            :label="$t('orderId')"
            label-position="on-border"
            style="margin-bottom: 34px"
          >
            <b-input
              id="order-identifier"
              v-model.trim="orderId"
              type="text"
              @keyup.native.enter="handleEnter"
            />
          </b-field>

          <!-- Creation date -->
          <b-field :label="$t('creationDate')" label-position="on-border">
            <b-datepicker
              id="creation-date"
              v-model="creationDate"
              :placeholder="$t('selectDate')"
              icon="calendar"
              icon-pack="fas"
              range
              :locale="$i18n.locale"
              position="is-bottom-left"
              style="width: 100%"
              :unselectable-dates="unselectableDates"
              nearby-selectable-month-days
              @range-end="adjustCreationDateSearchOption"
            >
              <b-dropdown
                v-model="currentSearchOption"
                aria-role="list"
                :change="searchOptionChanged()"
              >
                <template #trigger>
                  <b-button
                    :label="$t(currentSearchOption.name)"
                    type="is-link is-light"
                    outlined
                    expanded
                    size="is-small"
                    icon-right="angle-down"
                  />
                </template>

                <b-dropdown-item
                  v-for="(searchOption, index) in searchOptions"
                  :key="index"
                  :value="searchOption"
                  aria-role="listitem"
                >
                  <div class="media">
                    <div class="media-content">
                      <h3>{{ $t(searchOption.name) }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-datepicker>

            <!-- Icon to remove selected date -->
            <div
              style="height: 100%"
              class="is-flex is-justify-content-left is-align-items-center"
              @click="removeCreationDate"
            >
              <b-icon
                v-show="creationDate != null"
                class="is-clickable"
                pack="fas"
                icon="times"
              ></b-icon>
            </div>
          </b-field>

          <b-field
            id="processing-indicator"
            class="is-flex is-align-items-center"
            style="margin-top: 40px"
          >
            Import
            <b-switch
              v-model="processingIndicator"
              type="is-dark"
              class="ml-2"
            />
            Export
          </b-field>

          <div class="column is-flex is-justify-content-right mt-5">
            <b-button
              type="is-primary"
              :disabled="!isSubmittable"
              :loading="isLoading"
              @click="handleSearchClick"
            >
              {{ $t("search") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div :hidden="isTableHidden">
      <b-table
        id="search-result-table"
        :row-class="() => 'is-clickable'"
        :data="orders"
        :selected.sync="selected"
        :loading="isTableLoading"
        @select="
          order =>
            $router.push({
              name: 'SuperViewer',
              params: {
                orderIdentifier: order.customerOrderId
              }
            })
        "
        hoverable
        striped
        mobile-cards
      >
        <b-table-column
          field="customerOrderId"
          :label="$t('resultTable.id')"
          v-slot="props"
        >
          {{ props.row.customerOrderId }} </b-table-column
        ><b-table-column
          field="transportOrderNumber"
          :label="$t('resultTable.transportOrderNumber')"
          v-slot="props"
        >
          {{
            props.row.transportOrderNumber | truncateTextAtChar(30)
          }} </b-table-column
        ><b-table-column
          field="carrierName"
          :label="$t('resultTable.carrierName')"
          v-slot="props"
        >
          {{ props.row.carrierName }} </b-table-column
        ><b-table-column
          field="date"
          :label="$t('resultTable.creationDate')"
          v-slot="props"
        >
          {{ props.row.date }} </b-table-column
        ><b-table-column
          field="customsFlag"
          :label="$t('resultTable.customsFlag')"
          v-slot="props"
        >
          {{ props.row.customsFlag }} </b-table-column
        ><b-table-column
          field="status"
          :label="$t('resultTable.orderStatus')"
          v-slot="props"
        >
          {{ props.row.status }}
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">{{ $t("resultTable.noRecord") }}</div>
        </template>
      </b-table>
    </div>

    <PaginationBar
      v-if="!isTableHidden"
      :total="totalItems"
      :limit="limit"
      :page="page"
      @update:page="handleTablePageChange"
    />
    <VerificationPopup />
  </div>
</template>

<script>
import PaginationBar from "@/components/common/PaginationBar.vue";
import customerOrdersApi from "@/repository/customer-order/orders.api";
import actor from "@/mixins/actor";
import queryFilter from "@/mixins/queryFilter";
import VerificationPopup from "@/components/customer-order/VerificationPopup";
import isEqual from "lodash/isEqual";
import moment from "moment";

const ContainerNumberRegex = new RegExp("[a-zA-Z]{4}[0-9]{7}");
const CUSTOMS_DATE_RANGE = "customsDateRange";
const TODAY = "today";
const LAST_SEVEN_DAYS = "lastSevenDays";
const LAST_THIRTY_DAYS = "lastThirtyDays";

export default {
  name: "OrderFilter",

  components: {
    PaginationBar,
    VerificationPopup
  },

  mixins: [actor, queryFilter],

  props: {
    /**
     * @typedef FilterData
     * @property {Query} query - Query parameters
     * @property {Object} result - Query result corresponding to the query params
     * @property {number} result.total - Total number of all matched results
     * @property {Array} result.orders - Matched order results
     */
    /**
     * @type FilterData
     */
    filterData: {
      type: Object,
      required: false
    }
  },

  data: function() {
    let today = this.dateFromToday(0);
    let searchOptions = [
      { value: null, name: CUSTOMS_DATE_RANGE },
      { value: [today, today], name: TODAY },
      { value: [this.dateFromToday(-7), today], name: LAST_SEVEN_DAYS },
      { value: [this.dateFromToday(-30), today], name: LAST_THIRTY_DAYS }
    ];

    return {
      orderId: "",
      transportOrderNumber: "",
      containerNumber: "",
      carrierCode: "",
      processingIndicator: this.$root.isExport,
      creationDate: null, //when chose date range from date time picker, creationDate will always have two elements
      orders: [],
      isLoading: false,
      isTableLoading: false,
      isTableHidden: true,
      selected: null,
      currentSearchOption: searchOptions[0],
      searchOptions: searchOptions,
      unselectableAfterDate: 31,

      totalItems: 0,
      page: 1,
      limit: 6
    };
  },

  computed: {
    /**
     * User can submit request to call API
     * if and only when user has entered at least one of these fields (order ID, transport order number, container number, carrier code, creation date)
     * and all of them are valid
     */
    isSubmittable: function() {
      return (
        (this.orderId ||
          this.transportOrderNumber ||
          this.containerNumber ||
          this.carrierCode ||
          (this.creationDate && this.creationDate.length)) &&
        (!this.containerNumber ||
          ContainerNumberRegex.test(this.containerNumber))
      );
    },

    customsFlag() {
      return !this.processingIndicator ? "Import" : "Export";
    },

    startDate() {
      return this.creationDate && this.creationDate.length > 0
        ? this.creationDate[0]
        : null;
    },

    endDate() {
      return this.creationDate && this.creationDate.length > 1
        ? moment(this.creationDate[1])
            .endOf("day")
            .toDate()
        : null;
    }
  },

  watch: {
    "$root.isExport"() {
      this.processingIndicator = this.$root.isExport;
    }
  },

  methods: {
    searchOptionChanged: function() {
      if (this.currentSearchOption.name == CUSTOMS_DATE_RANGE) {
        return;
      }
      this.creationDate = this.currentSearchOption.value;
    },

    /**
     * Adjust the searching option for the CreationDate.
     * When users choose the CreationDate searching range manually,
     * adjust the currentSearchOption value to the option CUSTOMS_DATE_RANGE.
     */
    adjustCreationDateSearchOption: function() {
      this.currentSearchOption = this.searchOptions[0];
    },

    /**
     * Date filter method passed to Buefy DatePicker component to deactivate all days beyond today.
     * The method is called continously with all the visible dates in the DatePicker dropdown panel.
     * @param {Date} date Checking date
     * @returns TRUE if the date is unselectable; else FALSE.
     */
    unselectableDates: function(date) {
      const today = new Date();
      return today < date;
    },

    removeCreationDate: function() {
      this.currentSearchOption = this.searchOptions[0];
      this.creationDate = null;
    },

    handleSearchClick: function() {
      this.isLoading = true;

      if (this.orderId) this.getOrderById();
      else this.filterCustomerOrders();
    },

    handleEnter: function() {
      if (this.isSubmittable) {
        this.handleSearchClick();
      }
    },

    handleTablePageChange(page) {
      this.page = page;
      this.filterCustomerOrders();
    },

    getOrderById: function() {
      this.transportOrderNumber = "";
      this.containerNumber = "";
      this.carrierCode = "";
      this.processingIndicator = false;
      this.creationDate = null;

      customerOrdersApi
        .getById(this.orderId)
        .then(response => {
          this.isLoading = false;
          // Navigate to order view page.
          if (response.status === 200) {
            this.$router.push({
              name: "SuperViewer",
              params: {
                orderIdentifier: response.data?.customerOrderId,
                orderObject: response.data
              }
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    filterCustomerOrders: function() {
      this.isTableLoading = true;

      const queryParams = this.generateNewQueryParams();

      const isSearchOptionsUnchanged = isEqual(
        { ...this.$route.query, page: 0, limit: 0 },
        { ...queryParams, page: 0, limit: 0 }
      );
      // If searching options (not consider value of page and limit) are changed, reset the value of page to 1.
      if (!isSearchOptionsUnchanged) {
        queryParams.page = 1;
      }

      // Convert the page value to offset value
      // Results responded by server are paged by (offset, limit)
      const offset = (queryParams.page - 1) * queryParams.limit;

      customerOrdersApi
        .filter(
          queryParams.transportOrderNumber,
          queryParams.containerNumber,
          queryParams.carrierCode,
          queryParams.customsFlag,
          queryParams.startDate,
          queryParams.endDate,
          offset,
          queryParams.limit
        )
        .then(async response => {
          for (let order of response.data.items) {
            if (order.carrier?.name) order.carrierName = order.carrier.name;
            else if (order.carrier?.code)
              order.carrierName = await this.getActorName(order.carrier.code);
            else order.carrierName = "";

            order.date = this.$d(
              new Date(order.date),
              "shortWithoutTimeZone",
              this.$i18n.locale
            );
          }

          const orders = response.data.items.map(item => {
            return {
              ...item,
              status: this.$t(`status.${item.status.toLowerCase()}`)
            };
          });

          if (isEqual(this.$route.query, queryParams)) {
            // if the query params are unchanged, just show the result list table.
            this.orders = orders;
            this.totalItems = response.data.total;
          } else {
            // else show the result list table and try to change the query params in URL to let users turn back to previous browsed page easily.
            // because the $router.push statement causes the component to be reloaded, we pass the searching result
            // to the component's props "filterData" to prevent the component from trying to retrieve the result again.
            this.$router
              .push({
                name: "Home",
                query: queryParams,
                params: {
                  filterResult: {
                    result: {
                      total: response.data.total,
                      orders: orders
                    }
                  }
                }
              })
              .catch(() => {});
          }

          this.isTableHidden = false;
          this.isTableLoading = false;
          this.isLoading = false;
        })
        .catch(e => {
          this.orders = [];
          this.isLoading = false;

          throw e;
        });
    },

    dateFromToday(number) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (number == 0) return today;
      return new Date(today.setDate(today.getDate() + number));
    },

    getInitialQuery() {
      const query = this.getQueryFilter() ?? {};

      this.transportOrderNumber = query.transportOrderNumber ?? "";
      this.containerNumber = query.containerNumber ?? "";
      this.carrierCode = query.carrierCode ?? "";
      this.processingIndicator = query.processingIndicator ?? false;
      if (query.creationDate) {
        this.creationDate = query.creationDate;
      } else {
        // if there's no stored query.creationDate, today is selected as default option for this.creationDate
        this.currentSearchOption = this.searchOptions[1];
      }
      this.page = query.page ?? 1;
      this.limit = query.limit ?? 6;

      if (this.filterData?.result) {
        this.orders = this.filterData.result.orders;
        this.totalItems = this.filterData.result.total;
        this.isTableHidden = false;
      } else if (this.isSubmittable) {
        this.handleSearchClick();
      }
    }
  },

  created() {
    this.getInitialQuery();
  }
};
</script>
