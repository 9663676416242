export default {
  data() {
    return {
      isDarkMode: Boolean(localStorage.getItem("darkMode") === "true")
    };
  },

  methods: {
    setPropertyInDarkMode() {
      document.documentElement.className = this.$root.isDarkMode ? "dark" : "";
    }
  },

  watch: {
    isDarkMode(newVal) {
      localStorage.setItem("darkMode", newVal);
      this.setPropertyInDarkMode();
    }
  }
};
