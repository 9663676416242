import useI18n from "@/i18n/i18n.js";

const i18n = useI18n();

export default {
  filters: {
    ourNumberFormatThousandSeparator: function(num, locale) {
      locale === void 0 ? (locale = i18n.locale) : locale;
      return new Intl.NumberFormat(locale).format(num);
    }
  }
};
