<i18n src="@/i18n/locales/side-bar.en.json"></i18n>
<i18n src="@/i18n/locales/side-bar.fr.json"></i18n>

<template>
  <div class="selectThemeCnt">
    <div>
      <b-field>
        {{ $t("selectTheme.changeTheme") }}
        <b-switch
          class="mt-2"
          v-model="$root.isDarkMode"
          size="is-default"
          data-testid="switch-button"
        >
          {{
            $root.isDarkMode
              ? $t("selectTheme.darkTheme")
              : $t("selectTheme.lightTheme")
          }}
        </b-switch>
      </b-field>
    </div>
    <br />
    <div class="description">
      {{ $t("selectTheme.explainThemeChanging") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectTheme"
};
</script>

<style scoped>
.selectThemeCnt {
  font-size: 0.9em;
}

.description {
  font-size: 0.85rem;
}
</style>
