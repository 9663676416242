import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

const END_POINT = "/handling-units";

export default {
  /**
   *
   * @param {string} reference - container reference
   * @param {string} customsFlag - customs flag
   * @returns {Promise<HttpResponse<HandlingUnitPrefill>>} - latest information of the container
   */
  getLatestHandlingUnit(reference, customsFlag) {
    return PrivateCustomerOrderHttpClient.httpClient.get(
      `${END_POINT}?reference=${reference}&customsFlag=${customsFlag}`
    );
  }
};
