<i18n src="@/i18n/locales/header.en.json"></i18n>
<i18n src="@/i18n/locales/header.fr.json"></i18n>

<template>
  <b-navbar
    :fixed-top="true"
    id="NavBar"
    :class="{
      borderStyle: $root.isDarkMode
    }"
  >
    <template #brand>
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/' }"
        class="is-size-3-desktop is-size-4-touch has-text-weight-bold"
      >
        <img
          v-if="$root.isDarkMode"
          src="@/assets/images/logoSMOUV_white.svg"
          class="logo"
        />
        <img v-else src="@/assets/images/logoSMOUV_rvb.svg" class="logo" />
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="router-link" :to="{ path: '/' }" data-testid="home">
        {{ $t("search") }}
      </b-navbar-item>

      <b-navbar-item
        v-if="$isAuthenticated && $isSuperViewer"
        tag="router-link"
        :to="{ path: '/api-keys' }"
      >
        {{ $t("gedmouvLogin") }}
      </b-navbar-item>

      <b-navbar-item
        v-if="$isAuthenticated && $isSuperViewer"
        tag="router-link"
        :to="{ path: '/results-logging' }"
      >
        {{ $t("resultsLogging") }}
      </b-navbar-item>

      <b-navbar-item
        v-if="$isAuthenticated && $isSuperViewer"
        tag="router-link"
        :to="{ path: '/transcodifications' }"
      >
        {{ $t("transcodifications") }}
      </b-navbar-item>

      <b-navbar-item
        v-if="$isAuthenticated && $isSuperViewer"
        tag="router-link"
        :to="{ path: '/organizations' }"
      >
        {{ $t("organizations") }}
      </b-navbar-item>

      <b-navbar-item v-if="$isAuthenticated" @click="createNewOrder">
        {{ $t("createNewOrder") }}
      </b-navbar-item>

      <b-navbar-item
        v-show="false"
        :href="$isAuthenticated ? subscriptionUrl : publicSubscriptionUrl"
        data-testid="subscriptions"
      >
        {{ $t("subscriptions") }}
      </b-navbar-item>

      <b-navbar-dropdown v-if="$isAuthenticated" hoverable right>
        <template #label>
          <span data-testid="user-name">
            {{ userName }}
          </span>
        </template>
        <b-navbar-item
          v-if="!$isSuperViewer && !$isAdmin"
          tag="router-link"
          :to="{ path: '/my-organization' }"
        >
          {{ $t("myOrganization") }}
        </b-navbar-item>
        <b-navbar-item @click="signOut()" data-testid="sign-out">
          {{ $t("signOut") }}
        </b-navbar-item>
      </b-navbar-dropdown>

      <div class="navbar-start" v-else>
        <b-navbar-item @click="logIn()" data-testid="log-in">
          {{ $t("login") }}
        </b-navbar-item>

        <b-navbar-item :href="signUpUrl" data-testid="sign-up">
          {{ $t("signUp") }}
        </b-navbar-item>
      </div>

      <b-navbar-item>
        <SideBar />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import SideBar from "@/components/common/header/SideBar";

import urlConfigurator from "@/mixins/urlConfigurator";

export default {
  name: "TheHeader",

  mixins: [urlConfigurator],

  components: {
    SideBar
  },

  computed: {
    userName() {
      return this.$soneConnect.keycloak.tokenParsed.name;
    }
  },

  methods: {
    createNewOrder() {
      this.$root.newOrderKey++;
      this.$router
        .push({
          name: "CustomerOrderEditor",
          params: {
            orderIdentifier: undefined
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

#NavBar {
  border-bottom: #d6d6d6 1px solid;
  height: 52px;
}

#NavBar.borderStyle {
  border-bottom: #4d4d4d 1px solid;
}

.logo {
  height: 64px;
}

.navbar-item {
  font-size: 16px;
  font-weight: 500;
}

.navbar-dropdown .navbar-item:hover {
  color: red;
  background-color: transparent;
}
</style>
