var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-field',{staticClass:"mt-5 large-label voyage-info",attrs:{"label":_vm.$t('voyage'),"label-position":"on-border","custom-class":"transparent-label"}},[(_vm.showVoyage)?_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns mt-1"},[_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":"max:384","name":_vm.$t('meansName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('meansName'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{on:{"blur":function($event){return _vm.onVoyageInformationChange('meansName', _vm.meansName)}},model:{value:(_vm.meansName),callback:function ($$v) {_vm.meansName=$$v},expression:"meansName"}})],1)]}}],null,false,1698997080)})],1),_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":"max:256","name":_vm.$t('freightAgent')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('freightAgent'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{on:{"blur":function($event){return _vm.onVoyageInformationChange('freightAgent', _vm.freightAgent)}},model:{value:(_vm.freightAgent),callback:function ($$v) {_vm.freightAgent=$$v},expression:"freightAgent"}})],1)]}}],null,false,3418701368)})],1),_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":"max:256","name":_vm.$t('shippingCompany')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('shippingCompany'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{on:{"blur":function($event){return _vm.onVoyageInformationChange('shippingCompany', _vm.shippingCompany)}},model:{value:(_vm.shippingCompany),callback:function ($$v) {_vm.shippingCompany=$$v},expression:"shippingCompany"}})],1)]}}],null,false,1690784024)})],1),(_vm.customsFlag === 'import')?_c('div',{staticClass:"column is-one-fifth"},[_c('b-field',{attrs:{"label":_vm.$t('eta'),"label-position":"on-border"}},[_c('DateTimePicker',{on:{"input":function($event){return _vm.onVoyageInformationChange('eta', _vm.eta)}},model:{value:(_vm.eta),callback:function ($$v) {_vm.eta=$$v},expression:"eta"}})],1)],1):_vm._e(),(_vm.customsFlag === 'export')?_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":_vm.isEditingOrder ? '' : 'date_eta_etd',"name":_vm.$t('etd')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('etd'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('DateTimePicker',{on:{"input":function($event){return _vm.onVoyageInformationChange('etd', _vm.etd)}},model:{value:(_vm.etd),callback:function ($$v) {_vm.etd=$$v},expression:"etd"}})],1)]}}],null,false,410155609)})],1):_vm._e(),_c('div',{staticClass:"is-flex is-justify-content-flex-end column is-one-fifth",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showVoyage = false}}},[_c('b-icon',{staticClass:"is-size-3",attrs:{"pack":"fas","icon":"chevron-up"}})],1)]),(_vm.customsFlag === 'export')?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":_vm.isEditingOrder ? '' : 'date_from_today',"name":_vm.$t('closingCustoms')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('closingCustoms'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('DateTimePicker',{attrs:{"position":'left'},on:{"input":function($event){return _vm.onVoyageInformationChange('closingCustoms', _vm.closingCustoms)}},model:{value:(_vm.closingCustoms),callback:function ($$v) {_vm.closingCustoms=$$v},expression:"closingCustoms"}})],1)]}}],null,false,692479441)})],1),_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":_vm.isEditingOrder ? '' : 'date_from_today',"name":_vm.$t('closingDangerous')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('closingDangerous'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('DateTimePicker',{on:{"input":function($event){return _vm.onVoyageInformationChange(
                  'closingDangerous',
                  _vm.closingDangerous
                )}},model:{value:(_vm.closingDangerous),callback:function ($$v) {_vm.closingDangerous=$$v},expression:"closingDangerous"}})],1)]}}],null,false,749186227)})],1),_c('div',{staticClass:"column is-one-fifth"},[_c('ValidationProvider',{attrs:{"rules":_vm.isEditingOrder ? '' : 'date_from_today',"name":_vm.$t('closingDelivery')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('closingDelivery'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('DateTimePicker',{on:{"input":function($event){return _vm.onVoyageInformationChange('closingDelivery', _vm.closingDelivery)}},model:{value:(_vm.closingDelivery),callback:function ($$v) {_vm.closingDelivery=$$v},expression:"closingDelivery"}})],1)]}}],null,false,1523194905)})],1)]):_vm._e()]):_c('div',{staticClass:"is-flex is-justify-content-flex-end expand-arrow mr-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showVoyage = true}}},[_c('b-icon',{staticClass:"is-size-3",attrs:{"pack":"fas","icon":"chevron-down"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }