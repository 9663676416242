<template>
  <b-menu-item
    icon-pack="mdi"
    :icon="icon"
    :active="isActiveAndExpanded"
    :expanded="isActiveAndExpanded"
  >
    <template slot="label" slot-scope="props">
      {{ title }}
      <b-icon
        pack="mdi"
        class="is-pulled-right"
        :icon="props.expanded ? 'menu-down' : 'menu-up'"
      />
    </template>

    <slot></slot>
  </b-menu-item>
</template>

<script>
export default {
  name: "MenuItem",

  props: {
    title: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: true
    },

    isActive: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    isActiveAndExpanded() {
      return this.isActive;
    }
  }
};
</script>
