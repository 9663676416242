<i18n src="@/i18n/locales/legal.fr.json"></i18n>
<i18n src="@/i18n/locales/legal.en.json"></i18n>

<template>
  <span>
    <b>{{ $t("SOGET") }}</b>
    <br />
    <soget-address />
    <br />
    <label>{{ $t("Coords.Mail") }}</label>
    <a href="mailto:dpo@soget.fr">
      dpo@soget.fr
    </a>
  </span>
</template>
<script>
import SogetAddress from "@/components/legal-notice/SogetAddress";
export default {
  name: "SogetDPO",
  components: {
    SogetAddress
  }
};
</script>
