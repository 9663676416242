<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <b-tooltip
    :label="getActorCodeLabel(actorCodeState)"
    v-if="actorCodeState != 'Unknown'"
    position="is-right"
    class="is-size-6"
  >
    <div class="ml-2">
      <span v-if="actorCodeState == 'Valid'" class="icon has-text-success">
        <i class="fas fa-check fa-md"></i>
      </span>
      <span
        v-if="actorCodeState == 'PendingValidation'"
        class="icon has-text-primary"
      >
        <i class="fas fa-clock fa-md"></i>
      </span>
      <span v-if="actorCodeState == 'Invalid'" class="icon has-text-danger">
        <i class="fas fa-exclamation-triangle fa-md"></i>
      </span>
    </div>
  </b-tooltip>
</template>
<script>
export default {
  name: "ActorCodeState",

  props: {
    actorCodeState: { type: String }
  },

  methods: {
    getActorCodeLabel(actorCodeState) {
      switch (actorCodeState) {
        case "Valid":
          return this.$t("validState");
        case "Invalid":
          return this.$t("invalidState");
        case "PendingValidation":
          return this.$t("pendingState");
        default:
          return "";
      }
    }
  }
};
</script>
