export class UserProfile {
  constructor(name, preferred_username, given_name, family_name) {
    this.name = name;
    this.preferred_username = preferred_username;
    this.given_name = given_name;
    this.family_name = family_name;
  }
}

export class UserToken {
  constructor(access_token, refresh_token, id_token) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.id_token = id_token;
  }
}
