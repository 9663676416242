<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="instruction">
    <b-icon pack="fas" icon="info" type="is-primary" />
    <div class="mt-2 has-text-primary">{{ $t("instruction") }}</div>
  </div>
</template>
<script>
export default {
  name: "InstructionInfo"
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";
.instruction {
  padding: 20px;
  background-color: #ebf3fd;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-left: $primary 5px solid;
  height: fit-content;
}
</style>
