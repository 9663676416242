import Vue from "vue";
import VueI18n from "vue-i18n";
import GetBrowserLocale from "@/i18n/util/GetBrowserLocale";
import DateTimeFormats from "@/i18n/locales/DateTimeFormat";
import SupportedLocales from "@/i18n/config/SupportedLocales";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function GetStartingLocale() {
  const browserLocale = GetBrowserLocale({ countryCodeOnly: true });
  if (Object.keys(SupportedLocales).includes(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en";
  }
}

let instance;

const useI18n = () => {
  if (instance) return instance;

  const vueI18n = new VueI18n({
    locale: GetStartingLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(),
    dateTimeFormats: DateTimeFormats,
    // suppress the fallback locale warning
    // to ignore date format config error when using SFC.
    silentFallbackWarn: true
  });

  const extraHandlers = {
    set(obj, prop, value) {
      if (prop === "locale") {
        document.getElementsByTagName("html")[0].lang = value;
      }

      obj[prop] = value;
      return true;
    }
  };

  instance = new Proxy(vueI18n, extraHandlers);

  return instance;
};

export default useI18n;
