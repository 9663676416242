import { useSOneConnect } from "@/plugins/sone-connect/install";
import HttpClient from "@/repository/httpClient";

const soneConnect = useSOneConnect();

class ReferentialHttpClient {
  static get httpClient() {
    const token = soneConnect.userToken;
    const headers = {
      Authorization: `Bearer ${token.access_token}`
    };

    let referentialHttpClient = new HttpClient(
      window._config.api.CONFIG_REFERENTIAL_URL,
      headers
    );

    return referentialHttpClient.httpClient;
  }
}

export default ReferentialHttpClient;
