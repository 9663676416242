<template>
  <section>
    <b-loading
      :is-full-page="true"
      v-model="$isLoading"
      class="has-text-red"
      :can-cancel="false"
    >
      <img :src="spinner" :width="126" :height="126" />
    </b-loading>
  </section>
</template>

<script>
import spinner from "@/assets/images/loading.gif";

export default {
  name: "TheLoader",

  data() {
    return { spinner };
  }
};
</script>
