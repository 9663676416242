var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box is-radiusless is-fullheight is-scrollable-y p-0"},[_c('CollapseCard',{attrs:{"title":_vm.$t('generalInformation'),"is-open":_vm.openCardIndex === 0},on:{"on-card-open":function($event){_vm.openCardIndex = 0}}},[_c('table',{staticClass:"table has-text-left is-fullwidth"},[_c('tbody',[(_vm.isImport)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("billOfLading")))]),_c('td',[_vm._v(_vm._s(_vm.container.billOfLading))])]):_vm._e(),(_vm.container.spi)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("pcsIdentifier")))]),_c('td',[_vm._v(_vm._s(_vm.container.spi))])]):_vm._e(),(_vm.container.rank)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("containerRank")))]),_c('td',[_vm._v(_vm._s(_vm.container.rank))])]):_vm._e(),(!_vm.isImport)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("bookingNumber")))]),_c('td',[_vm._v(_vm._s(_vm.container.bookingNumber))])]):_vm._e(),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("containerType")))]),_c('td',[_vm._v(_vm._s(_vm.container.type))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("containerGrossWeight")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("localiseNumber")(_vm.container.grossWeight))+" kg ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("tare")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("localiseNumber")(_vm.container.tare))+" kg ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("sealNumbers")))]),_c('td',_vm._l((_vm.container.sealNumbers),function(sealNumber,key){return _c('b-tag',{key:key,staticClass:"mr-1 has-primary-color"},[_vm._v(" "+_vm._s(sealNumber)+" ")])}),1)]),(_vm.container.rangeMin != null)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("rangeMin")))]),_c('td',[_vm._v(_vm._s(_vm.container.rangeMin)+" °C")])]):_vm._e(),(_vm.container.rangeMax != null)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("rangeMax")))]),_c('td',[_vm._v(_vm._s(_vm.container.rangeMax)+" °C")])]):_vm._e(),(
            _vm.container.indicator &&
              _vm.container.indicator.isReefer &&
              _vm.container.reefer
          )?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("reeferTemperature")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("localiseNumber")(_vm.container.reefer.setPoint))+" °C ")])]):_vm._e(),(
            _vm.container.indicator && _vm.container.indicator.isOog && _vm.container.oog
          )?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("oogLength")))]),_c('td',[_vm._v(_vm._s(_vm._f("localiseNumber")(_vm.container.oog.length))+" m")])]):_vm._e(),(
            _vm.container.indicator && _vm.container.indicator.isOog && _vm.container.oog
          )?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("oogWidth")))]),_c('td',[_vm._v(_vm._s(_vm._f("localiseNumber")(_vm.container.oog.width))+" m")])]):_vm._e(),(
            _vm.container.indicator && _vm.container.indicator.isOog && _vm.container.oog
          )?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("oogHeight")))]),_c('td',[_vm._v(_vm._s(_vm._f("localiseNumber")(_vm.container.oog.height))+" m")])]):_vm._e(),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("comment")))]),_c('td',[_vm._v(_vm._s(_vm.container.comment))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("customsInformation")))]),_c('td',[_vm._v(_vm._s(_vm.container.customsInformation))])])])])]),(_vm.container.goods && _vm.container.goods.length > 0)?_c('CollapseCard',{staticClass:"mt-2",attrs:{"title":_vm.$t('goodsInformation'),"is-open":_vm.openCardIndex === 1},on:{"on-card-open":function($event){_vm.openCardIndex = 1}}},[_c('table',{staticClass:"table has-text-left is-fullwidth"},[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[(_vm.container.goods.length > 1 && _vm.carouselIndex > 0)?_c('b-button',{attrs:{"type":"is-primary is-light","size":"is-small","icon-right":"arrow-left"},on:{"click":function($event){_vm.carouselIndex--}}}):_vm._e(),_c('p',{staticClass:"has-text-primary"},[_vm._v(" "+_vm._s(((_vm.$t("item", { number: _vm.carouselIndex + 1 }).toUpperCase()) + "/" + (_vm.container.goods.length)))+" ")]),(
                _vm.container.goods.length > 1 &&
                  _vm.carouselIndex < _vm.container.goods.length - 1
              )?_c('b-button',{attrs:{"type":"is-primary is-light","size":"is-small","icon-right":"arrow-right"},on:{"click":function($event){_vm.carouselIndex++}}}):_vm._e()],1)])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("type")))]),_c('td',[_vm._v(_vm._s(_vm.currentGood.type)+" - "+_vm._s(_vm.currentGood.typeDescription))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("description")))]),_c('td',[_vm._v(_vm._s(_vm.currentGood.description))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("quantity")))]),_c('td',[_vm._v(_vm._s(_vm._f("localiseNumber")(_vm.currentGood.quantity)))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("grossWeight")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("localiseNumber")(_vm.currentGood.weight))+" kg ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("volume")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("localiseNumber")(_vm.currentGood.volume))+" dm3 ")])]),(
          _vm.currentGood.hazardousClasses &&
            _vm.currentGood.hazardousClasses.length > 0
        )?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("dangerousTitle")))]),_c('td',[_c('table',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("code.undg")))]),_c('td',[_vm._v(_vm._s(_vm.currentGood.hazardousClasses[0].undgCode))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("code.imdg")))]),_c('td',[_vm._v(_vm._s(_vm.currentGood.hazardousClasses[0].imdgCode))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("packagingGroup")))]),_c('td',[_vm._v(_vm._s(_vm.currentGood.hazardousClasses[0].packagingGroup))])])])])]):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }