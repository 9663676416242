import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=d0f78bae&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&lang=js&"
export * from "./TheHeader.vue?vue&type=script&lang=js&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=d0f78bae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0f78bae",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/header.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcommon%2Fheader%2FTheHeader.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/header.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcommon%2Fheader%2FTheHeader.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports