<i18n src="@/i18n/locales/event-info.en.json"></i18n>
<i18n src="@/i18n/locales/event-info.fr.json"></i18n>
<i18n src="@/i18n/locales/my-locations.en.json"></i18n>
<i18n src="@/i18n/locales/my-locations.fr.json"></i18n>

<template>
  <b-modal
    :active="value"
    :can-cancel="false"
    has-modal-card
    class="modal"
    aria-role="dialog"
    aria-label="My location"
    aria-modal
  >
    <div class="card">
      <header class="modal-card-head">
        <h1 class="modal-card-title mr-6">
          <span v-if="!isReadOnly">{{
            $t(myLocationId ? "titleEditMyLocation" : "titleCreateMyLocation")
          }}</span>
          <span v-else>
            {{ $t("titleViewMyLocation") }}
          </span>
        </h1>
        <button type="button" class="delete" @click="closeModal" />
      </header>

      <ValidationObserver ref="validationObserver" tag="div" class="field">
        <div class="card-content">
          <div class="columns">
            <!-- Location code -->
            <div class="column">
              <Autocomplete
                v-model="code"
                :label="$t('locationCode')"
                :search-property="'code'"
                :validation-rules="'max:20'"
                :max-length="20"
                :is-fixed-length="false"
                :is-description-displayed="true"
                :label-position="'on-border'"
                :description-property="'name'"
                :fetch-all-async="fetchAllLocationsAsync"
                :fetch-async="fetchLocationAsync"
                :is-autocomplete-field-expanded="true"
                :disabled="isReadOnly"
                @input:description="onLocationCodeInputChange"
              />
            </div>

            <!-- Location name -->
            <div class="column">
              <InputWithValidation
                v-model="name"
                rules="max:255"
                is-required
                :label="$t('locationName')"
                :disabled="isReadOnly"
              />
            </div>
          </div>

          <div class="columns">
            <!-- Location address -->
            <div class="column">
              <InputWithValidation
                v-model="address"
                rules="max:255"
                :label="$t('address')"
                :disabled="isReadOnly"
              />
            </div>
          </div>

          <div class="columns">
            <!-- Location postalCode -->
            <div class="column">
              <InputWithValidation
                v-model="postalCode"
                rules="max:255"
                :label="$t('postalCode')"
                :disabled="isReadOnly"
              />
            </div>

            <!-- Location city -->
            <div class="column">
              <InputWithValidation
                v-model="city"
                rules="max:255"
                :label="$t('city')"
                :disabled="isReadOnly"
              />
            </div>

            <!-- Location country -->
            <div class="column">
              <div class="columns is-vcentered">
                <Autocomplete
                  v-model="country"
                  class="column"
                  :label="$t('country')"
                  :search-property="'alphaCode2'"
                  :validation-rules="'length:2'"
                  :max-length="2"
                  :is-fixed-length="true"
                  :is-description-displayed="true"
                  :is-autocomplete-field-expanded="true"
                  :label-position="'on-border'"
                  :description-property="'name'"
                  :disabled="isReadOnly"
                  :fetch-all-async="getAllCountriesAsync"
                  :fetch-async="getCountryAsync"
                />
              </div>
            </div>
          </div>

          <b-field
            :label="$t('contact')"
            label-position="on-border"
            class="contact-info mt-5"
            custom-class="transparent-label"
          >
            <div class="columns box">
              <!-- Contact name -->
              <div class="column">
                <InputWithValidation
                  v-model="contactName"
                  rules="max:255"
                  :label="$t('name')"
                  :disabled="isReadOnly"
                />
              </div>

              <!-- Contact email -->
              <div class="column">
                <InputWithValidation
                  v-model="email"
                  rules="max:255|email"
                  :label="$t('email')"
                  :disabled="isReadOnly"
                />
              </div>

              <!-- Contact phone -->
              <div class="column">
                <InputWithValidation
                  v-model="phone"
                  rules="max:255"
                  :label="$t('phone')"
                  :disabled="isReadOnly"
                />
              </div>
            </div>
          </b-field>
        </div>
      </ValidationObserver>

      <div class="modal-card-foot is-justify-content-end">
        <b-button
          type="is-danger"
          :disabled="isWaitingSubmit"
          @click="closeModal"
        >
          {{ $t(isReadOnly ? "close" : "cancel") }}
        </b-button>

        <b-button
          v-if="!isReadOnly"
          type="is-primary"
          :loading="isWaitingSubmit"
          :disabled="isWaitingSubmit"
          @click="handleSubmit"
        >
          <span>{{ $t(myLocationId ? "edit" : "create") }}</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import notifier from "@/mixins/notifier";
import myLocationsApi from "@/repository/customer-order/myLocations.api.js";
import countriesApi from "@/repository/referential/countries.api.js";
import locationCodesApi from "@/repository/referential/locations.api.js";

import Autocomplete from "@/components/common/Autocomplete";
import InputWithValidation from "@/components/common/InputWithValidation";

export default {
  name: "MyLocationModal",

  components: {
    Autocomplete,
    InputWithValidation
  },

  mixins: [notifier],

  props: {
    /**
     * Boolean to indicate the modal is open or close.
     */
    value: {
      type: Boolean,
      required: true,
      default: false
    },

    myLocationId: Number,

    isReadOnly: Boolean
  },

  data() {
    return {
      getAllCountriesAsync: countriesApi.getAll,
      getCountryAsync: countriesApi.get,

      code: null,
      name: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
      contactName: null,
      email: null,
      phone: null,

      isWaitingSubmit: false
    };
  },

  computed: {
    currentMyLocationId: {
      get() {
        return this.myLocationId;
      },
      set(value) {
        this.$emit("update:my-location-id", value);
      }
    },

    fetchAllLocationsAsync() {
      return locationCodesApi.getAll;
    },

    fetchLocationAsync() {
      return locationCodesApi.get;
    }
  },

  methods: {
    closeModal: function() {
      this.resetModalData();
      this.$emit("input", false);
    },

    resetModalData: function() {
      this.currentMyLocationId = null;
      this.code = null;
      this.name = null;
      this.address = null;
      this.postalCode = null;
      this.city = null;
      this.country = null;
      this.contactName = null;
      this.email = null;
      this.phone = null;
    },

    async loadDataFromApi(id) {
      await myLocationsApi.getMyLocationById({ id }).then(data => {
        this.code = data.code;
        this.name = data.name;
        this.address = data.address;
        this.city = data.city;
        this.country = data.country;
        this.postalCode = data.postalCode;

        this.contactName = data.contact?.name;
        this.email = data.contact?.email;
        this.phone = data.contact?.phone;
      });
    },

    handleSubmit: async function() {
      const validateResult = await this.$refs.validationObserver.validate();
      if (!validateResult) return;

      const newData = {
        code: this.code,
        name: this.name,
        address: this.address,
        city: this.city,
        country: this.country,
        postalCode: this.postalCode,
        contact: {
          name: this.contactName,
          email: this.email,
          phone: this.phone
        }
      };

      this.isWaitingSubmit = true;

      try {
        if (!this.currentMyLocationId) {
          await myLocationsApi.createNewMyLocation(newData);
        } else {
          await myLocationsApi.updateMyLocation({
            ...newData,
            id: this.currentMyLocationId
          });
        }

        this.$emit("get-new-data");
        this.closeModal();
      } finally {
        this.isWaitingSubmit = false;
      }
    },

    async onLocationCodeInputChange(locationName) {
      if (this.currentMyLocationId) {
        // avoid case: S)ONE name overrides customed name in edit mode
        this.name ??= locationName;
      } else {
        // if user is creating new entry, then always change the name base on given input
        this.name = locationName;
      }
    }
  },

  watch: {
    currentMyLocationId(newVal) {
      if (newVal) this.loadDataFromApi(newVal);
    }
  }
};
</script>
