var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{key:_vm.goodsIndex,ref:"goodsObserver"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_c('Autocomplete',{attrs:{"label":_vm.$t('type'),"search-property":'code',"validation-rules":'length:2',"max-length":2,"is-fixed-length":true,"is-description-displayed":true,"label-position":'on-border',"description-property":'description',"fetch-all-async":_vm.getAllPackagingCodesAsync,"fetch-async":_vm.getPackagingCodeAsync,"is-autocomplete-field-expanded":true,"disabled":_vm.isDisabled},on:{"input":function($event){return _vm.onGoodsInformationChange('type', _vm.goodsType)}},model:{value:(_vm.goodsType),callback:function ($$v) {_vm.goodsType=$$v},expression:"goodsType"}})],1),_c('div',{staticClass:"column is-one-quarter"},[_c('ValidationProvider',{attrs:{"rules":"max:264","name":_vm.$t('description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('description'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{attrs:{"disabled":_vm.isDisabled},on:{"blur":function($event){return _vm.onGoodsInformationChange('description', _vm.description)}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"integer|min_value:1","name":_vm.$t('quantity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('quantity'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{attrs:{"disabled":_vm.isDisabled},on:{"blur":function($event){return _vm.onGoodsInformationChange('quantity', _vm.quantity)}},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}})],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"double|min_value:0","name":_vm.$t('weight')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('weight'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{attrs:{"value":_vm.convertStringWithLocaleDecimalSeparator(_vm.weight),"expanded":"","disabled":_vm.isDisabled},on:{"input":function (val) { return (_vm.weight = _vm.trimLeadingZeros(val)); },"blur":function($event){return _vm.onGoodsInformationChange('weight', _vm.weight)}}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static"},[_vm._v(" "+_vm._s(_vm.$t("weightUnit"))+" ")])])],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"double|min_value:0","name":_vm.$t('volume')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('volume'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{attrs:{"value":_vm.convertStringWithLocaleDecimalSeparator(_vm.volume),"expanded":"","disabled":_vm.isDisabled},on:{"input":function (val) { return (_vm.volume = _vm.trimLeadingZeros(val)); },"blur":function($event){return _vm.onGoodsInformationChange('volume', _vm.volume)}}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static"},[_vm._v(" "+_vm._s(_vm.$t("volumeUnit"))+" ")])])],1)]}}])})],1),_c('div',{staticClass:"is-narrow is-flex is-flex-direction-column is-align-items-center"},[_vm._v(" "+_vm._s(_vm.$t("dangerous"))+" "),_c('b-tooltip',{attrs:{"label":_vm.$t('setContainerHazardous'),"type":"is-warning","active":_vm.notDangerousGoods}},[_c('b-switch',{attrs:{"type":"is-danger","disabled":_vm.notDangerousGoods || _vm.isDisabled},model:{value:(_vm.isDangerous),callback:function ($$v) {_vm.isDangerous=$$v},expression:"isDangerous"}})],1)],1),(_vm.isDangerous)?_c('div',{staticClass:"column is-1"},[_c('Autocomplete',{attrs:{"label":_vm.$t('undgCode'),"search-property":'code',"validation-rules":{
          required: _vm.isDangerous,
          length: { length: 4 }
        },"max-length":4,"is-fixed-length":true,"is-description-displayed":false,"label-position":'on-border',"description-property":'description',"fetch-all-async":_vm.getAllDangerousCodesAsync,"fetch-async":_vm.getDangerousCodeAsync,"is-autocomplete-field-expanded":true,"path-parameters":{ type: 'undg' },"disabled":_vm.isDisabled},model:{value:(_vm.undgCode),callback:function ($$v) {_vm.undgCode=$$v},expression:"undgCode"}})],1):_vm._e(),(_vm.isDangerous)?_c('div',{staticClass:"column is-1"},[_c('Autocomplete',{attrs:{"label":_vm.$t('imdgCode'),"search-property":'code',"validation-rules":{
          required: _vm.isDangerous
        },"is-description-displayed":false,"label-position":'on-border',"description-property":'description',"fetch-all-async":_vm.getAllDangerousCodesAsync,"fetch-async":_vm.getDangerousCodeAsync,"is-autocomplete-field-expanded":true,"path-parameters":{ type: 'imdg' },"disabled":_vm.isDisabled},model:{value:(_vm.imdgCode),callback:function ($$v) {_vm.imdgCode=$$v},expression:"imdgCode"}})],1):_vm._e(),(_vm.isDangerous)?_c('div',{staticClass:"column is-1"},[_c('ValidationProvider',{attrs:{"rules":"max:16","name":_vm.$t('packagingGroup')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('packagingGroup'),"label-position":"on-border"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]},proxy:true}],null,true)},[_c('b-input',{attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.packagingGroup),callback:function ($$v) {_vm.packagingGroup=$$v},expression:"packagingGroup"}})],1)]}}],null,false,3667409800)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }