import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

/**THE SUBJECT OF TRANSCODIFICATION CAN BE: ACTORS, LOCATIONS, OR CONTAINER_TYPES */

export default {
  /**
   * Create a new subject.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {ActorPost | ContainerTypePost | LocationPost} payload - Subject information.
   * @returns {Promise<HttpResponse<SubjectPostResponse>>}
   */
  postSubject(endpoint, payload) {
    const newActor = {
      code: payload.code,
      type: payload.type,
      transcodifications: payload.transcodifications?.map(transcode => {
        return {
          system: transcode.system,
          value: transcode.value
        };
      })
    };

    return PrivateCustomerOrderHttpClient.httpClient.post(endpoint, newActor);
  },

  /**
   * Get all subjects and their related transcodifications.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {PaginationParams} params - Query params
   * @returns {Promise<HttpResponse<PaginationResponse<SubjectGetResponse>>>}
   */
  getAllSubjects(endpoint, params) {
    return PrivateCustomerOrderHttpClient.httpClient.get(
      `${endpoint}?offset=${params?.offset}&limit=${params?.limit}`
    );
  },

  /**
   * Get subject information by its id.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} id - Subject id
   * @return {Promise<HttpResponse<SubjectGetResponse>>>}
   */
  getSubjectById(endpoint, id) {
    return PrivateCustomerOrderHttpClient.httpClient.get(`${endpoint}/${id}`);
  },

  /**
   * Update information of one subject.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} id - Subject ID
   * @param {ActorPut | ContainerTypePut | LocationPut} payload - Subject information
   */
  updateSubject(endpoint, id, payload) {
    return PrivateCustomerOrderHttpClient.httpClient.put(
      `${endpoint}/${id}`,
      payload
    );
  },

  /**
   * Delete one subject and its transcodifications.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} id - Subject ID
   */
  deleteSubject(endpoint, id) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${endpoint}/${id}`
    );
  },

  /**
   * Add a transcodification.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} subjectId - path parameters.
   * @param {TranscodificationPost} transcodification - transcodification information.
   * @returns {Promise<HttpResponse<TranscodificationPostResponse>>}
   */
  addTranscodification(endpoint, subjectId, transcodification) {
    return PrivateCustomerOrderHttpClient.httpClient.post(
      `${endpoint}/${subjectId}/transcodifications`,
      transcodification
    );
  },

  /**
   * Get all transcodificationss of one subject based on subject's id.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} subjectId - path parameters.
   * @returns {Promise<HttpResponse<AllTranscodificationGetResponse>>}
   */
  getAllTranscodifications(endpoint, subjectId) {
    return PrivateCustomerOrderHttpClient.httpClient.get(
      `${endpoint}/${subjectId}/transcodifications`
    );
  },

  /**
   * Update transcodification of one subject.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} subjectd - Subject ID.
   * @param {number} transcodeId - Transcodification ID.
   * @param {TranscodificationPost} transcodification - Transcodification information
   */
  updateTranscodification(endpoint, subjectId, transcodeId, transcodification) {
    return PrivateCustomerOrderHttpClient.httpClient.put(
      `${endpoint}/${subjectId}/transcodifications/${transcodeId}`,
      transcodification
    );
  },

  /**
   * Delete a transcodification of one subject.
   * @param {SubjectEndpoint} endpoint - Subject endpoint.
   * @param {number} subjectd - Subject ID.
   * @param {number} transcodeId - Transcodification ID.
   */
  deleteTranscodification(endpoint, subjectId, transcodeId) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${endpoint}/${subjectId}/transcodifications/${transcodeId}`
    );
  }
};
