<i18n src="@/i18n/locales/my-carrier.en.json"></i18n>
<i18n src="@/i18n/locales/my-carrier.fr.json"></i18n>

<template>
  <div class="columns">
    <div class="column">
      <div class="box">
        <Autocomplete
          class="field"
          v-model="carrier.code"
          :label="$t('code')"
          :search-property="'code'"
          :validation-rules="'required|max:255'"
          :max-length="255"
          :is-required="true"
          :is-description-displayed="false"
          :label-position="'on-border'"
          :description-property="'name'"
          :fetch-all-async="getAllActorsAsync"
          :is-autocomplete-field-expanded="true"
          :disabled="isViewMode || mode != 'create'"
          disabled-fetch-data-triggered-from-outside
          validation-tag="div"
          @input:description="autoChangeCarrierName"
        />

        <Autocomplete
          v-if="!isViewMode && mode == 'create'"
          v-model="carrier.name"
          :label="$t('name')"
          :search-property="'name'"
          :validation-rules="'max:128'"
          :max-length="128"
          :is-description-displayed="false"
          :label-position="'on-border'"
          :description-property="'code'"
          :fetch-all-async="getAllActorsAsync"
          :is-autocomplete-field-expanded="true"
          search-query-parameter="name"
          hide-fetch-all-async-error
          :uppercase-input="false"
          @input:description="value => (carrier.code = value)"
        />
        <InputWithValidation
          v-else
          rules="max:128"
          :label="$t('name')"
          v-model="carrier.name"
          :disabled="isViewMode"
        />
      </div>
    </div>

    <div class="column">
      <div class="box">
        <div class="has-text-primary has-text-weight-bold mb-5">
          {{ $t("contact") }}
        </div>

        <InputWithValidation
          rules="max:255"
          :label="$t('name')"
          v-model="carrier.contact.name"
          :disabled="isViewMode"
        />

        <InputWithValidation
          rules="max:255|email"
          :label="$t('email')"
          v-model="carrier.contact.email"
          :disabled="isViewMode"
        />

        <InputWithValidation
          rules="max:255"
          :label="$t('phone')"
          v-model="carrier.contact.phone"
          :disabled="isViewMode"
        />
      </div>
    </div>

    <div class="column is-5">
      <div class="box">
        <div class="has-text-primary has-text-weight-bold mb-5">
          {{ $t("agency") }}
        </div>

        <div class="columns">
          <div class="column is-three-fifths">
            <InputWithValidation
              rules="max:255"
              :label="$t('name')"
              v-model="carrier.agency.name"
              :disabled="isViewMode"
            />

            <InputWithValidation
              rules="max:255"
              :label="$t('address')"
              v-model="carrier.agency.address"
              :disabled="isViewMode"
            />

            <InputWithValidation
              rules="max:255"
              :label="$t('city')"
              v-model="carrier.agency.city"
              :disabled="isViewMode"
            />
          </div>

          <div class="is-flex is-two-fifths is-align-items-flex-end">
            <div class="column">
              <InputWithValidation
                rules="max:255"
                :label="$t('postalCode')"
                v-model="carrier.agency.postalCode"
                :disabled="isViewMode"
              />

              <Autocomplete
                v-model="carrier.agency.country"
                :label="$t('country')"
                :search-property="'alphaCode2'"
                :validation-rules="'length:2'"
                :max-length="2"
                :is-fixed-length="true"
                :is-description-displayed="true"
                :label-position="'on-border'"
                :description-property="'name'"
                :fetch-all-async="getAllCountriesAsync"
                :fetch-async="getCountryAsync"
                :is-autocomplete-field-expanded="true"
                :disabled="mode == 'view'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from "@/components/common/Autocomplete";
import InputWithValidation from "../common/InputWithValidation.vue";

import actorsApi from "@/repository/referential/actors.api";
import countriesApi from "@/repository/referential/countries.api";

export default {
  name: "CarrierForm",
  components: { Autocomplete, InputWithValidation },
  props: {
    propCarrier: {
      type: Object
    },
    mode: {
      type: String,
      default: "view",
      required: true
    }
  },
  data() {
    return {
      carrier: this.propCarrier ?? {},
      getAllActorsAsync: actorsApi.getAll,
      getActorAsync: actorsApi.get,
      getAllCountriesAsync: countriesApi.getAll,
      getCountryAsync: countriesApi.get
    };
  },
  computed: {
    isViewMode() {
      return this.mode === "view";
    }
  },
  methods: {
    autoChangeCarrierName(value) {
      if (value && this.mode == "create") this.carrier.name = value;
    }
  }
};
</script>

<style scoped>
.field:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
