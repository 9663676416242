<i18n src="@/i18n/locales/super-viewer-info-global.en.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-info-global.fr.json"></i18n>

<template>
  <!-- Informations de la commande globale -->
  <div class="mt-5 px-4">
    <h1 class="is-size-4 has-text-weight-bold pl-1 pb-2">
      {{ $t("command") }} {{ orderId }}
    </h1>

    <div class="box box-global has-text-weight-bold has-text-white mb-3">
      <div class="columns">
        <div class="column center-vertical has-text-left">
          <p>
            {{ $t("transportOrderNumber") }}
            {{ reference | truncateTextAtChar(30) }}
          </p>
          <p v-if="clientReference">
            {{ $t("clientReference", { chargeur }) }} {{ clientReference }}
          </p>
        </div>

        <div class="column center-vertical has-text-centered">
          <div class="center-horizontal">
            <p>{{ $t("contractor") }} {{ contractorName }}</p>
            <PopUpActor :actor="contractor" />
          </div>
        </div>

        <div class="column center-vertical has-text-centered">
          <div class="center-horizontal">
            <p>{{ $t("carrier") }} {{ carrierName }}</p>
            <PopUpActor :actor="carrier" />
          </div>
        </div>

        <div class="column center-vertical has-text-right">
          <p>{{ customsFlag.toUpperCase() }}</p>
          <p>{{ $d(new Date(date), "shortWithoutTimeZone") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopUpActor from "./PopUpActor.vue";

const DEFAULT_STRING = "XXX";

export default {
  name: "InfoGlobal",

  components: { PopUpActor },

  props: {
    orderId: {
      type: String,
      required: true
    },

    reference: {
      type: String,
      required: true
    },

    clientReference: {
      type: String,
      required: false,
      default: DEFAULT_STRING
    },

    contractor: {
      type: Object,
      required: true
    },

    carrier: {
      type: Object,
      required: true
    },

    customsFlag: {
      type: String,
      required: false,
      default: "IMPORT"
    },

    date: {
      type: String,
      required: true
    }
  },

  computed: {
    contractorName: function() {
      return this.getActorName(this.contractor).toUpperCase();
    },

    carrierName: function() {
      return this.getActorName(this.carrier).toUpperCase();
    },

    chargeur: function() {
      let chargeur = this.customsFlag.toLowerCase();
      chargeur = chargeur.charAt(0).toUpperCase() + chargeur.slice(1) + "er";
      return chargeur;
    }
  },

  methods: {
    getActorName: function(actor) {
      if (actor.name) return actor.name;
      if (actor.code) return actor.code;
      return DEFAULT_STRING;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.center-vertical {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.center-horizontal {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.box-global {
  background-color: $primary !important;
  border-radius: 0 !important;
}
.darkBtn {
  color: #fff !important;
  border-color: #fff !important;
}
</style>
