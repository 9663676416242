<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="mt-4 box">
    <div class="mt-4 box">
      <b-table
        :data="organizations"
        :loading="isLoading"
        hoverable
        detailed
        detail-key="id"
        detail-transition="fade"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <b-table-column
          field="id"
          width="16%"
          :label="$t('organization')"
          v-slot="props"
        >
          <strong>{{ props.row.name }}</strong>
        </b-table-column>

        <b-table-column
          field="actorCode"
          width="18%"
          :label="$t('actorCode')"
          v-slot="props"
        >
          <strong class="has-text-primary"> {{ props.row.actorCode }}</strong>
        </b-table-column>

        <b-table-column
          field="locationName"
          width="35%"
          label="Description"
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column
          field="actorCodeState"
          width="18%"
          :label="$t('actorCodeState')"
          v-slot="props"
        >
          <strong :class="getActorCodeStateColor(props.row.actorCodeState)">
            {{ getActorCodeState(props.row.actorCodeState) }}</strong
          >
        </b-table-column>

        <b-table-column width="16%" label="Action" v-slot="props">
          <div class="is-flex is-align-items-center">
            <b-button
              v-if="isVerifiedActorCode(props.row.actorCodeState)"
              type="is-warning is-light"
              style="color: orange"
              class="is-size-7 mr-3"
              id="editButton"
              icon-pack="mdi"
              icon-left="circle-edit-outline"
              @click="
                handleVerify(
                  props.row.id,
                  props.row.name,
                  props.row.actorCode,
                  props.row.actorCodeState
                )
              "
            >
              {{ $t("actionButtons.verify") }}
            </b-button>
          </div>
        </b-table-column>
        <template #detail="props">
          <div
            class="columns is-flex is-align-items-flex-start is-justify-content-stretch is-full is-flex-wrap-wrap "
          >
            <MemberItem
              v-for="member in props.row.members"
              :key="member.id"
              :member-name="member.firstName + ' ' + member.lastName"
              :email="member.email"
              :role="member.role"
            ></MemberItem>
          </div>
        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t("noRecord") }}</div>
        </template>
      </b-table>
    </div>
    <ActorCodeModal
      v-model="isOpenActorCodeModal"
      :organizationId="organizationId"
      :actorCode="actorCode"
      :actorCodeState="actorCodeState"
      :name="organizationName"
      :hideAllErrors="false"
      @updated-actor-code="getData"
    >
    </ActorCodeModal>
    <PaginationBar
      :total="totalItems"
      :limit="limit"
      v-bind:page.sync="page"
      @update:page="getData"
    />
  </div>
</template>

<script>
import organizationsApi from "@/repository/customer-order/organizations.api.js";
import PaginationBar from "@/components/common/PaginationBar.vue";
import MemberItem from "@/components/my-organization/MemberItem";
import ActorCodeModal from "@/components/my-organization/ActorCodeModal";
import actorReferential from "@/mixins/actor";

export default {
  name: "OrganizationManagement",
  data() {
    return {
      isLoading: false,

      organizationId: "",
      organizationName: "",
      actorCode: "",
      actorCodeState: "",

      organizations: [],

      totalItems: 0,
      page: 1,
      limit: 6,

      isOpenActorCodeModal: false
    };
  },

  mixins: [actorReferential],

  components: {
    PaginationBar,
    MemberItem,
    ActorCodeModal
  },

  mounted() {
    this.getData();
  },

  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    }
  },

  methods: {
    /**
     * Load data from API
     */
    async getData() {
      let paginationParams = {
        limit: this.limit,
        offset: this.offset
      };
      this.isLoading = true;
      await organizationsApi
        .getAllOrganizations(paginationParams)
        .then(async response => {
          this.totalItems = response.data.total;
          var items = response.data.items;
          for (let i = 0; i < items.length; i++) {
            items[i].description = await this.getActorName(items[i].actorCode);
          }
          return items;
        })
        .then(async response => {
          this.organizations = response;
        })
        .finally(() => (this.isLoading = false));
    },

    /**
     *
     * Handle verify actor code.
     *
     * @param {*} id organization identifier.
     * @param {*} organizationName organization name.
     * @param {*} actorCode actor code
     * @param {*} actorCodeState actor code state.
     */
    handleVerify(id, organizationName, actorCode, actorCodeState) {
      this.isOpenActorCodeModal = true;
      this.organizationId = id;
      this.organizationName = organizationName;
      this.actorCode = actorCode;
      this.actorCodeState = actorCodeState;
    },

    /**
     * Get actor code state.
     *
     * @param {*} actorCodeState  actor code state
     */
    getActorCodeState(actorCodeState) {
      switch (actorCodeState) {
        case "Valid":
          return this.$t("validState");
        case "Invalid":
          return this.$t("invalidState");
        case "PendingValidation":
          return this.$t("pendingState");
        case "Unknown":
          return this.$t("unknown");
        default:
          return "";
      }
    },

    /**
     * Get color of actor code state
     *
     * @param {*} actorCodeState
     */
    getActorCodeStateColor(actorCodeState) {
      switch (actorCodeState) {
        case "Valid":
          return "has-text-success";
        case "Invalid":
          return "has-text-danger is-size-6";
        case "PendingValidation":
          return "has-text-warning";
        default:
          return "has-text-primary";
      }
    },

    /**
     * Check actor code state is valid or not.
     *
     * @param {string} actorCodeState
     */
    isVerifiedActorCode(actorCodeState) {
      return actorCodeState != "Valid";
    }
  }
};
</script>
