<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <div v-if="!endpoint" class="transcodifications-container">
    <div class="has-text-grey mt-3">
      {{ $t("instruction") }}
    </div>

    <div
      class="transcodifications-container is-flex mt-4 columns is-mobile is-flex-wrap-wrap"
    >
      <div
        class="transcodification-item column is-half-mobile is-one-quarter-desktop  is-flex is-flex-direction-column is-align-items-center has-background-link-light has-text-primary is-clickable has-text-weight-semibold px-6 py-5 m-3 is-rounded has-text-centered"
        v-for="item in transcodificationItems"
        :key="item.id"
        @click="navigateToTranscodificationComponentPage(item.path)"
      >
        <b-icon pack="fas" :icon="item.icon" size="is-medium"></b-icon>
        {{ $t(item.name) }}
      </div>
    </div>
  </div>

  <div v-else><StackTranscodification :endpoint="endpoint" /></div>
</template>

<script>
import VueRouter from "vue-router";
import StackTranscodification from "./StackTranscodification.vue";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
  name: "MainTranscodification",

  components: { StackTranscodification },

  data() {
    return {
      transcodificationItems: [
        {
          id: 1,
          name: "actor",
          icon: "users",
          path: process.env.VUE_APP_API_ACTORS_ENDPOINT
        },
        {
          id: 2,
          name: "location",
          icon: "map-marker-alt",
          path: process.env.VUE_APP_API_LOCATIONS_ENDPOINT
        },
        {
          id: 3,
          name: "containerType",
          icon: "box",
          path: process.env.VUE_APP_API_CONTAINER_TYPES_ENDPOINT
        }
      ]
    };
  },

  computed: {
    endpoint() {
      return new URL(window.location.href).pathname.split("/")[2];
    }
  },

  methods: {
    navigateToTranscodificationComponentPage: function(path) {
      this.$router
        .push({
          path: "/transcodifications/" + path
        })
        .catch(failure => {
          if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
            this.$router.go();
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.transcodifications-container {
  width: 100%;
}
.transcodification-item {
  transition: all 0.25s ease-in-out;
  border-radius: 8px;
}

.transcodification-item:hover {
  color: #fff !important;
  background-color: $primary !important;
}
</style>
