<template>
  <ValidationProvider
    :rules="validationRules"
    v-slot="{ errors }"
    :name="label"
    :vid="vid"
    slim
  >
    <b-field :label-position="labelPosition" :class="fieldClass" ref="input">
      <template #label>
        <label
          :class="{
            'required-field': isRequired,
            'non-required-field': !isRequired
          }"
          class="mb-6"
        >
          {{ label }}
        </label>
      </template>

      <b-input
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="inputClass"
        class="relative-input"
        @mouseenter.native="handleHover"
        @mouseleave.native="isHovering = false"
        @input="$emit('input', inputValue)"
        @blur="$emit('blur', inputValue)"
      />

      <span
        class="tooltip"
        v-show="isHovering && inputValue && disabled && isOverflow"
      >
        {{ inputValue }}
      </span>
      <template #message>
        <p class="has-text-danger">
          {{ errors[0] }}
        </p>
      </template>
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  name: "InputWithValidation",
  props: {
    /**
     * initial input value.
     */
    value: String,
    /**
     * label of the input field.
     */
    label: String,
    /**
     * placeholder inside input field.
     */
    placeholder: String,
    /**
     * indicate that the field is require
     */
    isRequired: {
      type: Boolean,
      default: false
    },
    fieldClass: {
      type: String,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    },
    /**
     * position of b-field label.
     */
    labelPosition: {
      type: String,
      default: "on-border"
    },
    /**
     * vee-validate rules
     */
    rules: [String, Object],
    /**
     * Determine if this component is disabled
     */
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },

  data() {
    return {
      inputValue: "",
      isHovering: false,
      isOverflow: false
    };
  },

  computed: {
    /**
     * Random vid for ValidationProvider.
     * Each ValidationProvider needs a unique vid.
     */
    vid() {
      return Math.random()
        .toString()
        .split(".")[1];
    },

    validationRules() {
      let localRules = this.rules ?? "";

      if (this.isRequired && !localRules.includes("required")) {
        localRules =
          localRules.length > 0 ? `${localRules}|required` : "required";
      }
      return localRules;
    }
  },

  watch: {
    value(newVal) {
      this.inputValue = newVal ?? "";
    }
  },

  created() {
    if (this.value) {
      this.inputValue = this.value;
    }
  },

  methods: {
    /**
     * Handle hover input.
     */
    handleHover() {
      this.isHovering = true;
      this.getOverflowStatus();
    },

    /**
     * Get overflow input status
     */
    getOverflowStatus() {
      let div = document.createElement("div");
      div.style.display = "inline";
      div.style.width = "auto";
      div.style.visibility = "hidden";
      div.innerHTML = this.inputValue;
      document.body.appendChild(div);
      this.isOverflow =
        div.offsetWidth >= this.$refs["input"]?.$el?.clientWidth - 24;
      div.remove();
    }
  }
};
</script>
