<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <div
    class="box addItem is-flex is-flex-direction-column is-justify-content-center is-full"
  >
    <ValidationObserver ref="validationObserver" tag="div" class="field">
      <ValidationProvider
        rules="required"
        v-slot="{ errors }"
        :name="$t('system')"
        tag="div"
        class="field is-expanded"
      >
        <b-field label-position="on-border" :label="$t('system')">
          <b-select
            v-model="system"
            class="selectItem"
            :loading="isLoadingData"
          >
            <option
              v-for="s in transcodeSystems"
              :key="`${s.code}-show-value`"
              :value="s.code"
            >
              {{ s.name }}
            </option>
          </b-select>

          <template #message>
            <p class="has-text-danger">
              {{ errors[0] }}
            </p>
          </template>
        </b-field>
      </ValidationProvider>

      <div
        class="is-flex is-justify-content-space-between is-align-items-center"
      >
        <ValidationProvider
          :rules="
            endpoint == 'container-types' ? 'required|length:4' : 'required'
          "
          v-slot="{ errors }"
          :name="$t('value')"
          tag="div"
          class="field is-expanded"
          :style="{ flex: 1 }"
        >
          <b-field :label="$t('value')" label-position="on-border" class="mt-4">
            <b-input
              class="inputItem"
              v-model="value"
              :placeholder="$t('valuePlaceholder')"
              @blur="isErrorInput = value == null || value == ''"
              :loading="isLoadingData"
            />

            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>

        <div class="is-flex">
          <b-button
            icon-left="times"
            type="is-info is-light"
            outlined
            class="ml-2 mr-2"
            :class="isErrorInput ? 'mb-4' : null"
            :disabled="isBtnLoading"
            @click="handleCloseItem()"
          ></b-button>

          <b-button
            icon-left="check"
            type="is-primary"
            :class="isErrorInput ? 'mb-4' : null"
            :disabled="isBtnLoading"
            :loading="isBtnLoading"
            @click="handleAddUpdate()"
          ></b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import transcodificationsApi from "@/repository/customer-order/transcodifications.api";

const TRANSCODE_SYSTEMS = [
  {
    code: "AKANEA",
    name: "Akanea"
  },
  {
    code: "GEDMOUV",
    name: "GedMouv"
  }
];

export default {
  name: "TranscodificationAddUpdate",

  props: {
    subjectId: {
      type: Number,
      required: true
    },

    endpoint: {
      type: String,
      required: true
    },

    code: String,
    transcodeId: Number,
    oldSystem: String,
    oldValue: String,

    action: String,
    isOpenUpdate: Boolean
  },

  data() {
    return {
      isBtnLoading: false,
      isLoadingData: false,
      isErrorInput: false,

      system: this.oldSystem ? this.oldSystem : "AKANEA",
      value: this.oldValue ? this.oldValue : null
    };
  },

  computed: {
    transcodeSystems() {
      let transcodeSystems;

      if (this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT)
        transcodeSystems = TRANSCODE_SYSTEMS;
      // Only "AKANEA" system will be accepted for location and container size type transcodification
      else
        transcodeSystems = [
          {
            code: "AKANEA",
            name: "Akanea"
          }
        ];

      return transcodeSystems;
    },

    subjectCode: {
      get() {
        return this.code;
      },
      set(value) {
        // Emit 'code' variable to make add modal open or close in StackTranscodification component
        this.$emit("update:code", value);
      }
    }
  },

  watch: {
    value() {
      if (this.value != "" && this.value !== null) this.isErrorInput = false;
      else this.isErrorInput = true;
    }
  },

  methods: {
    handleCloseItem() {
      if (this.action === "update") {
        this.$emit("update:isOpenUpdate", false);
      } else this.subjectCode = null;
    },

    async handleAddUpdate() {
      const validateResult = await this.$refs.validationObserver.validate();

      if (!validateResult || this.autocompleteFieldError) {
        this.isErrorInput = true;
        return;
      }

      /* Enable button loading when call api */
      this.isBtnLoading = true;

      if (this.action === "update") {
        this.handleUpdateTranscodification();
      } else this.handleAddTranscodification();
    },

    async handleUpdateTranscodification() {
      try {
        /* Call api update an existing transcodification */
        await transcodificationsApi.updateTranscodification(
          this.endpoint,
          this.subjectId,
          this.transcodeId,
          {
            system: this.system,
            value: this.value
          }
        );

        /* Disable button loading when call api */
        this.isBtnLoading = false;
        this.handleCloseItem();

        this.$emit("update-transcode", this.subjectId);
      } catch (err) {
        /* Disable button loading when there's error */
        this.isBtnLoading = false;
      }
    },

    async handleAddTranscodification() {
      try {
        /* Call api add new transcodification */
        await transcodificationsApi.addTranscodification(
          this.endpoint,
          this.subjectId,
          {
            system: this.system,
            value: this.value
          }
        );

        /* Disable button loading when call api */
        this.isBtnLoading = false;
        this.handleCloseItem();

        this.$emit("add-transcode", this.subjectId);
      } catch (err) {
        /* Disable button loading when there's error */
        this.isBtnLoading = false;
      }
    }
  }
};
</script>
