import { render, staticRenderFns } from "./TranscodificationAddUpdate.vue?vue&type=template&id=56b1f804&"
import script from "./TranscodificationAddUpdate.vue?vue&type=script&lang=js&"
export * from "./TranscodificationAddUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/transcodification.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Ftranscodification%2FTranscodificationAddUpdate.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/transcodification.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Ftranscodification%2FTranscodificationAddUpdate.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports