<template>
  <div id="app">
    <div id="header">
      <TheHeader />
    </div>
    <div id="content">
      <router-view :key="$route.fullPath"></router-view>
      <TheLoader />
    </div>
    <cookie-banner />
    <TheFooter />
  </div>
</template>

<script>
import TheFooter from "@/components/common/footer/TheFooter.vue";
import TheHeader from "@/components/common/header/TheHeader.vue";
import TheLoader from "@/components/common/TheLoader.vue";
import CookieBanner from "@/components/legal-notice/CookieBanner";

export default {
  name: "App",
  components: {
    TheFooter,
    TheHeader,
    TheLoader,
    CookieBanner
  },
  mounted: function() {
    this.$root.setPropertyInDarkMode();
  }
};
</script>

<style>
@import "./assets/styles/app.css";
@import "./assets/styles/all.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#header {
  flex-shrink: 0;
}

#content {
  flex: 1 0 auto;
}
</style>
