import locationsApi from "@/repository/referential/locations.api.js";

export default {
  methods: {
    /**
     * Get the name of a location.
     * @param {String} locationCode Location code.
     * @returns Location name.
     * Returns the given location code as result if location not found.
     */
    getLocationName: async function(locationCode) {
      let name = "";

      if (locationCode) {
        try {
          let pathParams = {
            code: locationCode
          };

          let response = await locationsApi.get(pathParams);

          name = response?.data?.name;
        } catch (error) {
          if (error) name = locationCode;
        }
      }

      return name;
    }
  }
};
