"use strict";

/**
 * Roles of user in the S'mouv application.
 */
const UserRole = Object.freeze({
  Admin: "admin",
  SuperViewer: "support",
  Owner: "owner",
  Editor: "customer-order-editor",
  Viewer: "customer-order-viewer"
});

export default UserRole;
