<template>
  <svg
    id="SogetLogo"
    data-name="SogetLogo"
    class="SogetLogo componentLogo"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 338.3 76.1"
    style="enable-background:new 0 0 338.3 76.1;"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0Z"
        d="M50,36.5c-2.3-1.4-4.8-2.5-7.6-3.4c-2.7-0.9-5.5-1.6-8.2-2.2s-5.3-1.2-7.5-1.8c-2.1-0.6-3.9-1.4-5.2-2.4
      c-1.2-0.9-1.8-2.1-1.8-3.6c0-1.2,0.4-2.3,1.1-3.3c0.8-1,2-1.8,3.6-2.4c1.7-0.6,4.1-0.9,7-0.9c2.8,0,5.8,0.4,8.8,1.2
      c3.1,0.8,6.1,2.1,8.9,3.7l0.7,0.4L55.4,9l-0.5-0.3c-3.1-1.9-6.7-3.4-10.8-4.4s-8.3-1.5-12.4-1.5c-6.3,0-11.6,1-15.8,2.8
      c-4.2,1.9-7.4,4.5-9.5,7.7C4.3,16.5,3.3,20.1,3.3,24c0,3.6,0.7,6.6,2.1,9c1.4,2.4,3.3,4.3,5.7,5.8c2.3,1.4,4.9,2.6,7.7,3.5
      c2.7,0.9,5.5,1.6,8.2,2.2c2.7,0.6,5.2,1.2,7.4,1.9c2.2,0.6,3.9,1.4,5.2,2.4c1.2,0.9,1.8,2.1,1.8,3.6c0,1.2-0.4,2.2-1.1,3.1
      c-0.8,0.9-2,1.7-3.8,2.3s-4.1,0.9-7,0.9c-3.8,0-7.7-0.6-11.5-1.9c-3.8-1.3-7.1-2.9-9.7-4.8l-0.7-0.5L1.7,64.1l0.5,0.4
      c3,2.3,7,4.2,11.9,5.6s10.1,2.1,15.4,2.1c6.3,0,11.6-1,15.8-2.9s7.5-4.5,9.6-7.7c2.1-3.2,3.2-6.7,3.2-10.6c0-3.5-0.7-6.5-2.2-8.9
      C54.3,39.8,52.4,37.9,50,36.5z"
      />
      <path
        class="st0Z"
        d="M122.8,12.5c-3.3-3.1-7.2-5.6-11.7-7.2c-4.4-1.7-9.3-2.5-14.6-2.5c-5.3,0-10.2,0.9-14.6,2.6s-8.3,4.2-11.6,7.3
      c-3.3,3.1-5.9,6.8-7.7,11s-2.7,8.8-2.7,13.9c0,5,0.9,9.6,2.7,13.8s4.4,7.9,7.7,11c3.3,3.1,7.2,5.6,11.7,7.3
      c4.4,1.7,9.4,2.6,14.6,2.6s10.1-0.9,14.5-2.5c4.4-1.7,8.3-4.1,11.7-7.2c3.3-3.1,5.9-6.9,7.7-11.1s2.7-8.9,2.7-13.9
      s-0.9-9.7-2.7-13.9C128.7,19.4,126.1,15.7,122.8,12.5z M116.4,37.5c0,3-0.5,5.8-1.5,8.2s-2.4,4.6-4.2,6.4c-1.8,1.8-3.9,3.3-6.3,4.2
      c-2.4,1-5,1.5-7.8,1.5s-5.5-0.5-7.9-1.5s-4.5-2.4-6.3-4.2s-3.2-4-4.2-6.4s-1.5-5.2-1.5-8.2s0.5-5.8,1.5-8.2s2.4-4.6,4.2-6.4
      c1.8-1.8,3.9-3.3,6.3-4.2c2.4-1,5.1-1.5,7.9-1.5s5.5,0.5,7.8,1.5c2.4,1,4.5,2.4,6.3,4.2c1.8,1.8,3.2,4,4.2,6.4
      S116.4,34.5,116.4,37.5z"
      />
      <polygon
        class="st0Z"
        points="277.6,3.9 277.6,17.8 298.7,17.8 298.7,71.1 315.4,71.1 315.4,17.8 336.4,17.8 336.4,3.9 	"
      />
      <path
        class="st1Z"
        d="M268.5,12.5c-3.3-3.1-7.2-5.6-11.7-7.2c-4.4-1.7-9.3-2.5-14.6-2.5S232,3.7,227.6,5.4s-8.3,4.2-11.6,7.3
      s-5.9,6.8-7.7,11s-2.7,8.8-2.7,13.9c0,5,0.9,9.6,2.7,13.8s4.4,7.9,7.7,11c3.3,3.1,7.2,5.6,11.7,7.3c4.4,1.7,9.4,2.6,14.6,2.6
      c5.2,0,10.1-0.9,14.5-2.5c4.4-1.7,8.3-4.1,11.7-7.2c1.5-1.4,2.9-3.1,4.2-4.8l0.6-0.9L256.6,52l-0.3,0.3c-1.8,1.8-3.9,3.2-6.2,4.2
      c-2.4,1-5,1.5-7.8,1.5s-5.5-0.5-7.9-1.5s-4.5-2.4-6.3-4.2s-3.2-4-4.2-6.4c-0.3-0.7-0.5-1.5-0.8-2.4h55.3l0.1-0.6
      c0.2-1.7,0.4-3.5,0.4-5.2c0-5-0.9-9.7-2.7-13.9C274.4,19.4,271.8,15.7,268.5,12.5z M260.7,29.6h-36.9c0-0.1,0.1-0.2,0.1-0.3l0,0
      c1-2.4,2.4-4.6,4.2-6.4s3.9-3.3,6.3-4.2c2.4-1,5.1-1.5,7.9-1.5c2.8,0,5.5,0.5,7.8,1.5c2.4,1,4.5,2.4,6.3,4.2c1.8,1.8,3.2,4,4.2,6.4
      C260.6,29.4,260.7,29.5,260.7,29.6z"
      />
      <path
        class="st0Z"
        d="M158.8,22.8c1.8-1.8,4-3.2,6.6-4.2c2.5-1,5.3-1.5,8.4-1.5c3.3,0,6.3,0.6,9,1.8c2.7,1.2,5.3,3,7.6,5.5l0.5,0.5
      l10.7-10l-0.5-0.5c-3.3-3.8-7.3-6.7-12.1-8.7c-4.7-2-10.1-3-16-3c-5.3,0-10.3,0.9-14.7,2.5c-4.5,1.7-8.4,4.1-11.7,7.2
      s-5.9,6.8-7.7,11s-2.7,8.9-2.7,13.9s0.9,9.7,2.7,13.9s4.4,7.9,7.6,11c3.3,3.1,7.2,5.6,11.6,7.2c4.4,1.7,9.4,2.5,14.6,2.5
      c4.7,0,9.6-0.7,14.3-2.1c4.8-1.4,9-3.6,12.7-6.4l0.3-0.2V35.7h-21.1v13.8h5.7v5.8c-1,0.5-1.8,0.8-2.6,1.1c-2.8,1-5.6,1.5-8.5,1.5
      c-3,0-5.8-0.5-8.3-1.5s-4.7-2.5-6.5-4.3s-3.3-4-4.2-6.5c-1-2.5-1.5-5.2-1.5-8.1c0-3,0.5-5.8,1.5-8.3
      C155.5,26.8,157,24.6,158.8,22.8z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 120
    },
    height: {
      type: [Number, String],
      default: 27
    },
    iconColor: {
      type: String,
      default: "currentColor"
    }
  }
};
</script>

<style type="text/css">
.st0Z {
  fill: #004b9c;
}
.st1Z {
  fill: #e32219;
}
</style>
