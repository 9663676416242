import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

const END_POINT = "/invitations";

export default {
  /**
   * Create new invitation.
   * @param {InvitationPost} data - invitation pose data.
   * @returns
   */
  sendInvitation(data) {
    return PrivateCustomerOrderHttpClient.httpClient.post(`${END_POINT}`, data);
  },

  /**
   *
   * @param {string} invitationId
   * @returns {Promise<InvitationGetResponse>}
   */
  getInvitationById(invitationId) {
    return PrivateCustomerOrderHttpClient.httpClient
      .get(`${END_POINT}/${invitationId}`)
      .then(response => response.data);
  },

  /**
   * Delete
   * @param {string} invitationId - invitation id.
   * @returns
   */
  deleteInvitation(invitationId) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${END_POINT}/${invitationId}`
    );
  },

  /**
   *
   * @param {string} invitationId - invitation id.
   * @param {InvitationPatchModel} patchData - updated data.
   * @returns
   */
  acceptInvitation(invitationId, patchData) {
    return PrivateCustomerOrderHttpClient.httpClient.patch(
      `${END_POINT}/${invitationId}`,
      patchData
    );
  }
};
