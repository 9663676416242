<template>
  <div class="selectLang">
    <button
      class="button has-text-primary is-light"
      :class="{
        'has-background-grey-darker has-text-primary-light': $root.isDarkMode
      }"
      @click="switchLocale"
    >
      {{ $i18n.locale === "en" ? "Français" : "English" }}
    </button>
  </div>
</template>
<script>
import localStorage from "@/mixins/localStorage";
import { localize } from "vee-validate";

export default {
  name: "LocaleSwitcher",
  mixins: [localStorage],
  methods: {
    switchLocale: function() {
      const code = this.$i18n.locale === "en" ? "fr" : "en";
      this.$i18n.locale = code;
      this.setLocale(code);
      // Notify vee-validate about locale change
      localize(code);
    }
  },
  created: function() {
    if (this.getLocale() != null) {
      this.$i18n.locale = this.getLocale();
    }
    // Notify vee-validate about locale change
    localize(this.$i18n.locale);
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.selectLang {
  text-align: center;

  button:hover {
    color: #e32219 !important;
  }
}
</style>
