import Vue from "vue";
import Vuex from "vuex";

import createLogger from "vuex/dist/logger";

// import the auto exporter
import modules from "./modules";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const plugins = () => {
  const plugins = [];

  if (debug) {
    plugins.push(createLogger());
  }

  return plugins;
};

let instance;

const useStore = () => {
  if (instance) return instance;

  instance = new Vuex.Store({
    modules,
    strict: debug,
    plugins: plugins()
  });

  return instance;
};

export default useStore;
