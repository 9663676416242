import { render, staticRenderFns } from "./InfoAdditional.vue?vue&type=template&id=d78b7002&scoped=true&"
import script from "./InfoAdditional.vue?vue&type=script&lang=js&"
export * from "./InfoAdditional.vue?vue&type=script&lang=js&"
import style0 from "./InfoAdditional.vue?vue&type=style&index=0&id=d78b7002&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d78b7002",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/customer-order.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FInfoAdditional.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/customer-order.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FInfoAdditional.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "@/i18n/locales/voyage.en.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FInfoAdditional.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "@/i18n/locales/voyage.fr.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FInfoAdditional.vue"
if (typeof block3 === 'function') block3(component)
import block4 from "@/i18n/locales/super-viewer-info-global.en.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FInfoAdditional.vue"
if (typeof block4 === 'function') block4(component)
import block5 from "@/i18n/locales/super-viewer-info-global.fr.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FInfoAdditional.vue"
if (typeof block5 === 'function') block5(component)

export default component.exports