<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <div class="px-4">
    <div class="label is-size-4 has-text-weight-normal">
      {{ $t("history.title") }}
    </div>
    <HorizontalTimeLine :items="getTimelineItems()" />
  </div>
</template>

<script>
import HorizontalTimeLine from "@/components/common/timeline/HorizontalTimeLine";
export default {
  name: "OrderHistory",
  components: {
    HorizontalTimeLine
  },
  props: {
    histories: {
      required: true,
      type: Array
    }
  },
  methods: {
    getTimelineItems: function() {
      let res = [];
      for (let i = 0; i < this.histories.length; i++) {
        let orderStatus = this.$t(
          `status.${this.histories[i].status.toLowerCase()}`
        );

        if (this.histories[i].status.toLowerCase() == "valid") {
          let firstIndex = this.histories.findIndex(
            e => e.status.toLowerCase() == "valid"
          );
          if (firstIndex > -1 && firstIndex < i)
            orderStatus = this.$t("status.update");
        }

        res.push({
          label: this.$d(
            new Date(this.histories[i].date),
            "shortWithoutTimeZone",
            this.$i18n.locale
          ),
          title1: orderStatus,
          title2: this.getActionDescription(this.histories[i]),
          title3: this.histories[i].comment
        });
      }

      return res;
    },

    getActionDescription: function(history) {
      let action = history?.action?.toLowerCase();

      if (history.status == "Draft") action = "createDraft";
      else if (history.status == "Accepted") action = "updateAccepted";
      else if (history.status == "Refused") action = "updateRefused";

      if (action == "update")
        action += history.actor.isContractor ? "Contractor" : "Carrier";

      return action ? this.$i18n.t(`history.actions.${action}`) : "...";
    }
  },

  created() {
    this.histories = this.histories.sort((h1, h2) => {
      let d1 = new Date(h1.date);
      let d2 = new Date(h2.date);
      return d1.getTime() - d2.getTime();
    });
  }
};
</script>

<style scoped>
.step-details {
  margin-top: 10px;
}
</style>
