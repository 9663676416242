import Vue from "vue";
import useI18n from "@/i18n/i18n";

Vue.filter("truncateTextAtChar", function(value, truncatedPosition) {
  return value.length > truncatedPosition
    ? value.substr(0, truncatedPosition) + "..."
    : value;
});

Vue.filter("localiseNumber", function(value) {
  const { locale } = useI18n();
  return value ? value.toLocaleString(locale) : 0;
});
