import { NotificationProgrammatic as Notification } from "buefy";
import { throttle } from "lodash";

const throttledSuccess = throttle(message => {
  Notification.open({
    message: message,
    type: "is-success",
    hasIcon: true,
    icon: "check-circle",
    iconPack: "fas",
    position: "is-bottom-right",
    ariaCloseLabel: "Close notification",
    duration: 2500
  });
}, 5000);

export default {
  methods: {
    /**
     * Notify user that an error occurred in the system.
     * @param {String} message - Error message.
     */
    notifyError: function(message) {
      Notification.open({
        message: message,
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation-circle",
        iconPack: "fas",
        position: "is-bottom-right",
        ariaCloseLabel: "Close notification",
        duration: 5000
      });
    },

    /**
     * Notify user that an operation has been performed successfully.
     *
     * The notification opening is throttled, meaning any call following
     * the first one will not be complete until a delay has expired.
     *
     * @param {String} message - Message to be displayed to user.
     */
    notifySuccess: function(message) {
      throttledSuccess(message);
    }
  }
};
