<template>
  <span>
    <span class="nbsp address">4, rue des Lamaneurs</span>
    <br />
    <span v-if="postal === void 0">
      <span class="nbsp cedex">CS 20858</span>
      -
    </span>
    <span v-if="postal === void 0" key="postalCode">
      <span class="nbsp cedexPostalCode">76085 Le Havre cedex, </span>
    </span>
    <span v-else key="postalCode">
      <span class="nbsp basePostalCode">76600 Le Havre, </span>
    </span>
    <span class="country">France</span>
  </span>
</template>
<script>
export default {
  name: "SogetAddress",
  props: {
    postal: String
  }
};
</script>
