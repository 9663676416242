<template>
  <section class="section transcodification is-flex is-flex-direction-column ">
    <BreadCrumb />
    <MainTranscodification />
  </section>
</template>
<script>
import BreadCrumb from "../components/transcodification/BreadCrumb.vue";
import MainTranscodification from "../components/transcodification/MainTranscodification.vue";

export default {
  name: "Transcodification",
  components: {
    BreadCrumb,
    MainTranscodification
  }
};
</script>
<style>
.transcodification {
  height: 100%;
}
</style>
