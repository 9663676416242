<template>
  <section class="section is-flex is-flex-direction-column">
    <MyCarrierTable />
  </section>
</template>
<script>
import MyCarrierTable from "../components/my-carrier/MyCarrierTable.vue";

export default {
  name: "MyCarrier",

  components: { MyCarrierTable }
};
</script>
