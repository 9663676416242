<i18n src="@/i18n/locales/my-carrier.en.json"></i18n>
<i18n src="@/i18n/locales/my-carrier.fr.json"></i18n>

<template>
  <b-modal
    :active="value"
    :can-cancel="false"
    has-modal-card
    width="100vw"
    aria-role="dialog"
    aria-label="Add a new carrier"
    aria-modal
  >
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <div class="modal-card">
        <header class="modal-card-head">
          <h1 class="modal-card-title mr-6">
            <span v-if="mode != 'view'">
              {{
                $t(
                  mode == "edit" ? "titleEditMyCarrier" : "titleCreateMyCarrier"
                )
              }}
            </span>
            <span v-else>
              {{ $t("titleView") }}
            </span>
          </h1>
          <button type="button" class="delete" @click="closeModal" />
        </header>

        <div class="modal-card-body">
          <CarrierForm :prop-carrier="carrier" :mode="mode" />
        </div>

        <div class="modal-card-foot is-justify-content-end">
          <b-button
            type="is-danger"
            :disabled="isWaitingSubmit"
            @click="closeModal"
          >
            {{ $t(mode == "view" ? "close" : "cancel") }}
          </b-button>

          <b-button
            type="is-primary"
            :loading="isWaitingSubmit"
            :disabled="isWaitingSubmit"
            @click="handleSubmit(submit)"
            v-if="mode != 'view'"
          >
            <span>{{ $t(currentCarrierCode ? "edit" : "create") }}</span>
          </b-button>
        </div>
      </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import notifier from "@/mixins/notifier";
import myCarriersApi from "@/repository/customer-order/myCarriers.api";
import CarrierForm from "./CarrierForm.vue";

export default {
  name: "MyCarrierModalAdd",
  components: { CarrierForm },
  mixins: [notifier],
  props: {
    /**
     * Boolean to indicate the modal is open or close.
     */
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    editCode: { type: String },
    mode: { type: String }
  },
  computed: {
    currentCarrierCode: {
      get() {
        return this.editCode;
      },
      set(value) {
        // Emit 'code' variable to make add modal open or close in StackTranscodification component
        this.$emit("update:editCode", value);
      }
    }
  },
  data() {
    return {
      carrier: {
        code: null,
        name: null,
        contact: {
          name: null,
          email: null,
          phone: null
        },
        agency: {
          name: null,
          address: null,
          city: null,
          country: null,
          postalCode: null
        }
      },
      isWaitingSubmit: false
    };
  },
  methods: {
    closeModal: function() {
      this.resetModalData();
      this.currentCarrierCode = null;
      this.$emit("input", false);
    },
    resetModalData: function() {
      this.carrier = {
        code: null,
        name: null,
        contact: {
          name: null,
          email: null,
          phone: null
        },
        agency: {
          name: null,
          address: null,
          city: null,
          country: null,
          postalCode: null
        }
      };
    },

    // Load data from api in edit mode.
    async loadDataFromApi(code) {
      const data = await myCarriersApi.getCarrierByCode({ code });
      // Set current data with data from server
      this.carrier.code = data.code;
      this.carrier.name = data.name ?? null;
      if (data.contact) this.carrier.contact = data.contact;
      else this.carrier.contact = { name: null, email: null, phone: null };
      if (data.agency) this.carrier.agency = data.agency;
      else
        this.carrier.agency = {
          name: null,
          address: null,
          city: null,
          country: null,
          postalCode: null
        };
    },

    submit: async function() {
      const newData = {
        code: this.carrier.code,
        name: this.carrier?.name,
        contact: {
          name: this.carrier?.contact?.name,
          email: this.carrier?.contact?.email,
          phone: this.carrier?.contact?.phone
        },
        agency: {
          name: this.carrier?.agency?.name,
          address: this.carrier?.agency?.address,
          city: this.carrier?.agency?.city,
          country: this.carrier?.agency?.country,
          postalCode: this.carrier?.agency?.postalCode
        }
      };
      this.isWaitingSubmit = true;
      try {
        if (!this.currentCarrierCode) {
          await myCarriersApi.createNewCarrier(newData);
        } else {
          await myCarriersApi.updateCarrier(newData);
        }

        this.$emit("add-api-my-carrier", newData);
        this.closeModal();
      } finally {
        this.isWaitingSubmit = false;
      }
    }
  },
  watch: {
    currentCarrierCode(newVal) {
      if (newVal) this.loadDataFromApi(newVal);
    }
  }
};
</script>

<style scoped>
.modal-card {
  width: 100vw;
  padding: 0 4%;
}
</style>
