/* eslint-disable no-console */
export class Logger {
  #log_prefix = "";
  #logging_enabled = false;
  constructor(log_prefix, logging_enabled) {
    this.#log_prefix = `[${log_prefix}]`;
    this.#logging_enabled = logging_enabled;
  }

  debug(...data) {
    if (this.#logging_enabled === true) {
      console.debug(`${this.#log_prefix}`, ...data);
    }
  }

  info(...data) {
    if (this.#logging_enabled === true) {
      console.info(`${this.#log_prefix}`, ...data);
    }
  }

  warn(...data) {
    if (this.#logging_enabled === true) {
      console.warn(`${this.#log_prefix}`, ...data);
    }
  }

  error(...data) {
    if (this.#logging_enabled === true) {
      console.error(`${this.#log_prefix}`, ...data);
    }
  }
}
