import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

/**
 * Results logging end point.
 */
const END_POINT = "/logging";

export default {
  /**
   * Get all logging results of a master customer order by its ID.
   * @param {"association-results" | "ici-results"} selectedType Selected result type: association-result/ ici-result.
   * @param {string} id Order ID.
   * @returns {Promise<HttpResponse<AssociationResult[]|IciResult[]>>} - Association or Ici result related to the order id.
   */
  getResultsById(selectedType, id) {
    return PrivateCustomerOrderHttpClient.httpClient
      .get(`${END_POINT}/${id}/${selectedType}`)
      .catch(error => {
        return error.response;
      })
      .then(response => {
        return response;
      });
  }
};
