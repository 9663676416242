import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import ConnectionView from "@/views/ConnectionView";
import CustomerOrderEditor from "@/views/CustomerOrderEditor";
import MyLocations from "@/views/MyLocations";
import PageNotFound from "@/views/PageNotFound";
import SuperViewer from "@/views/SuperViewer";
import Transcodification from "@/views/Transcodification";
import ApiKey from "@/views/ApiKey";
import ResultsLogging from "@/views/ResultsLogging";
import StackTranscodification from "@/components/transcodification/StackTranscodification";
import LegalNotice from "@/views/LegalNotice";
import MyCarrier from "@/views/MyCarrier";
import MyOrganization from "@/views/MyOrganization.vue";
import OrganizationManagement from "@/components/my-organization/OrganizationManagement";
import InvitationConfirmation from "@/views/InvitationConfirmation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    alias: "/index.html"
  },
  {
    path: "/connection",
    name: "Connection",
    component: ConnectionView,
    meta: { requiresAuth: false }
  },
  {
    path: "/my-locations",
    name: "MyLocations",
    component: MyLocations
  },
  {
    path: "/customer-order/:orderIdentifier?",
    name: "CustomerOrderEditor",
    component: CustomerOrderEditor
  },
  {
    path: "/super-viewer/:orderIdentifier",
    name: "SuperViewer",
    component: SuperViewer
  },
  {
    path: "/transcodifications",
    name: "Transcodification",
    component: Transcodification,
    meta: { requiresRoleSuperViewer: true },
    children: [
      {
        path: ":item",
        name: "StackTranscodification",
        component: StackTranscodification
      }
    ]
  },
  {
    path: "/api-keys",
    name: "ApiKey",
    component: ApiKey,
    meta: { requiresRoleSuperViewer: true }
  },
  {
    path: "/results-logging",
    name: "ResultsLogging",
    component: ResultsLogging,
    meta: { requiresRoleSuperViewer: true }
  },
  {
    path: "/my-carriers",
    name: "MyCarrier",
    component: MyCarrier
  },
  {
    path: "/my-organization",
    name: "MyOrganization",
    component: MyOrganization,
    meta: { requiresNotRoleSuperViewerAndAdmin: true }
  },
  {
    path: "/organizations",
    name: "OrganizationManagement",
    meta: { requiresRoleSuperViewer: true },
    component: OrganizationManagement
  },
  {
    path: "/invitations/:invitationId",
    name: "InvitationConfirmation",
    component: InvitationConfirmation,
    meta: { requiresAuth: false }
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: LegalNotice,
    meta: { requiresAuth: false }
  },
  {
    path: "*",
    name: "NotFound",
    component: PageNotFound,
    props: true,
    meta: { requiresAuth: false }
  }
];

let instance;

const useRouter = () => {
  if (instance) return instance;

  instance = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
      // This will scroll to the top of the page each time you navigate through the new route.
      return { x: 0, y: 0 };
    }
  });

  instance.beforeEach((to, from, next) => {
    //by default all views are not accessible & only accessible views are defined requireAuth: false;
    let toMeta = mergeMetaFields(to.matched);

    if (toMeta.requiresAuth) {
      if (instance.app.$isAuthenticated !== true) {
        return next({
          name: "Connection",
          // add the path of the route we wanted to access in order to be able to return to it once authenticated
          query: { redirect: `${to.fullPath}` }
        });
      }

      // if a route requires a SuperViewer user and the user is not a SuperViewer, then navigate to Home
      if (toMeta.requiresRoleSuperViewer && !instance.app.$isSuperViewer) {
        return next({
          name: "Home"
        });
      }

      if (
        toMeta.requiresNotRoleSuperViewerAndAdmin &&
        (instance.app.$isSuperViewer || instance.app.$isAdmin)
      )
        return next({
          name: "Home"
        });

      return next();
    } else {
      return next();
    }
  });

  return instance;
};

/**
 * Merge meta fields of an array of records
 *
 * @param matched - array of matched records
 */
function mergeMetaFields(matched) {
  const defaultMeta = {
    requiresAuth: true
  };

  return matched.reduce(
    (meta, record) => Object.assign(meta, record.meta),
    defaultMeta
  );
}

export default useRouter;
