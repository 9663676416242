export default {
  methods: {
    getLocale: function() {
      return localStorage.getItem("locale");
    },
    setLocale: function(value) {
      localStorage.setItem("locale", value);
    }
  }
};
