<template>
  <div class="is-inline-block">
    <div
      class="horizontal-timeline-item-container"
      :style="marginTop ? `top: ${marginTop}; width: 20px;` : 'width: 20px;'"
    >
      <div class="horizontal-timeline-item-arrow arrow-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalTimeLineArrow",
  props: {
    marginTop: String
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.arrow-right {
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid $primary;
}
</style>
