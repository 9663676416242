<i18n src="@/i18n/locales/app.en.json"></i18n>
<i18n src="@/i18n/locales/app.fr.json"></i18n>
<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <b-modal v-model="isOpen" has-modal-card :can-cancel="false" aria-modal>
    <div class="modal-card" :style="{ width: 'auto' }">
      <header class="modal-card-head">
        <h1 class="modal-card-title">
          <span v-if="endpoint == 'actors'">{{ $t("titleEditActor") }}</span>

          <span v-if="endpoint == 'locations'">{{
            $t("titleEditLocation")
          }}</span>

          <span v-if="endpoint == 'container-types'">{{
            $t("titleEditContainerType")
          }}</span>
        </h1>
        <button type="button" class="delete" @click="handleCancel" />
      </header>

      <section class="modal-card-body">
        <div class="box">
          <span v-if="endpoint == 'actors'">
            <strong>{{ $t("currentActor") }} : </strong> {{ oldCode }} -
            {{ oldType }}
          </span>

          <span v-if="endpoint == 'locations'"
            ><strong>{{ $t("currentLocation") }} : </strong> {{ oldCode }}
          </span>

          <span v-if="endpoint == 'container-types'"
            ><strong>{{ $t("currentContainerType") }} : </strong> {{ oldCode }}
          </span>
        </div>

        <b-loading v-model="isLoadingData" :can-cancel="false"></b-loading>

        <div class="box">
          <ValidationObserver ref="validationObserver" tag="div" class="field">
            <b-field grouped>
              <!-- Code field -->
              <Autocomplete
                v-model="code"
                :label="$t('newCode')"
                :is-autocomplete-field-expanded="true"
                :is-description-displayed="false"
                :is-required="true"
                :search-property="'code'"
                :description-property="descriptionProperty"
                :placeholder="$t('common.hintTypeSomething')"
                validation-tag="div"
                validation-class="field is-expanded"
                :validation-rules="'required'"
                :fetch-all-async="getAllAsync"
                :fetch-async="getAsync"
                class="subject-update"
                @input:description="value => (type = value)"
                @input:error="value => (autocompleteFieldError = value)"
              />

              <!-- Type field -->
              <ValidationProvider
                v-if="endpoint == 'actors'"
                rules="required"
                v-slot="{ errors }"
                name="Type"
                tag="div"
                class="field is-expanded"
              >
                <b-field expanded>
                  <template #label>
                    <LabelWithTooltip
                      :label="$t('newType')"
                      :is-required="true"
                    />
                  </template>

                  <b-input
                    v-model="type"
                    :placeholder="$t('common.hintTypeSomething')"
                  />

                  <template #message>
                    <p class="has-text-danger">
                      {{ errors[0] }}
                    </p>
                  </template>
                </b-field>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
        </div>
      </section>

      <footer class="modal-card-foot is-justify-content-end">
        <b-button
          type="is-danger"
          :label="$t('cancel')"
          :disabled="isBtnLoading"
          @click="handleCancel"
        />

        <b-button
          :label="$t('edit')"
          type="is-primary"
          class="submit-button"
          :loading="isBtnLoading"
          :disabled="isBtnLoading"
          @click="handleEdit"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import transcodificationsApi from "@/repository/customer-order/transcodifications.api";

import referentialActorsApi from "@/repository/referential/actors.api.js";
import referentialLocationsApi from "@/repository/referential/locations.api.js";
import referentialContainerTypesApi from "@/repository/referential/containerTypes.api";

import notifier from "@/mixins/notifier";

import Autocomplete from "@/components/common/Autocomplete";
import LabelWithTooltip from "../common/LabelWithTooltip.vue";

export default {
  name: "SubjectModalUpdate",

  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },

    subjectId: {
      type: Number,
      required: true
    },

    endpoint: {
      type: String,
      required: true
    },

    oldCode: {
      type: String,
      required: true
    }
  },

  components: { Autocomplete, LabelWithTooltip },

  data() {
    return {
      code: null,
      type: null,
      oldType: null,

      isBtnLoading: false,
      isLoadingData: false,

      autocompleteFieldError: null,
      descriptionProperty: null,
      getAllAsync: null,
      getAsync: null
    };
  },

  mounted() {
    if (this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT) {
      this.getAllAsync = referentialActorsApi.getAll;
      this.getAsync = referentialActorsApi.get;
      this.descriptionProperty = "type";
    }

    if (this.endpoint === process.env.VUE_APP_API_LOCATIONS_ENDPOINT) {
      this.getAllAsync = referentialLocationsApi.getAll;
      this.getAsync = referentialLocationsApi.get;
      this.descriptionProperty = "name";
    }

    if (this.endpoint === process.env.VUE_APP_API_CONTAINER_TYPES_ENDPOINT) {
      this.getAllAsync = referentialContainerTypesApi.getAll;
      this.getAsync = referentialContainerTypesApi.get;
      this.descriptionProperty = "description";
    }
  },

  watch: {
    async isOpen(newVal) {
      if (
        newVal == true &&
        this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT
      ) {
        this.isLoadingData = true;

        await transcodificationsApi
          .getSubjectById(this.endpoint, this.subjectId)
          .then(response => {
            this.oldType = response.data.type;
          });

        this.isLoadingData = false;
      }
    }
  },

  methods: {
    handleCancel() {
      this.$emit("update:isOpen", false);
      this.code = "";
      this.type = "";
    },

    async handleEdit() {
      const validateResult = await this.$refs.validationObserver.validate();
      if (!validateResult || this.autocompleteFieldError) return;

      let newData = {
        code: this.code,
        type: this.type
      };

      this.isBtnLoading = true;

      try {
        await transcodificationsApi
          .updateSubject(this.endpoint, this.subjectId, newData)
          .then(() => {
            let message = this.$t("updateSucceed");
            notifier.methods.notifySuccess(message);

            this.$emit("update:isOpen", false);
            this.$emit("update-subject");
          });
      } finally {
        this.isBtnLoading = false;
      }
    }
  }
};
</script>
