<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>
<i18n src="@/i18n/locales/voyage.en.json"></i18n>
<i18n src="@/i18n/locales/voyage.fr.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-info-global.en.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-info-global.fr.json"></i18n>

<template>
  <div>
    <div
      class="container is-flex is-align-items-center is-justify-content-center"
    >
      <b-button
        icon-pack="fas"
        :class="$root.isDarkMode ? 'darkBtn' : null"
        type="is-primary"
        outlined
        :icon-right="isCollapseOpen ? 'chevron-up' : 'chevron-down'"
        @click="isCollapseOpen = !isCollapseOpen"
        :label="isCollapseOpen ? $t('lessDetails') : $t('moreDetails')"
      />
    </div>

    <b-collapse :open="isCollapseOpen" class="px-4">
      <!-- Informations repliables -->
      <div class="mt-3">
        <div class="box box-global has-text-black mt-2">
          <div class="tile is-ancestor">
            <div class="tile is-parent is-4">
              <div class="tile is-child is-flex is-flex-direction-column">
                <p class="has-text-weight-bold has-text-white mb-2">
                  {{ $t("voyage") }}
                </p>

                <div class="box p-3 is-flex-grow-5">
                  <table class="table is-fullwidth">
                    <tbody>
                      <tr>
                        <th>{{ $t("meansName") }}</th>
                        <td>{{ voyage ? voyage.meansName : "" }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t("freightAgent") }}</th>
                        <td>{{ voyage ? voyage.freightAgent : "" }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t("shippingCompany") }}</th>
                        <td>{{ voyage ? voyage.shippingCompany : "" }}</td>
                      </tr>
                      <tr v-if="isImport">
                        <th>{{ $t("eta") }}</th>
                        <td>
                          {{
                            voyage && voyage.eta
                              ? $d(new Date(voyage.eta), "shortWithoutTimeZone")
                              : ""
                          }}
                        </td>
                      </tr>
                      <template v-else>
                        <tr>
                          <th>{{ $t("etd") }}</th>
                          <td>
                            {{
                              voyage && voyage.etd
                                ? $d(
                                    new Date(voyage.etd),
                                    "shortWithoutTimeZone"
                                  )
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>{{ $t("closingCustoms") }}</th>
                          <td>
                            {{
                              voyage && voyage.closingCustoms
                                ? $d(
                                    new Date(voyage.closingCustoms),
                                    "shortWithoutTimeZone"
                                  )
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>{{ $t("closingDangerous") }}</th>
                          <td>
                            {{
                              voyage && voyage.closingDangerous
                                ? $d(
                                    new Date(voyage.closingDangerous),
                                    "shortWithoutTimeZone"
                                  )
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>{{ $t("closingDelivery") }}</th>
                          <td>
                            {{
                              voyage && voyage.closingDelivery
                                ? $d(
                                    new Date(voyage.closingDelivery),
                                    "shortWithoutTimeZone"
                                  )
                                : ""
                            }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="tile is-vertical">
              <div class="tile">
                <div class="tile is-parent">
                  <div class="tile is-child  is-flex is-flex-direction-column">
                    <p class="has-text-weight-bold has-text-white mb-3">
                      {{ $t("priceInformation") }}
                    </p>
                    <div class="box p-3 is-flex-grow-5">
                      <div class="content">
                        <p>
                          {{ priceInformation }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tile is-parent">
                  <div class="tile is-child is-flex is-flex-direction-column">
                    <p class="has-text-weight-bold has-text-white mb-3">
                      {{ $t("instructionsInformation") }}
                    </p>
                    <div class="box p-3 is-flex-grow-5">
                      <div class="content">
                        <p>{{ instructionsInformation }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tile">
                <div class="tile is-parent">
                  <div class="tile is-child is-flex is-flex-direction-column">
                    <p class="has-text-weight-bold has-text-white mb-3">
                      {{ $t("contractTermsInformation") }}
                    </p>
                    <div class="box p-3 is-flex-grow-5">
                      <div class="content">
                        <p>{{ contractTermsInformation }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tile is-parent">
                  <div class="tile is-child is-flex is-flex-direction-column">
                    <p class="has-text-weight-bold has-text-white mb-3">
                      {{ $t("comments") }}
                    </p>
                    <div class="box p-3 is-flex-grow-5">
                      <div class="content">
                        <p>{{ comments }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "InfoAdditional",

  props: {
    voyage: {
      type: Object,
      required: false
    },

    priceInformation: {
      type: String,
      required: false,
      default: ""
    },

    comments: {
      type: String,
      required: false,
      default: ""
    },

    instructionsInformation: {
      type: String,
      required: false,
      default: ""
    },

    contractTermsInformation: {
      type: String,
      required: false,
      default: ""
    },

    isImport: {
      type: Boolean,
      required: true
    }
  },

  data: function() {
    return {
      isCollapseOpen: false
    };
  }
};
</script>

<style scoped>
.box {
  border-radius: 0 !important;
}
</style>
