const scrollToError = {
  methods: {
    scrollToError: function() {
      var errorMessages = this.$el.querySelectorAll(".has-text-danger");
      for (const element of errorMessages) {
        if (element.innerHTML) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          break;
        }
      }
    }
  }
};

export { scrollToError };
