<template>
  <span class="nbsp">
    <span class="amount">
      {{ 1012288 | ourNumberFormatThousandSeparator }}
    </span>
    <span class="devise">euros</span>
  </span>
</template>
<script>
import formatText from "@/mixins/formatText";
export default {
  name: "SogetCapital",
  mixins: [formatText]
};
</script>
