<i18n src="@/i18n/locales/side-bar.en.json"></i18n>
<i18n src="@/i18n/locales/side-bar.fr.json"></i18n>

<template>
  <div>
    <div>
      <b-field>
        {{ $t("prefillHandlingUnit.mode") }}
        <b-switch
          class="mt-2"
          v-model="$root.isPrefill"
          size="is-default"
          data-testid="switch-button"
        >
          {{
            $root.isPrefill
              ? $t("prefillHandlingUnit.on")
              : $t("prefillHandlingUnit.off")
          }}
        </b-switch>
      </b-field>
    </div>
    <br />
    <div class="description">
      {{ $t("prefillHandlingUnit.description") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PrefillHandlingUnit"
};
</script>

<style scoped>
.description {
  font-size: 0.85rem;
}
</style>
