<i18n src="@/i18n/locales/home.en.json"></i18n>
<i18n src="@/i18n/locales/home.fr.json"></i18n>

<template>
  <section class="section home is-flex is-align-items-center">
    <div style="width: 100%">
      <OrderFilter :filter-data="orderFilterResult" />
    </div>
  </section>
</template>

<script>
import OrderFilter from "@/components/home/OrderFilter.vue";

export default {
  name: "Home",
  components: {
    OrderFilter
  },
  data() {
    return {
      orderFilterResult: this.$route.params.filterResult ?? null
    };
  }
};
</script>

<style>
.home {
  height: 100%;
}
</style>
