<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <b-select v-model="roleVal">
    <option :value="roleOwner">{{ $t("member.role.owner") }}</option>
    <option :value="roleEditor">
      {{ $t("member.role.customer-order-editor") }}
    </option>
  </b-select>
</template>
<script>
import UserRole from "@/plugins/sone-connect/roles";

export default {
  name: "RoleSelect",

  data() {
    return {
      roleOwner: UserRole.Owner,
      roleEditor: UserRole.Editor
    };
  },

  props: {
    role: String
  },

  computed: {
    roleVal: {
      get() {
        return this.role;
      },
      set(value) {
        this.$emit("update:role", value);
      }
    }
  }
};
</script>
