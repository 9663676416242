import actorsApi from "@/repository/referential/actors.api";

export default {
  methods: {
    getActorName: function(actorCode) {
      let pathParams = {
        code: actorCode
      };

      return actorsApi
        .get(pathParams)
        .then(response => {
          return Promise.resolve(response.data.name);
        })
        .catch(() => {
          return Promise.resolve(actorCode);
        });
    }
  }
};
