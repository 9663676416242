<i18n src="@/i18n/locales/legal.fr.json"></i18n>
<i18n src="@/i18n/locales/legal.en.json"></i18n>

<template>
  <b-modal v-model="$root.isCookieBannerDisplay">
    <div class="cookie-banner">
      <h1>{{ $t("Cookies.Title") }}</h1>
      <div>{{ $t("Cookies.Explain") }}</div>
      <div class="cookies-about">
        <b-collapse :open="false" aria-id="CookiesAboutFunctionnal">
          <a
            slot="trigger"
            slot-scope="props"
            aria-controls="CookiesAboutFunctionnal"
          >
            <span class="functionnal">
              <b-icon
                pack="mdi"
                :icon="!props.open ? 'menu-up mdi-rotate-90' : 'menu-down'"
              ></b-icon>
              {{ $t("Cookies.Functionnal") }}
            </span>
            <span class="activated">{{ $t("Cookies.AlwaysOn") }}</span>
          </a>
          {{ $t("Cookies.AboutFunctionnal") }}
        </b-collapse>
        <div id="consent">
          <b-button @click="acceptCookies">
            Ok
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "CookieBanner",
  methods: {
    acceptCookies() {
      //ferme la popup
      this.$root.isCookieBannerDisplay = false;
      // TODO: create historique.js in src/store/modules
      // //definit le store infoWebStorage à true et fin des notifs webstorage
      // this.$store.dispatch("historique/setInfoWebStorage", true);
      // //definit historisation des recherches à true
      // this.$store.dispatch("historique/setHistory", true);
    }
  }
};
</script>
<style>
.cookie-banner {
  max-height: calc(100vh - 20px);
  width: 100vw;
  position: fixed;
  height: auto;
  left: 50%;
  top: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  grid-template-rows: minmax(0, 1fr);
  border-style: solid;
  border-radius: 10px;
  padding: 15px 20px;
  display: grid;
  grid-gap: 10px;
  background: #fff;
  z-index: 1900;
  background: var(--bgColorBiggy);
  text-align: center;
}

.cookie-banner > * {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.cookie-banner .modal-close {
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.2);
}

.cookie-banner h1 {
  font-size: 28px;
}

.cookies-about a {
  display: flex;
  justify-content: space-around;
  color: var(--fontColor);
}

.cookies-about a:hover {
  color: var(--fontColor) !important;
}

.cookies-about .functionnal {
  display: flex;
  padding-right: 20px;
}

.cookies-about .activated {
  color: #009f00;
}

#consent {
  padding: 15px;
}

#consent button {
  background-color: #004d9b;
  color: #fefefe;
}

@media (max-width: 500px) {
  .cookie-banner h1 {
    font-size: 21px;
  }
}
</style>
