var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-field',{staticClass:"large-label general-info",attrs:{"label":_vm.propOrder.customerOrderId
      ? _vm.$t('order') + ' ' + _vm.propOrder.customerOrderId
      : _vm.$t('yourOrder'),"label-position":"on-border","custom-class":"transparent-label"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns mt-1"},[_c('div',{staticClass:"column "},[_c('b-field',{attrs:{"label":_vm.$t('customsFlag'),"label-position":"on-border"}},[_c('b-select',{attrs:{"disabled":_vm.isEditingOrder},on:{"input":function($event){return _vm.confirmChangeCustomsFlag()}},model:{value:(_vm.customsFlag),callback:function ($$v) {_vm.customsFlag=$$v},expression:"customsFlag"}},[_c('option',{attrs:{"value":"import"}},[_vm._v("Import")]),_c('option',{attrs:{"value":"export"}},[_vm._v("Export")])])],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('clientReference'),"label-position":"on-border"}},[_c('b-input',{attrs:{"disabled":_vm.isEditingOrder},on:{"blur":function($event){return _vm.$emit(
                'set-order-information',
                'clientReference',
                _vm.clientReference
              )}},model:{value:(_vm.clientReference),callback:function ($$v) {_vm.clientReference=$$v},expression:"clientReference"}})],1)],1),_c('div',{staticClass:"column "},[_c('InputWithValidation',{attrs:{"rules":"max:252","label":_vm.$t('transportOrderNumber'),"is-required":"","disabled":_vm.isEditingOrder},on:{"blur":function($event){return _vm.$emit(
              'set-order-information',
              'transportOrderNumber',
              _vm.transportOrderNumber
            )}},model:{value:(_vm.transportOrderNumber),callback:function ($$v) {_vm.transportOrderNumber=$$v},expression:"transportOrderNumber"}})],1),(_vm.isEditingOrder)?_c('div',{staticClass:"column"},[_c('Autocomplete',{attrs:{"disabled":"","value":_vm.contractor.code,"label":_vm.$t('contractorCode'),"search-property":'code',"is-description-displayed":false,"label-position":'on-border',"description-property":'name',"fetch-async":_vm.getActorAsync,"is-autocomplete-field-expanded":true}})],1):_vm._e(),(_vm.isEditingOrder)?_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('contractorName'),"label-position":"on-border"}},[_c('b-input',{attrs:{"disabled":"","value":_vm.contractorName}})],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditingOrder),expression:"isEditingOrder"}],staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('creationDate'),"label-position":"on-border"}},[_c('b-datetimepicker',{attrs:{"disabled":""},model:{value:(_vm.creationDate),callback:function ($$v) {_vm.creationDate=$$v},expression:"creationDate"}})],1)],1)]),_c('CarrierInfo',{attrs:{"is-editing-order":_vm.isEditingOrder,"value":_vm.carrier},on:{"set-order-information":_vm.onCarrierInformationChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }