import number from "../mixins/number";

const customDouble = function(value) {
  return number.methods.isFloatString(value);
};

const custom_double = {
  validate: customDouble
};

const customMinValueDoubleString = function(value, { min }) {
  if (!number.methods.isFloatString(value)) return false;

  let parsedValue = number.methods.tryParseFloat(value);
  return parsedValue >= min;
};

const customMinValueDoubleStringParams = [
  {
    name: "min",
    cast(value) {
      return Number(value);
    }
  }
];

const custom_min_value = {
  validate: customMinValueDoubleString,
  params: customMinValueDoubleStringParams
};

const min_previous_event_date = {
  validate: (startDate, { previousEventDate }) => {
    if (startDate && !isNaN(previousEventDate)) {
      return startDate >= previousEventDate;
    }
    return true;
  },
  params: [
    {
      name: "previousEventDate",
      cast(value) {
        return Number(value);
      }
    }
  ]
};

const dateFromToday = value => {
  return {
    valid: value >= new Date()
  };
};

const date_from_today = {
  validate: dateFromToday
};

const validateEmail = function(value) {
  var re = /^[a-zA-Z0-9]+([-_+.]\w+)*@(\w+(-?\w+)*\.)+[a-zA-Z]{2,}$/;
  return re.test(String(value));
};

const email = {
  validate: validateEmail
};

export {
  custom_double,
  custom_min_value,
  min_previous_event_date,
  date_from_today,
  email
};
