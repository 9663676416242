<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <section>
    <b-breadcrumb align="is-left">
      <b-breadcrumb-item
        tag="router-link"
        to="/transcodifications"
        :active="endpoint ? false : true"
      >
        Transcodifications
      </b-breadcrumb-item>

      <b-breadcrumb-item
        v-if="endpoint"
        tag="router-link"
        :to="'/transcodifications/' + endpoint"
        active
      >
        <span v-if="endpoint == 'container-types'">{{
          $t("containerTypes")
        }}</span>

        <span v-else>{{ $t(endpoint) }}</span>
      </b-breadcrumb-item>
    </b-breadcrumb>
  </section>
</template>

<script>
export default {
  name: "BreadCrumb",
  computed: {
    endpoint() {
      return new URL(window.location.href).pathname.split("/")[2];
    }
  }
};
</script>
