var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',[_c('ValidationProvider',{class:_vm.validationClass,attrs:{"rules":_vm.validationRules,"name":_vm.label,"tag":_vm.validationTag,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{ref:"autocomplete",attrs:{"expanded":"","label-position":_vm.labelPosition},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-6",class:{
            'required-field': _vm.isRequired,
            'non-required-field': !_vm.isRequired
          },attrs:{"id":"autocomplete-label"}},[_vm._v(" "+_vm._s(_vm.label)+" ")])]},proxy:true},(!_vm.hideAllErrors)?{key:"message",fn:function(){return [_c('p',{staticClass:"has-text-danger item-not-found-error"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]),(!_vm.errorMsg)?_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('div',{staticClass:"relative-input"},[_c('b-autocomplete',{ref:"b-autocomplete",class:{
            'autocomplete-input': !_vm.isAutocompleteFieldExpanded,
            'is-uppercase': _vm.uppercaseInput
          },attrs:{"placeholder":_vm.placeholder,"data":_vm.suggestions,"field":_vm.searchProperty,"loading":_vm.isLoading,"icon-right-clickable":!_vm.disabled,"icon-right":_vm.inputValue && !_vm.disabled ? 'close-circle' : null,"icon-pack":"fas","disabled":_vm.disabled,"append-to-body":""},on:{"focus":function($event){_vm.isFocusing = true},"typing":_vm.getSuggestions,"select":_vm.onSelectSuggestion,"blur":_vm.onBlurInputField,"input":_vm.inputChanged,"icon-right-click":_vm.handleClear},nativeOn:{"mouseenter":function($event){return _vm.handleHover.apply(null, arguments)},"mouseleave":function($event){_vm.isBlurPrevented = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.isBlurPrevented = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var option = ref.option;
return [_c('div',{on:{"mousedown":function($event){_vm.isBlurPrevented = true}}},[_vm._v(" "+_vm._s(option[_vm.searchProperty])+" "),(option[_vm.descriptionProperty])?_c('span',[_vm._v(" "+_vm._s((" - " + (option[_vm.descriptionProperty])))+" ")]):_vm._e()])]}}],null,true),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBlurPrevented && _vm.inputValue && _vm.disabled && _vm.isOverflow),expression:"isBlurPrevented && inputValue && disabled && isOverflow"}],staticClass:"tooltip"},[_vm._v(" "+_vm._s(_vm.inputValue)+" ")])],1),(_vm.isDescriptionDisplayed)?_c('div',{staticClass:"relative-input"},[_c('b-input',{ref:"description",staticClass:"ml-1 description",attrs:{"disabled":"","expanded":"","value":_vm.description},nativeOn:{"mouseenter":function($event){return _vm.handleHoverDescription.apply(null, arguments)},"mouseleave":function($event){_vm.isBlurPreventedDescription = false}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.isBlurPreventedDescription && _vm.description && _vm.isDescriptionOverflow
          ),expression:"\n            isBlurPreventedDescription && description && isDescriptionOverflow\n          "}],staticClass:"tooltip"},[_vm._v(" "+_vm._s(_vm.description)+" ")])],1):_vm._e()])]}}])}),(_vm.errorMsg)?_c('ValidationProvider',{attrs:{"rules":"required","name":("autofield-" + _vm.label + "-error")}},[_c('b-input',{attrs:{"type":"hidden"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }