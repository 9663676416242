<i18n src="@/i18n/locales/results-logging.en.json"></i18n>
<i18n src="@/i18n/locales/results-logging.fr.json"></i18n>

<template>
  <div>
    <section class="section is-flex is-justify-content-center">
      <!-- Master order identifier -->
      <b-field
        :label="$t('orderId')"
        label-position="on-border"
        style="width: 40%"
        class="pr-5"
      >
        <b-input
          id="order-identifier"
          type="text"
          v-model="orderId"
          @keyup.native.enter="handleEnter"
        />
      </b-field>
      <!-- Result type -->
      <b-field
        :label="$t('type')"
        label-position="on-border"
        style="width: 20%"
        class="pr-5"
      >
        <b-select :placeholder="$t('selectResultType')" v-model="selectedType">
          <option :value="resultType.association">{{
            $t("associationResult")
          }}</option>
          <option :value="resultType.ici">{{ $t("iciResult") }}</option>
        </b-select></b-field
      >
      <!-- Search button -->
      <b-button
        type="is-primary"
        :disabled="!isSubmittable"
        :loading="isLoading"
        @click="handleSearchClick"
      >
        {{ $t("search") }}
      </b-button>
    </section>

    <div :hidden="isTableHidden" class="section">
      <b-table
        id="search-result-table"
        :data="data"
        :columns="columns"
        :loading="isTableLoading"
        hoverable
        striped
        mobile-cards
      >
        <template #empty>
          <div class="has-text-centered">{{ $t("resultTable.noRecord") }}</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import resultsLoggingApi from "@/repository/customer-order/resultsLogging.api";

export default {
  name: "ResultsLogging",

  data() {
    return {
      orderId: null,
      selectedType: null,
      resultType: {
        association: "association-results",
        ici: "ici-results"
      },
      isLoading: false,
      isTableHidden: true,
      isTableLoading: false,
      data: []
    };
  },

  computed: {
    isSubmittable() {
      return this.orderId != null && this.selectedType != null;
    },

    columns() {
      return [
        {
          field: "customerOrderId",
          label: this.$i18n.t("resultTable.id")
        },
        {
          field: "date",
          label: this.$i18n.t("resultTable.date")
        },
        {
          field: "status",
          label: this.$i18n.t("resultTable.status")
        },
        {
          field: "reason",
          label: this.$i18n.t("resultTable.reason")
        }
      ];
    }
  },

  methods: {
    handleEnter: function() {
      if (this.isSubmittable) {
        this.handleSearchClick();
      }
    },

    async handleSearchClick() {
      this.isLoading = true;
      this.isTableHidden = false;
      this.isTableLoading = true;

      await resultsLoggingApi
        .getResultsById(this.selectedType, this.orderId)
        .then(response => {
          this.data = response.data;
          if (this.selectedType == this.resultType.association) {
            this.data = this.data.map(item => {
              return {
                ...item,
                customerOrderId: item.orderId,
                status:
                  item.result == "InProgress"
                    ? this.$t(item.result)
                    : item.result,
                reason: item.reason ?? ""
              };
            });
          } else
            this.data = this.data.map(item => {
              return {
                ...item,
                status:
                  item.iciStatus == "InProgress"
                    ? this.$t(item.iciStatus)
                    : item.iciStatus
              };
            });
        })
        .catch(e => {
          this.data = [];
          throw e;
        })
        .finally(() => {
          this.isLoading = false;
          this.isTableLoading = false;
        });
    }
  }
};
</script>
