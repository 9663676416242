<i18n src="@/i18n/locales/side-bar.en.json"></i18n>
<i18n src="@/i18n/locales/side-bar.fr.json"></i18n>

<template>
  <div>
    <h2 class="is-size-6" data-testid="browsers-title">
      {{ $t("browsersTitle") }}
    </h2>
    <div class="iconBrowsers mt-2">
      <b-icon icon="google-chrome" pack="mdi" class="has-text-primary-light" />
      <b-icon icon="firefox" pack="mdi" class="ml-2 has-text-primary-light" />
      <b-icon
        icon="microsoft-edge"
        pack="mdi"
        class="ml-2 has-text-primary-light"
      />
      <b-icon
        icon="apple-safari"
        pack="mdi"
        class="ml-2 has-text-primary-light"
      />
    </div>

    <div class="description">
      <br />
      <span class="has-text-weight-medium" data-testid="supported-pc-mac">{{
        $t("supportedPcMac")
      }}</span>
      <br />
      PC : Microsoft Windows 7 / 8.1 / 10+
      <br />
      {{ $t("browsersTitle") }} :
      <span>Chrome 80+ / Firefox 74+ / Edge 18+</span>
      <br />
      Mac : Safari 14+

      <br />
      <br />
      <span class="has-text-weight-medium">{{ $t("supportedMobile") }}</span>

      <br />
      {{ $t("googleSystem") }} : Android 8+ Google Chrome 80+
      <br />
      {{ $t("appleSystem") }} : iOS 12+ Safari 14+ Google Chrome 80+
    </div>

    <br />

    <h2 class="is-size-6">{{ $t("devicesTitle") }}</h2>
    <div class="compatDevices mt-2">
      <b-icon icon="monitor" pack="mdi" class="has-text-primary-light" />
      <b-icon icon="laptop" pack="mdi" class="has-text-primary-light ml-2" />
      <b-icon icon="tablet" pack="mdi" class="has-text-primary-light ml-2" />
      <b-icon icon="cellphone" pack="mdi" class="has-text-primary-light ml-2" />
    </div>

    <p class="description mt-2">
      {{ $t("supportedDevices") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AppCompatibility"
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 0.85rem;
}
</style>
