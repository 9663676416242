<template>
  <div :class="customStyle" class="is-flex is-align-items-center p-0 m-0">
    <label
      class="label"
      :class="{
        'required-field': isRequired,
        'non-required-field': !isRequired
      }"
    >
      {{ label }}
    </label>

    <div class="ml-1" v-if="tooltip">
      <b-tooltip
        :label="tooltip"
        :type="tooltipType"
        :position="tooltipPosition"
        multilined
      >
        <b-icon
          pack="fas"
          icon="question-circle"
          size="is-small"
          type="is-primary"
        >
        </b-icon>
      </b-tooltip>
    </div>
    <div class="is-divider is-primary my-1" v-if="hasDivider" />
  </div>
</template>

<script>
export default {
  name: "LabelWithTooltip",
  props: {
    isRequired: {
      default: false
    },
    label: {
      required: true
    },
    tooltip: {
      default: null
    },
    tooltipPosition: {
      default: "is-top"
    },
    tooltipType: {
      default: "is-primary"
    },
    customStyle: {
      default: "pr-2",
      type: String
    },
    hasDivider: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
