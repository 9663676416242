<i18n src="@/i18n/locales/side-bar.en.json"></i18n>
<i18n src="@/i18n/locales/side-bar.fr.json"></i18n>

<template>
  <div class="is-size-6 borderLeft">
    <h2 class="has-text-weight-light pl-2 pb-2">
      {{ $t("admin") }}
    </h2>

    <router-link to="/transcodifications">
      <b-icon
        pack="mdi"
        icon="account-box-multiple"
        size="is-small"
        class="mr-1"
      />
      {{ $t("supporterFeatures.transcodifications") }}
    </router-link>

    <router-link to="/results-logging">
      <b-icon pack="mdi" icon="table-account" size="is-small" class="mr-1" />
      {{ $t("supporterFeatures.resultsLogging") }}
    </router-link>

    <router-link to="/api-keys">
      <b-icon pack="mdi" icon="key-variant" size="is-small" class="mr-1" />
      {{ $t("supporterFeatures.apiKeys") }}
    </router-link>

    <router-link to="/organizations">
      <b-icon pack="mdi" icon="account-group" size="is-small" class="mr-1" />
      {{ $t("supporterFeatures.organizations") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SupporterFeatures"
};
</script>

<style scoped>
.borderLeft {
  border-left: 2px hsl(0deg, 0%, 86%) solid;
}
</style>
