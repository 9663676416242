<i18n src="@/i18n/locales/api-key.en.json"></i18n>
<i18n src="@/i18n/locales/api-key.fr.json"></i18n>

<template>
  <div class="mt-4 box">
    <div class="is-flex is-justify-content-end mb-3">
      <b-button
        icon-pack="fas"
        icon-left="plus"
        type="is-primary"
        size="is-small"
        @click="handleCreate()"
      />
    </div>

    <ApiKeyModalAdd v-model="isOpenApiKeyModalAdd" @add-api-key="getData()" />

    <b-table
      :data="apiKeys"
      :loading="isLoading"
      hoverable
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="actorCode"
        width="24%"
        :label="$t('actorCode')"
        v-slot="props"
      >
        <strong class="has-text-primary"> {{ props.row.actorCode }}</strong>
      </b-table-column>

      <b-table-column
        field="actorName"
        width="24%"
        :label="$t('actorName')"
        v-slot="props"
      >
        <strong class="has-text-primary"> {{ props.row.actorName }}</strong>
      </b-table-column>

      <b-table-column
        field="authorizationKey"
        width="40%"
        :label="$t('authorizationKey')"
        v-slot="props"
      >
        <div
          class="is-flex is-align-items-center is-justify-content-space-between"
        >
          <div class="is-full-width">
            <b-field v-if="isEditMode(props.row.actorCode)">
              <b-input
                :value="inputValue(props.row.actorCode)"
                size="is-small"
                expanded
                @input="handleChangeInputValue($event, props.row.actorCode)"
              />
              <b-button
                class="is-size-7 ml-3 is-light"
                icon-pack="fas"
                icon-left="times"
                type="is-danger"
                @click="handleCancelEditMode(props.row.actorCode)"
              />
              <b-button
                class="is-size-7 ml-3 is-light"
                icon-pack="fas"
                icon-left="check"
                type="is-success"
                @click="handleEdit(props.row.actorCode)"
              />
            </b-field>

            <div v-else>
              <div v-if="revealKeys.includes(props.row.actorCode)">
                {{ decodeKeyBase64(props.row.authorizationKey) }}
              </div>
              <div v-else>
                {{ secureKey(decodeKeyBase64(props.row.authorizationKey)) }}
              </div>
            </div>
          </div>
        </div>
      </b-table-column>

      <b-table-column v-slot="props">
        <div class="is-flex is-align-items-center">
          <b-button
            class="is-size-7 mr-3 is-primary is-light"
            id="viewBtn"
            icon-pack="mdi"
            :icon-left="
              revealKeys.includes(props.row.actorCode)
                ? 'magnify-close'
                : 'magnify'
            "
            @click="handleReveal(props.row.actorCode)"
            :disabled="isEditMode(props.row.actorCode)"
          />

          <b-button
            type="is-warning is-light"
            style="color: orange"
            class="is-size-7 mr-3"
            id="editButton"
            icon-pack="mdi"
            icon-left="circle-edit-outline"
            @click="
              handleChangeToEditMode(
                props.row.actorCode,
                decodeKeyBase64(props.row.authorizationKey)
              )
            "
            :disabled="isEditMode(props.row.actorCode)"
          />

          <b-button
            type="is-danger is-light"
            class="is-size-7"
            id="deleteButton"
            @click="handleDelete(props.row.actorCode, props.row.actorName)"
            icon-pack="mdi"
            icon-left="delete"
          />
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">{{ $t("noRecord") }}</div>
      </template>
    </b-table>

    <PaginationBar
      :total="totalItems"
      :limit="limit"
      v-bind:page.sync="page"
      @update:page="getData"
    />
  </div>
</template>
<script>
import apiKeysApi from "@/repository/customer-order/apiKeys.api";
import PaginationBar from "@/components/common/PaginationBar.vue";
import ApiKeyModalAdd from "./ApiKeyModalAdd.vue";
import actorReferential from "@/mixins/actor";

export default {
  name: "ApiKeyTable",

  data() {
    return {
      isLoading: false,
      apiKeys: [],
      totalItems: 0,
      page: 1,
      limit: 6,
      revealKeys: [],
      editItems: [],
      isOpenApiKeyModalAdd: false
    };
  },

  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    }
  },

  mixins: [actorReferential],

  mounted() {
    this.getData();
  },

  methods: {
    // Function to get data from api
    async getData() {
      let paginationParams = {
        limit: this.limit,
        offset: this.offset
      };
      this.isLoading = true;
      await apiKeysApi
        .getAllApiKeys(paginationParams)
        .then(response => {
          this.totalItems = response.data.total;
          return response;
        })
        .then(async response => {
          this.apiKeys = response.data.items;
          for (var apiKey of this.apiKeys) {
            apiKey.actorName = await this.getActorName(apiKey.actorCode);
          }
        })
        .finally(() => (this.isLoading = false));
    },

    // Function to decode with base 64 format
    decodeKeyBase64(key) {
      return atob(key).replace(":gedmouv", "");
    },

    // Function to show input like password or text plain
    secureKey(key) {
      return Array(key.length + 1).join("•");
    },

    // Handle open modal
    handleCreate() {
      this.isOpenApiKeyModalAdd = true;
    },

    // Return input value
    inputValue(actorCode) {
      return this.editItems.find(item => item.actorCode == actorCode)?.value;
    },

    // Function to handle reveal key or not
    handleReveal(actorCode) {
      let findIndex = this.revealKeys.findIndex(item => item == actorCode);
      if (findIndex != -1) this.revealKeys.splice(findIndex, 1);
      else this.revealKeys.push(actorCode);
    },

    // Function to edit item or not
    handleChangeToEditMode(actorCode, editValue) {
      if (!this.isEditMode(actorCode))
        this.editItems.push({
          actorCode,
          value: editValue
        });
    },

    // Handle cancel edit mode
    handleCancelEditMode(actorCode) {
      if (this.isEditMode(actorCode))
        this.editItems.splice(
          this.editItems.findIndex(item => item.actorCode == actorCode),
          1
        );
    },

    // Show edit mode or not
    isEditMode(actorCode) {
      return (
        this.editItems.findIndex(item => item.actorCode == actorCode) != -1
      );
    },

    // Handle change input value
    handleChangeInputValue(e, actorCode) {
      let inputValue = this.editItems.find(item => item.actorCode == actorCode);
      inputValue.value = e;
    },

    // Handle edit
    async handleEdit(actorCode) {
      const authorizationKey = this.editItems.find(
        item => item.actorCode == actorCode
      )?.value;
      this.isLoading = true;
      await apiKeysApi
        .editApiKey(actorCode, { authorizationKey })
        .then(async () => {
          this.handleCancelEditMode(actorCode);
          this.isLoading = false;
          await this.getData();
        })
        .catch(() => (this.isLoading = false));
    },

    //Handle delete
    async handleDelete(actorCode, actorName) {
      this.$buefy.dialog.confirm({
        title: this.$t("confirmTitle"),
        message: this.$t("deleteApiKey", { actorCode, actorName }),
        confirmText: this.$t("delete"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          apiKeysApi
            .deleteApiKey(actorCode)
            .then(async () => {
              this.isLoading = false;
              await this.getData();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    }
  },

  components: { ApiKeyModalAdd, PaginationBar }
};
</script>
<style scoped>
.is-full-width {
  width: 100%;
}
</style>
