<i18n src="@/i18n/locales/legal.fr.json"></i18n>
<i18n src="@/i18n/locales/legal.en.json"></i18n>

<template>
  <div>
    <the-bandeau />
    <div class="section legal-notice">
      <h2 class="subtitle">
        {{ $t("About") }}
        {{ $t("SOGET") }}
      </h2>
      <section class="box">
        {{ $t("PrivateLimitedCompany") }}
        {{ $t("ShareCapitalOf") }}
        <soget-capital />
        <br />
        <soget-address />
        <br />
        <label>{{ $t("Coords.Tel") }}</label>
        <soget-num-tel />
        <br />
        <label>{{ $t("Coords.Mail") }}</label>
        <soget-mail />
        <br />
        <label>{{ $t("Coords.Siren") }}</label>
        <soget-num-siren />
        <br />
        <label>{{ $t("Coords.TvaIntra") }}</label>
        <soget-num-prefixe-tva-intra />
        <soget-num-siren />
      </section>

      <h2 class="subtitle">
        {{ $t("DPO") }}
      </h2>
      <section class="box">
        <soget-d-p-o />
      </section>

      <h2 class="subtitle">
        {{ $t("About") }}
        {{ appName }}
      </h2>
      <div v-if="this.isFrench" key="legalFr">
        <section class="box">
          Les informations figurant sur ce site et accessibles par ce site sont
          le reflet des données présentes dans le produit
          <strong> {{ appName }}</strong> de <b>{{ $t("SOGET") }}</b> en tant
          que service aux clients de <b>{{ $t("SOGET") }}</b> et ne peuvent être
          utilisées que dans un but d'information.
          <br />
          Les informations présentes sur le site ne peuvent en aucun cas être
          utilisées en cas de litige ou légalement devant des tribunaux.
          <br />
          <strong> {{ appName }}</strong> est considéré comme source fiable.
          Toutefois, <b>{{ $t("SOGET") }}</b> ne garantit pas l'exactitude ou le
          caractère exhaustif des informations. Les informations affichées sur
          ce site peuvent contenir des inexactitudes ou des erreurs. Les
          informations peuvent changer ou être mises à jour sans préavis.
          <b>{{ $t("SOGET") }}</b> peut également apporter des améliorations
          et/ou des changements au service, à tout moment et sans préavis.
          <b>{{ $t("SOGET") }}</b> ne pourra en aucun cas être tenue responsable
          de tout dommage de quelque nature que ce soit résultant de
          l’interprétation ou de l’utilisation des informations et documents
          disponibles sur ce site. L’utilisation des informations disponibles
          sur ce site se fait sous l’entière et seule responsabilité de
          l’utilisateur, qui assume la totalité des conséquences pouvant en
          découler, sans que <b>{{ $t("SOGET") }}</b> ne puisse être recherchée
          à ce titre, et sans recours contre cette dernière.
          <br />
          <strong> {{ appName }}</strong> est conçu et développé par son
          propriétaire <b>{{ $t("SOGET") }}</b
          >.
          <br />
          L’interconnexion entre <strong> {{ appName }}</strong> et des
          applications autres font l’objet d’autorisations contractuelles
          préalables entre <b>{{ $t("SOGET") }}</b> et les propriétaires des
          dites applications.
        </section>

        <h2 class="subtitle">Conception / Réalisation / Hébergement</h2>
        <section class="box">
          Hébergement Azure Cloud : Ce site est hébergé sur l’offre Cloud de
          Microsoft (Azure). Il est géographiquement localisé dans le centre
          d’hébergement de Microsoft en France (région Centre).
          <br />
          Pour plus d’informations, veuillez vous référer aux liens suivants :
          <br />
          <a
            :href="getUrlAzurePrivacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            La confidentialité chez Microsoft
          </a>
          <br />
          <a :href="getUrlAzureLegal" target="_blank" rel="noreferrer nofollow">
            Mentions légales Microsoft Azure
          </a>
        </section>

        <h2 class="subtitle">Crédits (photos et images)</h2>
        <section class="box">
          Image de bandeau (source + crédit à l’artiste inscrits en bas de
          chaque page)
          <br />
          Icônes MDI de Google
          <br />
          Icônes métier par {{ $t("SOGET") }}
        </section>

        <h2 class="subtitle">Cookies / Traceurs</h2>
        <section class="box">
          <b>{{ $t("SOGET") }}</b> utilise dans son application
          <strong> {{ appName }}</strong> des traceurs exemptés de consentement
          selon les termes de l’article 82 de la loi « Informatique et Libertés
          » du 06/01/1978. En effet, les traceurs utilisés sont strictement
          nécessaires à la fourniture d’un service de communication en ligne
          expressément demandé par l’utilisateur. Rendre accessible les réglages
          du navigateur (traceurs/cookies) aux utilisateurs aurait des effets
          potentiellement négatifs sur le fonctionnement du portail
          <strong> {{ appName }}</strong>
          tel qu’attendu par l’utilisateur.
          <br />
          Néanmoins, les préférences des utilisateurs sont stockées afin
          d’optimiser l’utilisation de
          <strong> {{ appName }}</strong> par l’utilisateur. Ce dernier est
          prévenu du fonctionnement de ce traceur lors de la connexion et a
          moyen de les désactiver via les préférences situées dans le menu
          latéral.
          <br />
          <cookie-button />
        </section>
      </div>
      <div v-else key="legalEn">
        <section class="box">
          The purpose of this site is to provide non contractual information
          reflecting the data present in product
          <strong> {{ appName }}</strong> of <b>{{ $t("SOGET") }}</b> as a
          service to <b>{{ $t("SOGET") }}</b>
          customers and can only be used for information purposes.
          <br />
          The information on the site cannot under any circumstances be used in
          the event of a dispute or legally before the courts.
          <br />
          <strong> {{ appName }}</strong> is considered a reliable source.
          However, <b>{{ $t("SOGET") }}</b> do not guarantee the accuracy or
          completeness of the information. The information displayed on this
          site may contain inaccuracies or errors. Information may change or be
          updated without notice. <b>{{ $t("SOGET") }}</b> may also make
          improvements and/or changes to the service, at any time and without
          notice. <b>{{ $t("SOGET") }}</b> can under no circumstances be held
          liable for any damage of any kind whatsoever resulting from the
          interpretation or use of the information and documents available on
          this site. The use of this information available on this site is done
          under the full and sole responsibility of the user, who assumes all
          the consequences that may result from it.
          <br />
          <strong> {{ appName }}</strong> is designed and developed by its owner
          <b>{{ $t("SOGET") }}</b
          >.
          <br />
          The interconnection between
          <strong> {{ appName }}</strong> and other applications are subject to
          prior contractual authorizations between <b>{{ $t("SOGET") }}</b> and
          the owners of said applications.
        </section>

        <h2 class="subtitle">Design / Production / Hosting</h2>
        <section class="box">
          Azure Cloud hosting: This site is hosted on the Microsoft Cloud offer
          (Azure). It is geographically located in the hosting center of
          Microsoft in France (Centre region).
          <br />
          For more information, please login at the following links:
          <br />
          <a
            :href="getUrlAzurePrivacy"
            target="_blank"
            rel="noreferrer nofollow"
          >
            Privacy at Microsoft
          </a>
          <br />
          <a :href="getUrlAzureLegal" target="_blank" rel="noreferrer nofollow">
            Microsoft Azure Legal Notices
          </a>
        </section>

        <h2 class="subtitle">Credits (photos and images)</h2>
        <section class="box">
          Banner image (source + credit to the artist listed at the bottom of
          each page)
          <br />
          MDI icons from Google
          <br />
          Business icons by {{ $t("SOGET") }}
        </section>

        <h2 class="subtitle">Cookies / Trackers</h2>
        <section class="box">
          <b>{{ $t("SOGET") }}</b> uses in its
          <strong> {{ appName }}</strong> application tracers exempt from
          consent under the terms of article 82 of the "Informatique et
          Libertés" law of 06/01/1978. Indeed, the tracers used are strictly
          necessary for the provision of an online communication service
          expressly requested by the user. Making browser settings (trackers /
          cookies) accessible to users would have potentially negative effects
          on the functioning of the <strong> {{ appName }}</strong> portal as
          expected by the user.
          <br />
          However, user preferences are stored in order to optimize the user's
          use of <strong> {{ appName }}</strong
          >. The latter is warned of the operation of this plotter during
          connection and has a means of deactivating them via the preferences
          located in the side menu.
          <br />
          <cookie-button />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import SogetMail from "@/components/legal-notice/SogetMail";
import SogetAddress from "@/components/legal-notice/SogetAddress";
import SogetNumTel from "@/components/legal-notice/SogetNumTel";
import SogetNumPrefixeTvaIntra from "@/components/legal-notice/SogetNumPrefixeTvaIntra";
import SogetNumSiren from "@/components/legal-notice/SogetNumSiren";
import SogetDPO from "@/components/legal-notice/SogetDPO";
import SogetCapital from "@/components/legal-notice/SogetCapital";
import CookieButton from "@/components/common/CookieButton";
import TheBandeau from "@/components/common/TheBandeau.vue";

export default {
  name: "LegalNotice",
  data() {
    return {
      appName: process.env.APP_NAME
    };
  },
  components: {
    SogetMail,
    SogetAddress,
    SogetNumTel,
    SogetNumPrefixeTvaIntra,
    SogetNumSiren,
    SogetDPO,
    SogetCapital,
    CookieButton,
    TheBandeau
  },
  computed: {
    isFrench() {
      return this.$i18n.locale == "fr";
    },
    getUrlAzurePrivacy() {
      return this.isFrench
        ? window._config.external_app.CONFIG_AZURE_CENTER_URL_FR
        : window._config.external_app.CONFIG_AZURE_CENTER_URL_EN;
    },
    getUrlAzureLegal() {
      return this.isFrench
        ? window._config.external_app.CONFIG_AZURE_LEGAL_URL_FR
        : window._config.external_app.CONFIG_AZURE_LEGAL_URL_EN;
    }
  }
};
</script>

<style>
.legal-notice {
  text-align: left;
}

.legal-notice .box {
  background-color: var(--footerBackgroundColor);
  box-shadow: none !important;
}

@media (min-width: 1000px) {
  .legal-notice {
    margin-left: auto;
    margin-right: auto;
    width: 84%;
    padding-top: 0.5em !important;
  }
}
</style>
