<template>
  <section class="section is-flex is-flex-direction-column">
    <MyLocationsTable />
  </section>
</template>

<script>
import MyLocationsTable from "@/components/my-locations/MyLocationsTable";

export default {
  name: "MyLocations",

  components: {
    MyLocationsTable
  }
};
</script>
