<i18n src="@/i18n/locales/container-info.en.json"></i18n>
<i18n src="@/i18n/locales/container-info.fr.json"></i18n>

<template>
  <ValidationObserver ref="newContainersObserver">
    <b-tabs
      v-model="containersActiveTab"
      type="is-boxed"
      :animated="false"
      :key="containerTabsKey"
      class="new-containers"
    >
      <b-tab-item disabled>
        <template #header>
          <span class="has-text-weight-bold tab-header">
            {{ $t("containers") }}
          </span>
        </template>
      </b-tab-item>

      <b-tab-item v-for="(container, index) in containers" :key="index">
        <!-- Container information -->
        <template #header>
          <span>
            {{ container.reference ? container.reference : $t("container") }}
            <span @click="deleteContainer(index)" v-if="!isEditingOrder">
              <b-icon pack="fas" icon="times" size="is-small" />
            </span>
          </span>
        </template>

        <ContainerEditor
          :is-validate-containers="isValidateContainers"
          :prop-container="container"
          :container-index="index"
          :customs-flag="customsFlag"
          :prop-event-active-tab="listPropEventActiveTab[index]"
          :prop-goods-active-tab="listPropGoodsActiveTab[index]"
          @set-container-information="setContainerInformation"
          @set-container-having-errors="setContainerHavingErrors"
          @change-goods-active-tab="changeGoodsActiveTab"
          @change-event-active-tab="changeEventActiveTab"
        />
      </b-tab-item>

      <b-tab-item v-if="!isEditingOrder">
        <template #header>
          <span class="add-btn" @click="addContainer">
            <b-icon pack="fas" icon="plus" size="is-small" />
          </span>
        </template>
      </b-tab-item>
    </b-tabs>
  </ValidationObserver>
</template>

<script>
import Vue from "vue";

import ContainerEditor from "@/components/customer-order/container-info/ContainerEditor.vue";

export default {
  name: "NewContainers",

  components: {
    ContainerEditor
  },

  props: {
    // propContainers will have value when it's used for editing or prefilling order purpose
    propContainers: {
      type: Array
    },

    customsFlag: {
      type: String,
      default: "import",
      required: true
    },

    refreshUi: {
      type: Boolean,
      default: false,
      require: true
    },

    isValidateContainers: {
      type: Boolean,
      default: false,
      require: true
    }
  },

  data() {
    return {
      isEditingOrder: this.$route.params?.orderIdentifier !== undefined,

      containers: this.propContainers ? this.propContainers : [],

      containerTabsKey: 0,
      containersActiveTab: 1,

      listPropEventActiveTab: [],
      listPropGoodsActiveTab: [],

      firstContainerHavingErrors: null,
      revalidate: false
    };
  },

  methods: {
    setContainerInformation(payload) {
      let updatedContainer = this.containers[payload.id];

      if (
        payload.field === "isReefer" ||
        payload.field === "isOog" ||
        payload.field === "isHazardous"
      ) {
        let indicator = updatedContainer.indicator ?? {};
        indicator[payload.field] = payload.value;
        Vue.set(updatedContainer, "indicator", indicator);
        return;
      }

      if (payload.field === "setPoint") {
        let reefer = updatedContainer.reefer ?? {};
        reefer[payload.field] = payload.value;
        Vue.set(updatedContainer, "reefer", reefer);
        return;
      }

      if (
        payload.field === "length" ||
        payload.field === "width" ||
        payload.field === "height"
      ) {
        let oog = updatedContainer.oog ?? {};
        oog[payload.field] = payload.value;
        Vue.set(updatedContainer, "oog", oog);
        return;
      }

      Vue.set(updatedContainer, payload.field, payload.value);
      this.$emit("set-order-information", "containers", this.containers);
    },

    addContainer() {
      this.containers.push({});
      this.activeLastTab();
    },

    deleteContainer(index) {
      this.containers.splice(index, 1);
      this.activeLastTab();
    },

    activeLastTab() {
      this.containersActiveTab = this.propContainers.length;
      this.containerTabsKey++;
    },

    // the validate() function of ValidationObsever will check error in parent (like ContainerInfo.vue) to child (like EventInfo.vue) order,
    // so it prefers to return ContainerInfo's own errors first, get that ContainerInfo's index and show its tab
    setContainerHavingErrors(index) {
      if (this.firstContainerHavingErrors !== null) {
        // show the first container (which has the smallest index) that has its own errors
        // (if there aren't any ContainerInfo'own errors, show the first tab that has errors in EventInfo/ GoodsInfo child component)
        if (index < this.firstContainerHavingErrors)
          this.firstContainerHavingErrors = index;
      } else this.firstContainerHavingErrors = index;

      // the first tab is already used to display 'Containers' label, so other tab for containers must be the container index plus 1
      this.containersActiveTab = this.firstContainerHavingErrors + 1;

      // change component's key to manually re-render component,
      // this will also reset firstEventHavingErrors and firstGoodsHavingErrors of child component ContainerInfo to null
      this.containerTabsKey++;

      // re-validate to re-display the errors messages after we re-render
      this.revalidate = true;

      // set isValidateContainers to false so we can run a new 'validate and display tab' process again when user clicks on 'Validate' button
      this.$emit("change-is-validate-containers");
    },

    changeEventActiveTab(activeTab, containerIndex) {
      // save different event active tab for different containers
      this.listPropEventActiveTab[containerIndex] = activeTab;
    },

    changeGoodsActiveTab(activeTab, containerIndex) {
      // save different goods active tab for different containers
      this.listPropGoodsActiveTab[containerIndex] = activeTab;
    }
  },

  mounted() {
    if (!this.isEditingOrder) {
      this.addContainer();
    }
    this.activeLastTab();
  },

  updated() {
    if (this.revalidate) {
      // re-validate to re-display the errors messages after we re-render the component
      this.$refs.newContainersObserver.validate();
      this.firstContainerHavingErrors = null;
      this.revalidate = false;
    }
  },

  watch: {
    refreshUi(newVal) {
      if (newVal) {
        this.activeLastTab();
        this.$emit("refresh-complete");
      }
    }
  }
};
</script>

<style>
.b-tabs .tabs li.is-disabled {
  opacity: 1 !important;
}

.b-tabs .tab-content {
  border-right: 1px solid #d3d0d0;
  border-bottom: 1px solid #d3d0d0;
  border-left: 1px solid #d3d0d0;
  border-radius: 4px;
}
</style>
