import { extend } from "vee-validate";
import {
  digits,
  integer,
  is_not,
  length,
  max,
  regex,
  required
} from "vee-validate/dist/rules.umd.js";
import { localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";
import {
  email,
  date_from_today,
  custom_min_value,
  min_previous_event_date,
  custom_double
} from "@/validation/customRules";
import useI18n from "@/i18n/i18n.js";

const i18n = useI18n();

// EXTEND BUILT-IN VALIDATION RULES
extend("digits", digits);
extend("integer", integer);
extend("is_not", is_not);
extend("length", length);
extend("max", max);
extend("regex", regex);
extend("required", {
  ...required,
  message: (_, values) =>
    toSentenceCase(i18n.t("validationRules.requiredField", values))
});

// EXTEND CUSTOM VALIDATION RULES
// The localized message for the rule "email" is reused from the built-in rule.
extend("double", custom_double);

extend("date_from_today", {
  ...date_from_today,
  message: (_, values) =>
    toSentenceCase(i18n.t("validationRules.dateFromToday", values))
});

extend("date_eta_etd", {
  ...date_from_today,
  message: (_, values) => i18n.t("validationRules.dateEtaEtd", values)
});

extend("email", {
  ...email,
  message: () => toSentenceCase(i18n.t("validationRules.invalidEmail"))
});

extend("min_value", custom_min_value);

extend("min_previous_event_date", {
  ...min_previous_event_date,
  message: () => i18n.t("validationRules.minPreviousEventDate")
});

extend("end_date_greater_than_start_date", {
  validate: (endDate, { target }) => {
    return !endDate || (target && endDate > target);
  },
  params: ["target"],
  message: () => i18n.t("validationRules.endDateMustAfterStartDate")
});

localize({
  en,
  fr
});

function toSentenceCase(sentence) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}
