import ReferentialApi from "./referentialHTTPClient";

/**
 * Actors end point.
 */
const END_POINT = "/actors";

export default {
  /**
   * Search actor informations.
   * @param {*} pathParams - path parameters.
   * @param {*} queryParams - query parameters.
   */
  getAll(pathParams, queryParams) {
    return ReferentialApi.httpClient.get(`${END_POINT}/search`, {
      params: queryParams
    });
  },

  /**
   * Get actor information.
   * @param {*} pathParams - path parameters.
   */
  get(pathParams) {
    return ReferentialApi.httpClient.get(`${END_POINT}/${pathParams?.code}`);
  }
};
