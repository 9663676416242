export default {
  data() {
    return {
      isExport: Boolean(localStorage.getItem("isExport") === "true")
    };
  },

  computed: {
    customsFlag() {
      return this.$root.isExport ? "export" : "import";
    }
  },

  watch: {
    isExport(newVal) {
      localStorage.setItem("isExport", newVal);
    }
  }
};
