<i18n src="@/i18n/locales/goods-info.en.json"></i18n>
<i18n src="@/i18n/locales/goods-info.fr.json"></i18n>

<template>
  <ValidationObserver ref="goodsObserver" :key="goodsIndex">
    <div class="columns">
      <!-- Goods type -->
      <div class="column is-2">
        <Autocomplete
          v-model="goodsType"
          :label="$t('type')"
          :search-property="'code'"
          :validation-rules="'length:2'"
          :max-length="2"
          :is-fixed-length="true"
          :is-description-displayed="true"
          :label-position="'on-border'"
          :description-property="'description'"
          :fetch-all-async="getAllPackagingCodesAsync"
          :fetch-async="getPackagingCodeAsync"
          :is-autocomplete-field-expanded="true"
          :disabled="isDisabled"
          @input="onGoodsInformationChange('type', goodsType)"
        />
      </div>

      <!-- Goods description -->
      <div class="column is-one-quarter">
        <ValidationProvider
          rules="max:264"
          v-slot="{ errors }"
          :name="$t('description')"
        >
          <b-field :label="$t('description')" label-position="on-border">
            <b-input
              v-model="description"
              :disabled="isDisabled"
              @blur="onGoodsInformationChange('description', description)"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Number of packages -->
      <div class="column">
        <ValidationProvider
          rules="integer|min_value:1"
          v-slot="{ errors }"
          :name="$t('quantity')"
        >
          <b-field :label="$t('quantity')" label-position="on-border">
            <b-input
              v-model.number="quantity"
              :disabled="isDisabled"
              @blur="onGoodsInformationChange('quantity', quantity)"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Goods gross weight -->
      <div class="column">
        <ValidationProvider
          rules="double|min_value:0"
          v-slot="{ errors }"
          :name="$t('weight')"
        >
          <b-field :label="$t('weight')" label-position="on-border">
            <b-input
              :value="convertStringWithLocaleDecimalSeparator(weight)"
              @input="val => (weight = trimLeadingZeros(val))"
              expanded
              :disabled="isDisabled"
              @blur="onGoodsInformationChange('weight', weight)"
            />
            <p class="control">
              <span class="button is-static">
                {{ $t("weightUnit") }}
              </span>
            </p>
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Goods volume -->
      <div class="column">
        <ValidationProvider
          rules="double|min_value:0"
          v-slot="{ errors }"
          :name="$t('volume')"
        >
          <b-field :label="$t('volume')" label-position="on-border">
            <b-input
              :value="convertStringWithLocaleDecimalSeparator(volume)"
              @input="val => (volume = trimLeadingZeros(val))"
              expanded
              :disabled="isDisabled"
              @blur="onGoodsInformationChange('volume', volume)"
            />
            <p class="control">
              <span class="button is-static">
                {{ $t("volumeUnit") }}
              </span>
            </p>
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <div
        class="is-narrow is-flex is-flex-direction-column is-align-items-center"
      >
        {{ $t("dangerous") }}
        <b-tooltip
          :label="$t('setContainerHazardous')"
          type="is-warning"
          :active="notDangerousGoods"
        >
          <b-switch
            v-model="isDangerous"
            type="is-danger"
            :disabled="notDangerousGoods || isDisabled"
          />
        </b-tooltip>
      </div>

      <!-- UNDG code -->
      <div class="column is-1" v-if="isDangerous">
        <Autocomplete
          v-model="undgCode"
          :label="$t('undgCode')"
          :search-property="'code'"
          :validation-rules="{
            required: isDangerous,
            length: { length: 4 }
          }"
          :max-length="4"
          :is-fixed-length="true"
          :is-description-displayed="false"
          :label-position="'on-border'"
          :description-property="'description'"
          :fetch-all-async="getAllDangerousCodesAsync"
          :fetch-async="getDangerousCodeAsync"
          :is-autocomplete-field-expanded="true"
          :path-parameters="{ type: 'undg' }"
          :disabled="isDisabled"
        />
      </div>

      <!-- IMDG code -->
      <div class="column is-1" v-if="isDangerous">
        <Autocomplete
          v-model="imdgCode"
          :label="$t('imdgCode')"
          :search-property="'code'"
          :validation-rules="{
            required: isDangerous
          }"
          :is-description-displayed="false"
          :label-position="'on-border'"
          :description-property="'description'"
          :fetch-all-async="getAllDangerousCodesAsync"
          :fetch-async="getDangerousCodeAsync"
          :is-autocomplete-field-expanded="true"
          :path-parameters="{ type: 'imdg' }"
          :disabled="isDisabled"
        />
      </div>

      <!-- Packaging group -->
      <div class="column is-1" v-if="isDangerous">
        <ValidationProvider
          rules="max:16"
          v-slot="{ errors }"
          :name="$t('packagingGroup')"
        >
          <b-field :label="$t('packagingGroup')" label-position="on-border">
            <b-input :disabled="isDisabled" v-model="packagingGroup" />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import packagingCodesApi from "@/repository/referential/packagingCodes.api.js";
import dangerousCodesApi from "@/repository/referential/dangerousCodes.api.js";
import number from "@/mixins/number";

import Autocomplete from "@/components/common/Autocomplete";

export default {
  name: "GoodsEditor",

  components: {
    Autocomplete
  },

  mixins: [number],

  props: {
    propGoods: {
      type: Object,
      default: null,
      required: true
    },

    goodsIndex: {
      type: Number,
      required: true
    },

    notDangerousGoods: {
      type: Boolean,
      required: true
    },

    isValidateContainers: {
      type: Boolean,
      default: false,
      require: true
    },

    /**
     * Determine if the goods is disabled or not.
     */
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      getAllPackagingCodesAsync: packagingCodesApi.getAll,
      getPackagingCodeAsync: packagingCodesApi.get,

      getAllDangerousCodesAsync: dangerousCodesApi.getAll,
      getDangerousCodeAsync: dangerousCodesApi.get,

      goodsType: this.propGoods.type,
      description: this.propGoods.description,
      quantity: this.propGoods.quantity,
      weight: this.propGoods.weight,
      volume: this.propGoods.volume,

      isDangerous: this.propGoods.hazardousClasses?.length > 0,

      hazardousClasses: this.propGoods.hazardousClasses
        ? this.propGoods.hazardousClasses
        : [{}]
    };
  },

  watch: {
    isDangerous: function(newValue) {
      if (!newValue) {
        this.propGoods.hazardousClasses = undefined;
      }
    },

    notDangerousGoods: function(newValue) {
      if (newValue) {
        this.isDangerous = false;
      }
    },

    async isValidateContainers(newVal) {
      if (newVal) {
        let isValidateSuccess = await this.$refs.goodsObserver.validate();

        if (!isValidateSuccess) {
          this.$emit("set-goods-having-errors", this.goodsIndex);
        }
      }
    }
  },

  computed: {
    undgCode: {
      get() {
        return this.hazardousClasses[0]?.undgCode;
      },
      set(value) {
        if (value !== undefined)
          this.onGoodsInformationChange("undgCode", value);
      }
    },

    imdgCode: {
      get() {
        return this.hazardousClasses[0]?.imdgCode;
      },
      set(value) {
        if (value !== undefined)
          this.onGoodsInformationChange("imdgCode", value);
      }
    },

    packagingGroup: {
      get() {
        return this.hazardousClasses[0]?.packagingGroup;
      },
      set(value) {
        this.onGoodsInformationChange("packagingGroup", value);
      }
    }
  },

  methods: {
    onGoodsInformationChange(field, value) {
      let goodsPayload = {
        goodsId: this.goodsIndex,
        field,
        value
      };

      this.$emit("set-goods-information", goodsPayload);
    }
  }
};
</script>
