<template>
  <section class="section is-flex is-flex-direction-column">
    <ApiKeyTable />
  </section>
</template>
<script>
import ApiKeyTable from "../components/api-key/ApiKeyTable.vue";
export default {
  name: "ApiKey",
  components: { ApiKeyTable }
};
</script>
