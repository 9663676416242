<i18n src="@/i18n/locales/my-carrier.en.json"></i18n>
<i18n src="@/i18n/locales/my-carrier.fr.json"></i18n>

<template>
  <div class="mt-4 box">
    <div class="is-flex is-justify-content-end mb-3">
      <b-button
        icon-pack="fas"
        icon-left="plus"
        type="is-primary"
        size="is-small"
        @click="handleCreate()"
      />
    </div>
    <MyCarrierModalAdd
      v-model="isOpenMyCarrierModalAdd"
      :editCode.sync="editCode"
      :mode="mode"
      @add-api-my-carrier="getData()"
    />
    <b-table
      :data="myCarriers"
      :loading="isLoading"
      hoverable
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="code"
        width="15%"
        :label="$t('code')"
        v-slot="props"
      >
        <strong class="has-text-primary"> {{ props.row.code }}</strong>
      </b-table-column>

      <b-table-column
        field="name"
        width="70%"
        :label="$t('name')"
        v-slot="props"
      >
        <strong class="has-text-primary"> {{ props.row.name }}</strong>
      </b-table-column>

      <b-table-column width="15%" :label="$t('action')" v-slot="props">
        <div class="is-flex is-align-items-center">
          <b-button
            class="is-size-7 mr-3 is-primary is-light"
            id="viewBtn"
            icon-pack="mdi"
            icon-left="magnify"
            @click="handleView(props.row.code)"
          />
          <b-button
            type="is-warning is-light"
            style="color: orange"
            class="is-size-7 mr-3"
            id="editButton"
            icon-pack="mdi"
            icon-left="circle-edit-outline"
            @click="handleEdit(props.row.code)"
          />

          <b-button
            type="is-danger is-light"
            class="is-size-7"
            id="deleteButton"
            icon-pack="mdi"
            icon-left="delete"
            @click="handleDelete(props.row.code, props.row.name)"
          />
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">{{ $t("noRecord") }}</div>
      </template>
    </b-table>

    <PaginationBar
      :total="totalItems"
      :limit="limit"
      :page.sync="page"
      @update:page="getData"
    />
  </div>
</template>

<script>
import myCarriersApi from "@/repository/customer-order/myCarriers.api";
import PaginationBar from "@/components/common/PaginationBar.vue";
import MyCarrierModalAdd from "./MyCarrierModalAdd.vue";

export default {
  name: "MyCarrierTable",

  data() {
    return {
      isLoading: false,
      myCarriers: [],
      totalItems: 0,
      page: 1,
      limit: 6,
      isOpenMyCarrierModalAdd: false,
      editCode: null,
      mode: "view"
    };
  },

  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    // Function to get data from api
    async getData() {
      let paginationParams = {
        limit: this.limit,
        offset: this.offset
      };
      this.isLoading = true;
      await myCarriersApi
        .getAllMyCarriers(null, paginationParams)
        .then(response => {
          this.totalItems = response.data.total;
          this.myCarriers = response.data.items;
        })
        .finally(() => (this.isLoading = false));
    },

    // Handle open modal to view
    handleView(code) {
      this.mode = "view";
      this.editCode = code;
      this.isOpenMyCarrierModalAdd = true;
    },

    // Handle open modal
    handleCreate() {
      this.editCode = null;
      this.mode = "create";
      this.isOpenMyCarrierModalAdd = true;
    },

    // Handle edit
    async handleEdit(code) {
      this.editCode = code;
      this.mode = "edit";
      this.isOpenMyCarrierModalAdd = true;
    },

    //Handle delete
    async handleDelete(code, name) {
      this.$buefy.dialog.confirm({
        title: this.$t("confirmTitle"),
        message: this.$t("deleteCarrier", { code, name }),
        confirmText: this.$t("delete"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          myCarriersApi
            .deleteCarrier(code)
            .then(async () => {
              this.isLoading = false;
              await this.getData();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    }
  },

  components: { PaginationBar, MyCarrierModalAdd }
};
</script>
