<i18n src="@/i18n/locales/connection.en.json"></i18n>
<i18n src="@/i18n/locales/connection.fr.json"></i18n>
<i18n src="@/i18n/locales/legal.fr.json"></i18n>
<i18n src="@/i18n/locales/legal.en.json"></i18n>

<template>
  <div class="title-container">
    <h1
      class="slogan"
      v-html="bandeauTitle"
      :class="{
        'has-text-light': $root.isDarkMode
      }"
    />
    <div class="bandeau"></div>
  </div>
</template>

<script>
export default {
  name: "TheBandeau",
  computed: {
    bandeauTitle() {
      switch (this.$route.name) {
        case "LegalNotice":
          return this.$t("Notion.LegalNotice");
        default:
          return this.$t("title1");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-container {
  width: 100%;
  padding-bottom: 48px;

  .slogan {
    font-weight: 100;
    line-height: 1.1em;
    font-size: 56px;
    padding: 48px 160px;
  }

  .bandeau {
    min-height: 25vh;
    width: 100%;
    background-image: url("../../assets/images/truck_container.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #fff;
    border-width: 1px 0;
    mask-image: url("../../assets/images/filter.png");
    mask-size: 100% 100%;
    mask-position: center center;
    mask-repeat: no-repeat;
  }
}

.dark .bandeau {
  filter: brightness(60%);
}

@media screen and (max-width: 319px) {
  .title-container {
    .slogan {
      padding: 32px 12px;
      font-size: 32px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .title-container {
    .slogan {
      padding: 48px 20px;
      font-size: 42px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .title-container {
    .slogan {
      padding: 64px 32px;
      font-size: 54px;
    }
  }
}
</style>
