<i18n src="@/i18n/locales/comment-info.en.json"></i18n>
<i18n src="@/i18n/locales/comment-info.fr.json"></i18n>

<template>
  <div>
    <div class="columns mt-5">
      <!-- Price information -->
      <div class="column">
        <b-field
          :label="$t('priceInformation')"
          label-position="on-border"
          class="large-label"
        >
          <b-input
            v-model="priceInformation"
            type="textarea"
            @blur="
              $emit(
                'set-order-information',
                'priceInformation',
                priceInformation
              )
            "
          />
        </b-field>
      </div>

      <!-- Driver instructions -->
      <div class="column">
        <b-field
          :label="$t('instructionsInformation')"
          label-position="on-border"
          class="large-label"
        >
          <b-input
            v-model="instructionsInformation"
            type="textarea"
            @blur="
              $emit(
                'set-order-information',
                'instructionsInformation',
                instructionsInformation
              )
            "
          />
        </b-field>
      </div>
    </div>

    <div class="columns mt-5">
      <!-- Contract terms information -->
      <div class="column">
        <b-field
          :label="$t('contractTermsInformation')"
          label-position="on-border"
          class="large-label"
        >
          <b-input
            v-model="contractTermsInformation"
            type="textarea"
            @blur="
              $emit(
                'set-order-information',
                'contractTermsInformation',
                contractTermsInformation
              )
            "
          />
        </b-field>
      </div>

      <!-- Special comments -->
      <div class="column">
        <b-field
          :label="$t('comment')"
          label-position="on-border"
          class="large-label"
        >
          <b-input
            v-model="comment"
            type="textarea"
            @blur="$emit('set-order-information', 'comment', comment)"
          />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentInfo",

  props: {
    // propOrder will have value when it's used for editing order purpose
    propOrder: {
      type: Object
    }
  },

  data() {
    return {
      priceInformation: this.propOrder.priceInformation
        ? this.propOrder.priceInformation
        : null,
      instructionsInformation: this.propOrder.instructionsInformation
        ? this.propOrder.instructionsInformation
        : null,
      contractTermsInformation: this.propOrder.contractTermsInformation
        ? this.propOrder.contractTermsInformation
        : null,
      comment: this.propOrder.comment ? this.propOrder.comment : null
    };
  }
};
</script>
