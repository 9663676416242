<i18n src="@/i18n/locales/container.en.json"></i18n>
<i18n src="@/i18n/locales/container.fr.json"></i18n>
<i18n src="@/i18n/locales/goods.en.json"></i18n>
<i18n src="@/i18n/locales/goods.fr.json"></i18n>
<i18n src="@/i18n/locales/hazardous-class.en.json"></i18n>
<i18n src="@/i18n/locales/hazardous-class.fr.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-containers-board.en.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-containers-board.fr.json"></i18n>

<template>
  <div class="box is-radiusless is-fullheight is-scrollable-y p-0">
    <CollapseCard
      :title="$t('generalInformation')"
      :is-open="openCardIndex === 0"
      @on-card-open="openCardIndex = 0"
    >
      <table class="table has-text-left is-fullwidth">
        <tbody>
          <tr v-if="isImport">
            <th>{{ $t("billOfLading") }}</th>
            <td>{{ container.billOfLading }}</td>
          </tr>
          <tr v-if="container.spi">
            <th>{{ $t("pcsIdentifier") }}</th>
            <td>{{ container.spi }}</td>
          </tr>
          <tr v-if="container.rank">
            <th>{{ $t("containerRank") }}</th>
            <td>{{ container.rank }}</td>
          </tr>
          <tr v-if="!isImport">
            <th>{{ $t("bookingNumber") }}</th>
            <td>{{ container.bookingNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("containerType") }}</th>
            <td>{{ container.type }}</td>
          </tr>
          <tr>
            <th>{{ $t("containerGrossWeight") }}</th>
            <td>
              {{ container.grossWeight | localiseNumber }}
              kg
            </td>
          </tr>
          <tr>
            <th>{{ $t("tare") }}</th>
            <td>
              {{ container.tare | localiseNumber }}
              kg
            </td>
          </tr>
          <tr>
            <th>{{ $t("sealNumbers") }}</th>
            <td>
              <b-tag
                v-for="(sealNumber, key) in container.sealNumbers"
                :key="key"
                class="mr-1 has-primary-color"
              >
                {{ sealNumber }}
              </b-tag>
            </td>
          </tr>
          <!-- rangeMin, rangeMax can be 0°C, so don't use v-if="container.rangeMin", we will only disable them when they don't have value-->
          <tr v-if="container.rangeMin != null">
            <th>{{ $t("rangeMin") }}</th>
            <td>{{ container.rangeMin }} °C</td>
          </tr>
          <tr v-if="container.rangeMax != null">
            <th>{{ $t("rangeMax") }}</th>
            <td>{{ container.rangeMax }} °C</td>
          </tr>
          <tr
            v-if="
              container.indicator &&
                container.indicator.isReefer &&
                container.reefer
            "
          >
            <th>{{ $t("reeferTemperature") }}</th>
            <td>
              {{ container.reefer.setPoint | localiseNumber }}
              °C
            </td>
          </tr>
          <tr
            v-if="
              container.indicator && container.indicator.isOog && container.oog
            "
          >
            <th>{{ $t("oogLength") }}</th>
            <td>{{ container.oog.length | localiseNumber }} m</td>
          </tr>
          <tr
            v-if="
              container.indicator && container.indicator.isOog && container.oog
            "
          >
            <th>{{ $t("oogWidth") }}</th>
            <td>{{ container.oog.width | localiseNumber }} m</td>
          </tr>
          <tr
            v-if="
              container.indicator && container.indicator.isOog && container.oog
            "
          >
            <th>{{ $t("oogHeight") }}</th>
            <td>{{ container.oog.height | localiseNumber }} m</td>
          </tr>
          <tr>
            <th>{{ $t("comment") }}</th>
            <td>{{ container.comment }}</td>
          </tr>
          <tr>
            <th>{{ $t("customsInformation") }}</th>
            <td>{{ container.customsInformation }}</td>
          </tr>
        </tbody>
      </table>
    </CollapseCard>

    <CollapseCard
      :title="$t('goodsInformation')"
      :is-open="openCardIndex === 1"
      @on-card-open="openCardIndex = 1"
      v-if="container.goods && container.goods.length > 0"
      class="mt-2"
    >
      <table class="table has-text-left is-fullwidth">
        <tr>
          <th colspan="2">
            <div class="is-flex is-justify-content-space-between">
              <b-button
                v-if="container.goods.length > 1 && carouselIndex > 0"
                type="is-primary is-light"
                size="is-small"
                icon-right="arrow-left"
                @click="carouselIndex--"
              />
              <p class="has-text-primary">
                {{
                  `${$t("item", { number: carouselIndex + 1 }).toUpperCase()}/${
                    container.goods.length
                  }`
                }}
              </p>
              <b-button
                v-if="
                  container.goods.length > 1 &&
                    carouselIndex < container.goods.length - 1
                "
                type="is-primary is-light"
                size="is-small"
                icon-right="arrow-right"
                @click="carouselIndex++"
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{{ $t("type") }}</th>
          <td>{{ currentGood.type }} - {{ currentGood.typeDescription }}</td>
        </tr>
        <tr>
          <th>{{ $t("description") }}</th>
          <td>{{ currentGood.description }}</td>
        </tr>
        <tr>
          <th>{{ $t("quantity") }}</th>
          <td>{{ currentGood.quantity | localiseNumber }}</td>
        </tr>
        <tr>
          <th>{{ $t("grossWeight") }}</th>
          <td>
            {{ currentGood.weight | localiseNumber }}
            kg
          </td>
        </tr>
        <tr>
          <th>{{ $t("volume") }}</th>
          <td>
            {{ currentGood.volume | localiseNumber }}
            dm3
          </td>
        </tr>
        <tr
          v-if="
            currentGood.hazardousClasses &&
              currentGood.hazardousClasses.length > 0
          "
        >
          <th>{{ $t("dangerousTitle") }}</th>
          <td>
            <table>
              <tr>
                <th>{{ $t("code.undg") }}</th>
                <td>{{ currentGood.hazardousClasses[0].undgCode }}</td>
              </tr>
              <tr>
                <th>{{ $t("code.imdg") }}</th>
                <td>{{ currentGood.hazardousClasses[0].imdgCode }}</td>
              </tr>
              <tr>
                <th>{{ $t("packagingGroup") }}</th>
                <td>{{ currentGood.hazardousClasses[0].packagingGroup }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </CollapseCard>
  </div>
</template>

<script>
import CollapseCard from "@/components/common/CollapseCard";
import packagingCodesApi from "@/repository/referential/packagingCodes.api";

export default {
  name: "ContainersBoardItemGeneralInfo",
  components: { CollapseCard },
  props: {
    container: {
      type: Object,
      required: true
    },
    isImport: {
      type: Boolean,
      required: true,
      default: true
    }
  },

  data() {
    return {
      carouselIndex: 0,
      openCardIndex: 0
    };
  },

  computed: {
    currentGood: function() {
      return this.container?.goods[this.carouselIndex];
    }
  },

  created: async function() {
    if (this.container?.goods) {
      for (let i = 0; i < this.container.goods.length; i++) {
        if (
          !this.container.goods[i].type ||
          this.container.goods[i].type.includes("-")
        ) {
          continue;
        }

        // retrieve goods type description
        let response = await packagingCodesApi.get({
          code: this.container.goods[i].type
        });

        // add description to the Type field if any
        if (response.data) {
          this.container.goods[i].typeDescription = response.data?.description;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.has-primary-color {
  color: $primary-text-color !important;
}
</style>
