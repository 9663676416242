import ReferentialApi from "@/repository/referential/referentialHTTPClient";

/**
 * Countries end point.
 */
const END_POINT = "/countries";

export default {
  /**
   * Search country.
   * @param {String} code - alphaCode.
   */
  getAll(_, queryParams) {
    return ReferentialApi.httpClient.get(`${END_POINT}/search`, {
      params: queryParams
    });
  },

  /**
   * Get country code information by code (2 or 3 letters are ok).
   * @param {String} code - alphaCode3.
   */
  get(pathParams) {
    return ReferentialApi.httpClient.get(`${END_POINT}/${pathParams?.code}`);
  }
};
