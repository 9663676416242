<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="info-container card">
    <div class="columns">
      <div class="column is-half" v-if="organization">
        <div class="info">
          <div class="label">{{ $t("organization") }}</div>
          <div class="value">{{ name }}</div>
        </div>
        <div class="info">
          <div class="label">{{ $t("siret") }}</div>
          <div class="value">{{ siret }}</div>
        </div>
        <div class="info">
          <div class="label" style="display: flex; align-items: center;">
            {{ $t("actorCode") }}
            <b-tooltip
              :label="$t('updateActorCode')"
              v-if="isOwner || $isSuperViewer"
            >
              <b-button
                v-if="!isValidatedActorCode"
                class="is-size-7 ml-4 is-light"
                icon-pack="fas"
                icon-left="edit"
                @click="openActorCodeModal()"
              >
              </b-button
            ></b-tooltip>
            <ActorCodeModal
              v-model="isOpenActorCodeModal"
              :organizationId="organization.id"
              :actorCode="actorCode"
              :actorCodeState="actorCodeState"
              :name="name"
              :is-owner="isOwner"
              :hide-all-errors="true"
              @updated-actor-code="reloadData"
            />
          </div>
          <div class="is-flex is-align-items-center">
            <div v-if="actorCode">
              {{ actorCode }}
            </div>
            <div v-else>N/A</div>
            <ActorCodeState :actorCodeState="actorCodeState" />
          </div>
        </div>
      </div>
      <div v-else class="column is-half">
        <AddOrganizationForm />
      </div>
      <div class="column is-half is-flex is-justify-content-end" v-if="isOwner">
        <InstructionInfo />
      </div>
    </div>
    <div v-if="organization">
      <div class="label">{{ $t("member.title") }}</div>
      <div v-for="member in members" :key="member.id" class="list-item">
        <MemberInfo
          :organizationId="organization.id"
          :member="member"
          :is-owner="isOwner"
          @update-data="reloadData"
        />
      </div>
    </div>
    <div v-if="isOwner && organization">
      <div class="label mt-6">
        {{ $t("invitation.addMember") }}
      </div>

      <ValidationObserver
        ref="invitationValidation"
        tag="div"
        class="field is-flex is-align-items-start is-justify-content-space-between mt-4"
      >
        <ValidationProvider
          rules="required|max:255|email"
          v-slot="{ errors }"
          :name="$t('invitation.email')"
        >
          <b-field expanded label-position="on-border">
            <template #label>
              <label class="required-field">
                {{ $t("invitation.email") }}
              </label>
            </template>
            <b-input v-model.trim="inviteEmail" expanded />
            <b-field
              :label="$t('invitation.role')"
              label-position="on-border"
              class="ml-4"
            >
              <RoleSelect v-bind:role.sync="inviteRole" />
            </b-field>

            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
        <b-tooltip :label="$t('invitation.invite')">
          <b-button
            icon-pack="fas"
            icon-left="paper-plane"
            type="is-primary"
            @click="sendInvitation"
            :loading="isSendingInvitation"
          />
        </b-tooltip>
      </ValidationObserver>
    </div>
    <div v-if="isOwner && organization">
      <div class="label mt-6">{{ $t("invitation.title") }}</div>
      <div
        v-for="invitation in invitations"
        :key="invitation.id"
        class="list-item"
      >
        <InvitationInfo :invitation="invitation" @update-data="reloadData" />
      </div>
      <div v-if="invitations.length == 0">{{ $t("invitation.noRecord") }}</div>
    </div>
  </div>
</template>

<script>
import InstructionInfo from "@/components/my-organization/InstructionInfo";
import MemberInfo from "./MemberInfo.vue";
import InvitationInfo from "./InvitationInfo.vue";
import AddOrganizationForm from "./AddOrganizationForm.vue";
import ActorCodeModal from "@/components/my-organization/ActorCodeModal";
import ActorCodeState from "@/components/my-organization/ActorCodeState";
import RoleSelect from "./RoleSelect.vue";
import invitationApi from "@/repository/customer-order/invitations.api.js";
import UserRole from "@/plugins/sone-connect/roles";

export default {
  name: "OrganizationInfo",
  data() {
    return {
      inviteEmail: "",
      inviteRole: UserRole.Owner,
      isOpenActorCodeModal: false,
      isSendingInvitation: false
    };
  },

  computed: {
    name() {
      return this.organization?.name;
    },
    siret() {
      return this.organization?.siret;
    },
    actorCode() {
      return this.organization?.actorCode;
    },
    actorCodeState() {
      return this.organization?.actorCodeState;
    },
    createdAt() {
      return this.organization?.createdAt;
    },
    createdBy() {
      return this.organization?.createdBy;
    },
    modifiedAt() {
      return this.organization?.modifiedAt;
    },
    modifiedBy() {
      return this.organization?.modifiedBy;
    },
    members() {
      return this.organization?.members;
    },
    invitations() {
      return this.organization?.invitations;
    },
    isOwner() {
      return this.organization?.members.some(
        member =>
          member.id == this.$soneConnect.keycloak.tokenParsed.sub &&
          member.role == UserRole.Owner
      );
    },

    isValidatedActorCode() {
      return this.actorCodeState == "Valid";
    }
  },

  props: {
    organization: { type: Object, default: () => {} }
  },

  components: {
    InstructionInfo,
    MemberInfo,
    InvitationInfo,
    AddOrganizationForm,
    ActorCodeModal,
    ActorCodeState,
    RoleSelect
  },

  methods: {
    openActorCodeModal() {
      this.isOpenActorCodeModal = true;
    },

    // Events to update data
    reloadData() {
      this.$emit("reload-data", null);
    },

    resetData() {
      this.inviteEmail = "";
      this.inviteRole = UserRole.Owner;
    },

    // Send invitation with email
    async sendInvitation() {
      try {
        const validateResult = await this.$refs.invitationValidation.validate();
        if (!validateResult) return;
        this.isSendingInvitation = true;
        await invitationApi.sendInvitation({
          organizationId: this.organization.id,
          email: this.inviteEmail,
          role: this.inviteRole
        });
        await this.resetData();
        await this.reloadData();
        requestAnimationFrame(() => {
          this.$refs.invitationValidation.reset();
        });
      } finally {
        this.isSendingInvitation = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.info-container {
  padding: 24px 32px;
}

.info {
  padding-bottom: 32px;
}

.label {
  font-size: 18px;
  line-height: 0.8rem;
  font-weight: 700;
}

.list-item {
  border-bottom: 1px rgb(238, 238, 238) solid;
}

.list-item:last-child {
  border-bottom: none;
}
</style>
