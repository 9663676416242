<i18n src="@/i18n/locales/my-locations.en.json"></i18n>
<i18n src="@/i18n/locales/my-locations.fr.json"></i18n>

<template>
  <div class="mt-4 box">
    <MyLocationModal
      v-model="isOpenModal"
      :my-location-id.sync="myLocationId"
      :is-read-only="isReadOnly"
      @get-new-data="getData()"
    />

    <div class="is-flex is-justify-content-end mb-3">
      <b-button
        icon-pack="fas"
        icon-left="plus"
        type="is-primary"
        size="is-small"
        id="create-button"
        @click="handleCreate()"
      />
    </div>
    <b-table
      :data="data"
      :loading="isLoading"
      hoverable
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="code" :label="$t('code')" v-slot="props">
        <strong class="has-text-primary"> {{ props.row.code }}</strong>
      </b-table-column>

      <b-table-column field="name" :label="$t('name')" v-slot="props">
        <strong class="has-text-primary"> {{ props.row.name }}</strong>
      </b-table-column>

      <b-table-column width="15%" :label="$t('action')" v-slot="props">
        <div class="is-flex is-align-items-center">
          <b-button
            class="is-size-7 mr-3 is-primary is-light"
            id="view-button"
            icon-pack="mdi"
            icon-left="magnify"
            @click="handleView(props.row.id)"
          />
          <b-button
            type="is-warning is-light"
            style="color: orange"
            class="is-size-7 mr-3"
            id="edit-button"
            icon-pack="mdi"
            icon-left="circle-edit-outline"
            @click="handleEdit(props.row.id)"
          />

          <b-button
            type="is-danger is-light"
            class="is-size-7"
            id="delete-button"
            icon-pack="mdi"
            icon-left="delete"
            @click="handleDelete(props.row.id, props.row.code, props.row.name)"
          />
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">{{ $t("noRecord") }}</div>
      </template>
    </b-table>

    <PaginationBar
      :total="totalItems"
      :limit="limit"
      v-bind:page.sync="page"
      @update:page="getData"
    />
  </div>
</template>

<script>
import myLocationsApi from "@/repository/customer-order/myLocations.api";

import PaginationBar from "@/components/common/PaginationBar.vue";
import MyLocationModal from "@/components/my-locations/MyLocationModal";

export default {
  name: "MyLocationsTable",

  components: {
    PaginationBar,
    MyLocationModal
  },

  data() {
    return {
      isLoading: false,
      data: [],
      totalItems: 0,
      page: 1,
      limit: 6,
      isOpenModal: false,
      myLocationId: null,
      isReadOnly: false
    };
  },

  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    // Function to get data from api
    async getData() {
      let paginationParams = {
        limit: this.limit,
        offset: this.offset
      };

      this.isLoading = true;

      await myLocationsApi
        .getAllMyLocations(null, paginationParams)
        .then(response => {
          this.totalItems = response.data.total;
          this.data = response.data.items;
        })
        .finally(() => (this.isLoading = false));
    },

    // Handle open modal to view
    handleView(id) {
      this.myLocationId = id;
      this.isReadOnly = true;
      this.isOpenModal = true;
    },

    // Handle open modal
    handleCreate() {
      this.myLocationId = null;
      this.isReadOnly = false;
      this.isOpenModal = true;
    },

    // Handle edit
    handleEdit(id) {
      this.myLocationId = id;
      this.isReadOnly = false;
      this.isOpenModal = true;
    },

    //Handle delete
    handleDelete(id, code, name) {
      this.$buefy.dialog.confirm({
        title: this.$t("confirmTitle"),
        message: this.$t("deleteMsg", { code, name }),
        confirmText: this.$t("delete"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          myLocationsApi
            .deleteMyLocation(id)
            .then(async () => {
              this.isLoading = false;
              await this.getData();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    }
  }
};
</script>
