export const IMPORT_EVENT_LADING = "LadingImport";
export const IMPORT_EVENT_DELIVERY = "DeliveryImport";
export const IMPORT_EVENT_EMPTY_RELEASE = "EmptyReleaseImport";
export const IMPORT_EVENT_EMPTY_RETURN = "EmptyReturnImport";
export const EXPORT_EVENT_EMPTY_RELEASE = "EmptyReleaseExport";
export const EXPORT_EVENT_POSITIONING = "PositioningExport";
export const EXPORT_EVENT_LADING = "LadingExport";
export const EXPORT_EVENT_DELIVERY = "DeliveryExport";
export const ANNOUNCED = "Announced";
export const ANNOUCEMENT_CANCELLED = "AnnoucementCancelled";

export const repeatableEventsArray = [ANNOUNCED, ANNOUCEMENT_CANCELLED];

export const allowedEventsArray = [
  IMPORT_EVENT_LADING,
  IMPORT_EVENT_DELIVERY,
  IMPORT_EVENT_EMPTY_RELEASE,
  IMPORT_EVENT_EMPTY_RETURN,
  EXPORT_EVENT_EMPTY_RELEASE,
  EXPORT_EVENT_POSITIONING,
  EXPORT_EVENT_LADING,
  EXPORT_EVENT_DELIVERY
];

export const importEventsArray = [
  IMPORT_EVENT_LADING,
  IMPORT_EVENT_DELIVERY,
  IMPORT_EVENT_EMPTY_RELEASE,
  IMPORT_EVENT_EMPTY_RETURN
];

export const exportEventsArray = [
  EXPORT_EVENT_EMPTY_RELEASE,
  EXPORT_EVENT_POSITIONING,
  EXPORT_EVENT_LADING,
  EXPORT_EVENT_DELIVERY
];
