<template>
  <span>
    FR75
  </span>
</template>
<script>
export default {
  name: "SogetNumPrefixeTvaIntra"
};
</script>
