<i18n src="@/i18n/locales/new-customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.fr.json"></i18n>

<template>
  <section class="pt-4">
    <div class="level is-mobile">
      <!-- This div is compulsory for bulma class `level` to position `level-item` in `level-right` correctly -->
      <div class="level-left" />

      <div class="level-right">
        <div class="level-item">
          <!-- Button cancel -->
          <b-button type="is-danger" @click="handleCancel">
            {{ $t("actionButtons.cancel") }}
          </b-button>
        </div>

        <div class="level-item">
          <!-- Button Submit -->
          <b-button type="is-primary" @click="handleSubmit">
            {{ submitBtnLabel }}
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ActionButtons",

  props: {
    isEditing: {
      type: Boolean,
      default: false,
      required: false
    },

    isSubmitting: {
      type: Boolean,
      default: false,
      required: false
    },

    submitHandler: {
      type: Function,
      required: false
    }
  },

  computed: {
    submitBtnLabel() {
      return this.isEditing
        ? this.$t("actionButtons.edit")
        : this.$t("actionButtons.validate");
    }
  },

  methods: {
    navigateToNewPage(routeName, routeParameters) {
      this.$router
        .push({
          name: routeName,
          params: routeParameters
        })
        .catch(() => {});
    },

    handleCancel() {
      if (this.isSubmitting) return;

      this.$buefy.dialog.confirm({
        message: this.$i18n.t("common.areYouSure"),
        cancelText: this.$i18n.t("actionButtons.no"),
        confirmText: this.$i18n.t("actionButtons.sure"),
        type: "is-danger",
        iconPack: "fas",
        hasIcon: true,
        onConfirm: () => {
          this.navigateToNewPage("Home");
        }
      });
    },

    handleSubmit() {
      // trigger event scroll to error field if exist
      this.$emit("click-submit");

      if (this.submitHandler) return this.submitHandler();
    }
  }
};
</script>
