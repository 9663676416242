<template>
  <div>
    <b-field class="is-flex is-align-items-center mt-2">
      Import
      <b-switch class="ml-2" v-model="$root.isExport" size="is-default">
        Export
      </b-switch>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "FlowType"
};
</script>
