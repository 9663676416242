<i18n src="@/i18n/locales/app.en.json"></i18n>
<i18n src="@/i18n/locales/app.fr.json"></i18n>

<template>
  <section class="py-4 pr-4">
    <b-loading v-model="isLoading"></b-loading>
    <div class="level is-mobile">
      <!-- This div is compulsory for bulma class `level` to position `level-item` in `level-right` correctly -->
      <div class="level-left" />
      <div class="level-right">
        <div class="level-item">
          <!-- Button cancel -->
          <b-button
            v-if="isCancellableOrder"
            type="is-danger"
            @click="handleClickCancelOrder"
            id="btn-cancel-order"
          >
            {{ $t("actionButtons.cancelOrder") }}
          </b-button>
        </div>

        <div class="level-item">
          <!-- Button create -->
          <b-button
            v-if="isEditableOrder"
            type="is-primary"
            @click="handleClickEdit"
            id="btn-edit-order"
          >
            {{ $t("actionButtons.editOrder") }}
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import customerOrdersApi from "@/repository/customer-order/orders.api";
import notifier from "@/mixins/notifier";
import { isEditable } from "@/helpers/orderHelper";

export default {
  name: "ActionButtons",

  props: {
    propOrder: {
      type: Object,
      default: null,
      required: true
    }
  },

  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    isCancellableOrder() {
      return (
        this.propOrder.status === "Valid" ||
        this.propOrder.status === "Refused" ||
        this.propOrder.status === "Rejected" ||
        this.propOrder.status === "Accepted"
      );
    },

    isEditableOrder: function() {
      return isEditable(this.propOrder);
    }
  },

  methods: {
    handleClickCancelOrder() {
      this.$buefy.dialog.confirm({
        message: this.$i18n.t("common.areYouSureToCancelOrder"),
        cancelText: this.$i18n.t("actionButtons.no"),
        confirmText: this.$i18n.t("actionButtons.sure"),
        type: "is-danger",
        iconPack: "fas",
        hasIcon: true,
        onConfirm: () => {
          this.cancelCustomerOrder().then(() =>
            this.$router.push({
              name: "Home"
            })
          );
        }
      });
    },

    cancelCustomerOrder() {
      this.isLoading = true;

      return customerOrdersApi
        .cancel(this.propOrder.customerOrderId)
        .then(response => {
          // display popup with successful message
          let message = this.$t("actionButtons.notifyCancelCustomerOrder");
          notifier.methods.notifySuccess(message);

          return Promise.resolve(response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleClickEdit() {
      this.$router
        .push({
          name: "CustomerOrderEditor",
          params: {
            orderIdentifier: this.propOrder.customerOrderId,
            orderNeedUpdate: this.propOrder
          }
        })
        .catch(() => {});
    }
  }
};
</script>
