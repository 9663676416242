<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <div class="column is-one-third p-3">
    <div v-if="!isOpenUpdate" class="box p-5 itemContainer">
      <div style="height: 65px" class="is-flex is-align-items-center">
        <img
          :src="logos[system]"
          class="logo"
          :style="
            system == 'AKANEA' && $root.isDarkMode ? { height: '40px' } : {}
          "
        />
      </div>

      <div class="is-flex is-align-items-flex-start mt-3">
        <div
          class="has-text-white px-3 py-2 has-background-primary has-text-weight-bold value-input"
        >
          {{ value }}
        </div>

        <div class="action-container is-flex">
          <b-button
            type="is-info is-light"
            outlined
            class="is-size-6 mr-3 ml-3"
            id="editButton"
            @click="handleEdit"
          >
            <b-icon pack="fas" icon="edit"></b-icon>
          </b-button>

          <b-button
            type="is-danger is-light"
            class="is-size-6"
            id="deleteButton"
            @click="handleDelete"
          >
            <b-icon pack="fas" icon="trash"></b-icon>
          </b-button>
        </div>
      </div>
    </div>

    <TranscodificationAddUpdate
      v-else
      :action="'update'"
      :subjectId="subjectId"
      :transcodeId="transcodeId"
      :oldSystem="system"
      :oldValue="value"
      :endpoint="endpoint"
      @update-transcode="updateTranscode"
      v-bind:isOpenUpdate.sync="isOpenUpdate"
    />
  </div>
</template>

<script>
import AKANEALogoLight from "@/assets/images/Akanea_logo.png";
import AKANEALogoDark from "@/assets/images/Akanea_logo_white.png";
import GEDMOUVLogo from "@/assets/images/logo_gedmouv_en.png";

import TranscodificationAddUpdate from "./TranscodificationAddUpdate.vue";

import transcodificationsApi from "@/repository/customer-order/transcodifications.api";

export default {
  name: "TranscodificationItem",

  props: {
    subjectId: {
      type: Number,
      required: true
    },

    transcodeId: {
      type: Number,
      required: true
    },

    system: {
      type: String,
      required: true,
      default: ""
    },

    value: {
      type: String,
      required: true,
      default: ""
    },

    endpoint: {
      type: String,
      required: true
    }
  },

  components: {
    TranscodificationAddUpdate
  },

  data() {
    return {
      isOpenUpdate: false
    };
  },

  computed: {
    logos() {
      return {
        AKANEA: this.$root.isDarkMode ? AKANEALogoDark : AKANEALogoLight,
        GEDMOUV: GEDMOUVLogo
      };
    }
  },

  methods: {
    handleDelete() {
      this.$buefy.dialog.confirm({
        title: this.$t("confirmTitle"),
        message: this.$t("deleteTranscode"),
        confirmText: this.$t("delete"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          transcodificationsApi
            .deleteTranscodification(
              this.endpoint,
              this.subjectId,
              this.transcodeId
            )
            .then(() => {
              this.$emit("refresh-data", this.subjectId);
            });
        }
      });
    },

    updateTranscode(subjectId) {
      this.$emit("update-transcode-item", subjectId);
    },

    handleEdit() {
      this.isOpenUpdate = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.value-input {
  overflow: hidden !important;
  word-wrap: break-word;
  flex: 1;
  border-radius: 4px;
}

.logo {
  height: 64px;
}

.itemContainer {
  border: 1px #fff solid;
  transition: all 0.25s ease-in-out;
}

.itemContainer:hover {
  border: 3px $primary solid;
}
</style>
