import axios from "axios";
import notifier from "@/mixins/notifier";
import useI18n from "@/i18n/i18n.js";

// Interceptor to catch errors
const errorInterceptor = error => {
  let vueI18n = useI18n();

  if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    let data = error.response.data;

    if (data.errors) {
      // validation errors
      var titleMessage = "One or more validation errors occurred.";
      let message = "";

      if (data.title == titleMessage) {
        message = vueI18n.t("validationErrorsOccured");
      } else {
        message = data.title;
      }

      let i = 1;
      for (let attribute in data.errors) {
        data.errors[attribute].forEach(e => {
          // notifier.methods.notifyError(e);
          message = `${message}<br/>${i++}. ${e}`;
        });
      }

      notifier.methods.notifyError(message, false);
    } else {
      // business error
      // dont' show when GET request API Referential returns an HTTP error
      if (
        !(
          error.config.baseURL === window._config.api.CONFIG_REFERENTIAL_URL &&
          ["get"].includes(error.config.method)
        )
      )
        notifier.methods.notifyError(data.message ?? vueI18n.t("verifyInput"));
    }
  } else if (error?.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    notifier.methods.notifyError(vueI18n.t("noResponseWasReceived"));
  } else {
    // Something happened in setting up the request that triggered an Error
    notifier.methods.notifyError(error?.message);
  }

  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = response => {
  let vueI18n = useI18n();

  // display notification only when user does the actions POST/PUT/DELETE
  if (["delete", "post", "put"].includes(response.config.method)) {
    switch (response.status) {
      case 200:
        notifier.methods.notifySuccess(vueI18n.t("successfulOperation"));
        break;
      default:
        notifier.methods.notifySuccess(vueI18n.t("successfulOperation"));
    }
  }

  return response;
};

// Interceptor for requests
let injectHeadersInterceptor = config => {
  let vueI18n = useI18n();

  config.headers.common = {
    ...config.headers.common,
    "Accept-Language": vueI18n.locale
  };
  return config;
};

class HttpClient {
  constructor(baseUrl, headers) {
    // Default headers to use when calling API.
    this.defaultHeaders = {
      "Content-Type": "application/json"
    };

    if (headers) {
      this.defaultHeaders = {
        ...this.defaultHeaders,
        ...headers
      };
    }

    this.httpClient = axios.create({
      baseURL: baseUrl,
      headers: this.defaultHeaders
    });

    this.defaultResponseInterceptorId = this.httpClient.interceptors.response.use(
      responseInterceptor,
      errorInterceptor
    );

    this.httpClient.interceptors.request.use(injectHeadersInterceptor);
  }

  ejectDefaultResponseInterceptors() {
    this.httpClient.interceptors.response.eject(
      this.defaultResponseInterceptorId
    );
  }
}

export default HttpClient;
